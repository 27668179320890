import React from "react";

import UnitTemplateEditions from "./unit-template";

const UnitCharles70 = () => {
    
    // Unit Specification
    const specsObj = {
        specs: [
            {
                icon: "area",
                desc: "Typical Lot Area",
                quantity: "99 sqm"
            },
            {
                icon: "area",
                desc: "Floor Area",
                quantity: "70 sqm"
            },
            {
                icon: "bath",
                desc: "Bathrooms",
                quantity: "2"
            },
            {
                icon: "bed",
                desc: "Bedrooms",
                quantity: "3"
            },
            {
                icon: "car",
                desc: "Carport",
                quantity: "2"
            },
            // {
            //     icon: "expand",
            //     desc: "Expandable",
            //     quantity: ""
            // },
        ]
    }

    // Gallery Images
    const galleryObjs = {
        exterior: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-70/Charles-70-exterior-6.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-70/Charles-70-exterior-9.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-70/Charles-70-exterior-7.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-70/Charles-70-exterior-2.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-70/Charles-70-exterior-5.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-70/Charles-70-exterior-10.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-70/Charles-70-exterior-11.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-70/Charles-70-exterior-4.jpg",
        ],
        interior: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-70/Charles-70-interior-2.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-70/Charles-70-interior-3.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-70/Charles-70-interior-4.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-70/Charles-70-interior-1.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-70/Charles-70-interior-8.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-70/Charles-70-interior-7.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-70/Charles-70-interior-6.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-70/Charles-70-interior-5.jpg",
        ]
    }

    // Unit Floor Plan
    const floorPlanObjs = {
        floor1: 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/floor-plan/Charles_GF_v3.jpg',
        floor2: 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/floor-plan/Charles_2F.jpg'
    }

    return (
        <>
            <UnitTemplateEditions
                title="Charles 70"
                mainImg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-70/Charles-70-exterior-7.jpg"
                logo="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/logos/phirst-editions-centrale-logo-white.png"
                mainDesc="Let the Charles 70 unit be your gateway to a life that is beautifully lived."
                {...specsObj}
                section2Desc="Embracing contemporary elegance is not just a distant aspiration, but an absolute certainty when your determination fuels your dreams. Experience the ultimate modern living as you reside in a residence that seamlessly transports you to a world of absolute bliss."
                section2Img="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-70/Charles-70-exterior-9.jpg"
                {...galleryObjs}
                {...floorPlanObjs}
            />
        </>
    )
}

export default UnitCharles70