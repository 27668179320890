import React from "react";

import UnitTemplateEditions from "./unit-template";

const UnitCharles150 = () => {

    // Unit Specification
    const specsObj = {
        specs: [
            {
                icon: "area",
                desc: "Typical Lot Area",
                quantity: "99 sqm"
            },
            {
                icon: "area",
                desc: "Floor Area",
                quantity: "150 sqm"
            },
            {
                icon: "bath",
                desc: "Bathrooms",
                quantity: "3"
            },
            {
                icon: "bed",
                desc: "Bedrooms",
                quantity: "3"
            },
            {
                icon: "car",
                desc: "Carport",
                quantity: "2"
            },
            // {
            //     icon: "expand",
            //     desc: "Expandable",
            //     quantity: ""
            // },
        ]
    }

    // Gallery Images
    const galleryObjs = {
        exterior: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-150/Charles-150-exterior-4.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-150/Charles-150-exterior-5.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-150/Charles-150-exterior-1.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-150/Charles-150-exterior-3.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-150/Charles-150-exterior-7.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-150/Charles-150-exterior-9.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-150/Charles-150-exterior-8.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-150/Charles-150-exterior-6.jpg",
        ],
        interior: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-150/Charles-150-interior-7.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-150/Charles-150-interior-4.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-150/Charles-150-interior-8.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-150/Charles-150-interior-1.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-150/Charles-150-interior-2.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-150/Charles-150-interior-5.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-150/Charles-150-interior-6.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-150/Charles-150-interior-3.jpg",
        ]
    }

    // Unit Floor Plan
    const floorPlanObjs = {
        floor1: 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/floor-plan/Charles-150-GF.jpg',
        floor2: 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/floor-plan/Charles-150-2F.jpg'
    }

    return (
        <>
            <UnitTemplateEditions
                title="Charles 150 Expanded Version"
                mainImg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-150/Charles-150-exterior-5.jpg"
                logo="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/logos/phirst-editions-centrale-logo-white.png"
                mainDesc="Unlock the full potential of your new abode and seize the possibilities that truly enrich life's experience."
                {...specsObj}
                section2Desc="The Charles 70, a dwelling of distinct charm, can now be transformed into a more expansive version to breathe life into your imaginative aspirations. Say hello to the grander and more refined Charles 150 Expanded Version, where your dreams come to fruition in a haven that you can truly call your own."
                section2Img="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-150/Charles-150-exterior-4.jpg"
                {...galleryObjs}
                {...floorPlanObjs}
            />
        </>
    )
}

export default UnitCharles150