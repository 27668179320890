var APP_DATA = {
  "scenes": [
    {
      "id": "0-entrance-exterior",
      "name": "Entrance Exterior",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.02879979420529466,
        "pitch": -0.19112899888204993,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.08159604423022593,
          "pitch": 0.021203929231369045,
          "rotation": 0,
          "target": "1-lobby"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "1-lobby",
      "name": "Lobby",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0,
        "pitch": 0,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.7881446169765525,
          "pitch": 0.12299743416981457,
          "rotation": 1.5707963267948966,
          "target": "2-lobby---diorama"
        },
        {
          "yaw": -0.4810619649061785,
          "pitch": 0.008213810262731158,
          "rotation": 0,
          "target": "3-entrance-walkway"
        },
        {
          "yaw": 0.3572424375606644,
          "pitch": 0.05235155253487278,
          "rotation": 0,
          "target": "4-inquiries"
        },
        {
          "yaw": 2.549434320783857,
          "pitch": 0.10497812169027654,
          "rotation": 0,
          "target": "0-entrance-exterior"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "2-lobby---diorama",
      "name": "Lobby - Diorama",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 3.1076613996025024,
        "pitch": 0.06107054501000242,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -3.0612292725469032,
          "pitch": 0.009095900119371336,
          "rotation": 0,
          "target": "4-inquiries"
        },
        {
          "yaw": 2.3086223644555766,
          "pitch": 0.03272017518418657,
          "rotation": 0,
          "target": "6-lounge"
        },
        {
          "yaw": 1.791443943769531,
          "pitch": 0.01295851292480954,
          "rotation": 0,
          "target": "3-entrance-walkway"
        },
        {
          "yaw": -1.8285867503315654,
          "pitch": 0.012834407717285501,
          "rotation": 0,
          "target": "0-entrance-exterior"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "3-entrance-walkway",
      "name": "Entrance Walkway",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 3.1241696258070295,
        "pitch": -0.064688023954524,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 3.0789774068492255,
          "pitch": 0.13484784781628534,
          "rotation": 0,
          "target": "8-walkway"
        },
        {
          "yaw": -1.9440800951260115,
          "pitch": 0.051456924417408345,
          "rotation": 0,
          "target": "6-lounge"
        },
        {
          "yaw": -0.6359125148702081,
          "pitch": 0.02947006363351612,
          "rotation": 0,
          "target": "4-inquiries"
        },
        {
          "yaw": -0.023166569822276273,
          "pitch": 0.017920765188563337,
          "rotation": 0,
          "target": "0-entrance-exterior"
        },
        {
          "yaw": 0.44222411509838366,
          "pitch": 0.1962877685590385,
          "rotation": 4.71238898038469,
          "target": "2-lobby---diorama"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "4-inquiries",
      "name": "Inquiries",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0.5828395251187537,
        "pitch": 0.06581183701032245,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.8903997015195824,
          "pitch": 0.0905969832799709,
          "rotation": 0,
          "target": "5-reception"
        },
        {
          "yaw": -0.1418868780593705,
          "pitch": 0.048580451048060524,
          "rotation": 0,
          "target": "6-lounge"
        },
        {
          "yaw": -0.23173758569842917,
          "pitch": 0.026802844298403627,
          "rotation": 4.71238898038469,
          "target": "3-entrance-walkway"
        },
        {
          "yaw": -1.3650087197210556,
          "pitch": 0.07633860881012566,
          "rotation": 0,
          "target": "1-lobby"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "5-reception",
      "name": "Reception",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -3.136260766735081,
        "pitch": 0.0661544609515019,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.5365771342363956,
          "pitch": 0.04252124517891431,
          "rotation": 0,
          "target": "1-lobby"
        },
        {
          "yaw": 0.7181999217497168,
          "pitch": 0.08599408489504157,
          "rotation": 1.5707963267948966,
          "target": "6-lounge"
        },
        {
          "yaw": 0.4440607917904771,
          "pitch": 0.06118107739679779,
          "rotation": 0,
          "target": "3-entrance-walkway"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "6-lounge",
      "name": "Lounge",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0.38746309394273126,
        "pitch": -0.024511694362510994,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.757976355007667,
          "pitch": -0.04225058209329546,
          "rotation": 0,
          "target": "5-reception"
        },
        {
          "yaw": 1.5623504511859894,
          "pitch": -0.06712559587256095,
          "rotation": 1.5707963267948966,
          "target": "1-lobby"
        },
        {
          "yaw": 1.996168961504912,
          "pitch": -0.011442201665028406,
          "rotation": 1.5707963267948966,
          "target": "3-entrance-walkway"
        },
        {
          "yaw": -0.28083637681061724,
          "pitch": 0.431090260952228,
          "rotation": 5.497787143782138,
          "target": "7-lounge---couch"
        },
        {
          "yaw": -1.9041972919949046,
          "pitch": 0.5554258765154998,
          "rotation": 11.780972450961727,
          "target": "8-walkway"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "7-lounge---couch",
      "name": "Lounge - Couch",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.03581310145689187,
        "pitch": 0.041433767878649164,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.322002029232035,
          "pitch": 0.28830110950648447,
          "rotation": 0.7853981633974483,
          "target": "8-walkway"
        },
        {
          "yaw": -2.228268299224565,
          "pitch": -0.18557191709329857,
          "rotation": 7.853981633974483,
          "target": "3-entrance-walkway"
        },
        {
          "yaw": -2.2279692685309005,
          "pitch": -0.01004119257059699,
          "rotation": 5.497787143782138,
          "target": "6-lounge"
        },
        {
          "yaw": 2.7913484671968423,
          "pitch": -0.1835173150880962,
          "rotation": 0,
          "target": "5-reception"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "8-walkway",
      "name": "Walkway",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -3.121674477866513,
        "pitch": -0.04514117929170247,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.2601679069917786,
          "pitch": -0.2087645702016765,
          "rotation": 0,
          "target": "3-entrance-walkway"
        },
        {
          "yaw": 2.3689569647924857,
          "pitch": 0.05568535719935852,
          "rotation": 0,
          "target": "14-play-park"
        },
        {
          "yaw": -2.716717553311744,
          "pitch": 0.14612290969917652,
          "rotation": 18.84955592153877,
          "target": "11-overview-houses"
        },
        {
          "yaw": 2.8304638555254904,
          "pitch": 0.0022760986765870683,
          "rotation": 0,
          "target": "13-tree-house"
        },
        {
          "yaw": -2.2100571091557892,
          "pitch": -0.05901731141006472,
          "rotation": 0,
          "target": "12-callista-pair"
        },
        {
          "yaw": -2.4370906453950347,
          "pitch": 0.2564682057848735,
          "rotation": 5.497787143782138,
          "target": "9-walkway---loop"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "9-walkway---loop",
      "name": "Walkway - Loop",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -1.0065878832732693,
        "pitch": -0.030522009637522274,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.082810549197438,
          "pitch": -0.12912833905127918,
          "rotation": 0,
          "target": "0-entrance-exterior"
        },
        {
          "yaw": 0.28138748990840057,
          "pitch": 0.22122303614237104,
          "rotation": 1.5707963267948966,
          "target": "8-walkway"
        },
        {
          "yaw": -0.2926707944064759,
          "pitch": 0.1378100756094991,
          "rotation": 0,
          "target": "12-callista-pair"
        },
        {
          "yaw": -1.1661730467700409,
          "pitch": 0.13545772531139377,
          "rotation": 7.0685834705770345,
          "target": "11-overview-houses"
        },
        {
          "yaw": -1.6222093825664174,
          "pitch": 0.10315079444411346,
          "rotation": 5.497787143782138,
          "target": "10-walkway-end"
        },
        {
          "yaw": -2.8898234689613496,
          "pitch": 0.04953641476189219,
          "rotation": 0,
          "target": "14-play-park"
        },
        {
          "yaw": -2.0433593615935237,
          "pitch": 0.02950099817289953,
          "rotation": 0,
          "target": "13-tree-house"
        },
        {
          "yaw": -2.308020708504003,
          "pitch": 0.02392408557778758,
          "rotation": 0,
          "target": "16-climb-station"
        },
        {
          "yaw": -2.6726399947200257,
          "pitch": 0.042344062085264866,
          "rotation": 0,
          "target": "15-paw-park"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "10-walkway-end",
      "name": "Walkway End",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.23323947818921198,
        "pitch": -0.06708463720266167,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.709076967282808,
          "pitch": 0.10889047246691597,
          "rotation": 0,
          "target": "16-climb-station"
        },
        {
          "yaw": 2.6888044793323678,
          "pitch": 0.09434306112146373,
          "rotation": 0,
          "target": "13-tree-house"
        },
        {
          "yaw": 1.5137114273068448,
          "pitch": 0.2930295332008548,
          "rotation": 12.566370614359176,
          "target": "9-walkway---loop"
        },
        {
          "yaw": 1.3941888696554834,
          "pitch": 0.164607408307905,
          "rotation": 4.71238898038469,
          "target": "11-overview-houses"
        },
        {
          "yaw": 1.5418213562612202,
          "pitch": -0.06718072198723313,
          "rotation": 0,
          "target": "3-entrance-walkway"
        }
      ],
      "infoHotspots": [
        {
          "yaw": -0.4743688382308697,
          "pitch": -0.05718277394368698,
          "title": "CALISTA END",
          "text": "CHECK INSIDE"
        },
        {
          "yaw": 0.16520875063216778,
          "pitch": -0.04877658993197187,
          "title": "CALISTA MID",
          "text": "CHECK INSIDE"
        }
      ]
    },
    {
      "id": "11-overview-houses",
      "name": "Overview Houses",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.42517285514072967,
        "pitch": -0.06531980884383515,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.6411112020656855,
          "pitch": 0.05475111907820995,
          "rotation": 0,
          "target": "13-tree-house"
        },
        {
          "yaw": 2.732397527448226,
          "pitch": 0.07168391460451673,
          "rotation": 0,
          "target": "14-play-park"
        },
        {
          "yaw": -1.9460478933481689,
          "pitch": 0.17755235697526395,
          "rotation": 7.0685834705770345,
          "target": "10-walkway-end"
        },
        {
          "yaw": 1.7528033281859852,
          "pitch": 0.15421414399798472,
          "rotation": 0,
          "target": "9-walkway---loop"
        },
        {
          "yaw": 1.340573909551388,
          "pitch": -0.10920996249164716,
          "rotation": 0,
          "target": "3-entrance-walkway"
        },
        {
          "yaw": 1.2674167240520635,
          "pitch": 0.10052282036496507,
          "rotation": 0.7853981633974483,
          "target": "8-walkway"
        }
      ],
      "infoHotspots": [
        {
          "yaw": 0.22400261239465635,
          "pitch": -0.107327079387597735,
          "title": "CALISTA PAIR",
          "text": "Check unit interior",
          "link": "/calista/pair/360"
        },
        {
          "yaw": -0.8609288334485733,
          "pitch": -0.20113194296942822,
          "title": "CALISTA MID",
          "text": "Check unit interior",
          "link": "/calista/mid/360"
        },
        {
          "yaw": -0.3615252574130454,
          "pitch": -0.05683050667146638,
          "title": "CALISTA BARE UNITS",
          "text": "Check unit interior",
          "link": "/calista/bare-units/360"
        },
        {
          "yaw": -1.286592318271205,
          "pitch": -0.04503508202055468,
          "title": "CALISTA END",
          "text": "Check unit interior",
          "link": "/calista/end/360"
        }
      ]
    },
    {
      "id": "12-callista-pair",
      "name": "Callista Pair",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0.25219902790733784,
        "pitch": -0.07083936521389944,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.3584001282793743,
          "pitch": -0.12009165807549849,
          "rotation": 0,
          "target": "3-entrance-walkway"
        },
        {
          "yaw": 2.7635755949112246,
          "pitch": 0.14362739183174789,
          "rotation": 2.356194490192345,
          "target": "8-walkway"
        },
        {
          "yaw": -2.7752765565443163,
          "pitch": 0.16355151084269792,
          "rotation": 0.7853981633974483,
          "target": "9-walkway---loop"
        },
        {
          "yaw": -1.768534777523893,
          "pitch": 0.1708435620461053,
          "rotation": 2.356194490192345,
          "target": "11-overview-houses"
        },
        {
          "yaw": -1.5773103070658827,
          "pitch": 0.09978496759759281,
          "rotation": 0.7853981633974483,
          "target": "10-walkway-end"
        }
      ],
      "infoHotspots": [
        {
          "yaw": 0.23204561754695519,
          "pitch": 0.14182871863272872,
          "title": "CALISTA PAIR",
          "text": "CHECK INSIDE"
        }
      ]
    },
    {
      "id": "13-tree-house",
      "name": "Tree House",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.5121748023125221,
        "pitch": -0.010320713415794103,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.089494492659254,
          "pitch": 0.1793749618696836,
          "rotation": 0,
          "target": "11-overview-houses"
        },
        {
          "yaw": 0.007389251127642993,
          "pitch": 0.17390810127641032,
          "rotation": 5.497787143782138,
          "target": "10-walkway-end"
        },
        {
          "yaw": 2.608509570288165,
          "pitch": 0.12731283148856498,
          "rotation": 0,
          "target": "9-walkway---loop"
        },
        {
          "yaw": 2.5545663838251267,
          "pitch": -0.12092211701924604,
          "rotation": 0,
          "target": "3-entrance-walkway"
        },
        {
          "yaw": 1.5718052231392052,
          "pitch": -0.025094270934250318,
          "rotation": 0,
          "target": "12-callista-pair"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "14-play-park",
      "name": "Play Park",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.9241910806925144,
        "pitch": -0.1432343312442903,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.01004290192710755,
          "pitch": 0.14604620594503537,
          "rotation": 5.497787143782138,
          "target": "13-tree-house"
        },
        {
          "yaw": 1.3415631494659124,
          "pitch": 0.17332453944093906,
          "rotation": 0,
          "target": "9-walkway---loop"
        },
        {
          "yaw": 2.2615400552074902,
          "pitch": -0.1319107146692886,
          "rotation": 0,
          "target": "3-entrance-walkway"
        },
        {
          "yaw": 2.134544805938443,
          "pitch": 0.11506730412914123,
          "rotation": 5.497787143782138,
          "target": "8-walkway"
        },
        {
          "yaw": 1.1635994681792425,
          "pitch": -0.023680469267919335,
          "rotation": 0,
          "target": "12-callista-pair"
        },
        {
          "yaw": 0.6247042455184264,
          "pitch": 0.1069689659958648,
          "rotation": 0,
          "target": "11-overview-houses"
        },
        {
          "yaw": 0.22737280025819118,
          "pitch": 0.09106636474971808,
          "rotation": 5.497787143782138,
          "target": "10-walkway-end"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "15-paw-park",
      "name": "Paw Park",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0.6930543543370433,
        "pitch": 0.08643566684751747,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.3686133236888498,
          "pitch": 0.06043210771551699,
          "rotation": 0,
          "target": "16-climb-station"
        },
        {
          "yaw": 2.414000585396626,
          "pitch": 0.03065061279663439,
          "rotation": 0,
          "target": "13-tree-house"
        },
        {
          "yaw": 1.9821862257420504,
          "pitch": 0.08627901049467468,
          "rotation": 1.5707963267948966,
          "target": "10-walkway-end"
        },
        {
          "yaw": -2.767068200720791,
          "pitch": -0.08955098691902386,
          "rotation": 0,
          "target": "3-entrance-walkway"
        },
        {
          "yaw": -2.772391496016235,
          "pitch": 0.0774492074875699,
          "rotation": 0.7853981633974483,
          "target": "8-walkway"
        },
        {
          "yaw": -3.0241110179653337,
          "pitch": 0.05156657111766805,
          "rotation": 0,
          "target": "14-play-park"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "16-climb-station",
      "name": "Climb Station",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.022275283674634494,
        "pitch": 0.13417140824188145,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.2211052589289597,
          "pitch": 0.09016538586566547,
          "rotation": 1.5707963267948966,
          "target": "15-paw-park"
        },
        {
          "yaw": 3.0487206807053093,
          "pitch": -0.016120763014015438,
          "rotation": 0,
          "target": "13-tree-house"
        },
        {
          "yaw": -2.773105426106122,
          "pitch": -0.08945025479311397,
          "rotation": 0,
          "target": "3-entrance-walkway"
        },
        {
          "yaw": -2.4700821104242934,
          "pitch": 0.036262286776135966,
          "rotation": 0,
          "target": "14-play-park"
        },
        {
          "yaw": -2.8608874205650086,
          "pitch": 0.0027462009280370125,
          "rotation": 0,
          "target": "8-walkway"
        },
        {
          "yaw": 1.9825831196962973,
          "pitch": 0.12970031381794556,
          "rotation": 7.0685834705770345,
          "target": "10-walkway-end"
        }
      ],
      "infoHotspots": []
    }
  ],
  "name": "360-sales-pave-centrale",
  "settings": {
    "mouseViewMode": "drag",
    "autorotateEnabled": false,
    "fullscreenButton": true,
    "viewControlButtons": true
  }
};

export default APP_DATA
