var APP_DATA = {
  "scenes": [
    {
      "id": "0-entrance-exterior",
      "name": "Entrance Exterior",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.09710377292913819,
        "pitch": 0.051603567078970514,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.3603031835967645,
          "pitch": 0.0011298286081125042,
          "rotation": 0,
          "target": "1-living-room-and-dining-area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "1-living-room-and-dining-area",
      "name": "Living Room and Dining Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.015074970511214758,
        "pitch": 0.15309449713603662,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.6853658119188921,
          "pitch": 0.06459567020857193,
          "rotation": 13.351768777756625,
          "target": "2-kitchen-area"
        },
        {
          "yaw": -0.30489932788066554,
          "pitch": 0.0864515749932604,
          "rotation": 1.5707963267948966,
          "target": "3-bathroom"
        },
        {
          "yaw": 2.393153483638512,
          "pitch": 0.05848807016607083,
          "rotation": 0,
          "target": "0-entrance-exterior"
        },
        {
          "yaw": -1.9401921680888012,
          "pitch": -0.20150286281341323,
          "rotation": 7.0685834705770345,
          "target": "4-upstairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "2-kitchen-area",
      "name": "Kitchen Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 1.053179072498013,
        "pitch": 0.06442713111615461,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.1256013927207924,
          "pitch": 0.04230274309985482,
          "rotation": 0,
          "target": "0-entrance-exterior"
        },
        {
          "yaw": -0.0043541654043508515,
          "pitch": 0.18003270867196974,
          "rotation": 2.356194490192345,
          "target": "1-living-room-and-dining-area"
        },
        {
          "yaw": 0.6749742645003725,
          "pitch": -0.09446908079303462,
          "rotation": 0.7853981633974483,
          "target": "4-upstairs"
        },
        {
          "yaw": -2.1065103136600705,
          "pitch": 0.06893030709949599,
          "rotation": 0,
          "target": "3-bathroom"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "3-bathroom",
      "name": "Bathroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -1.888302344534976,
        "pitch": 0.11350695813594669,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.1051412359316046,
          "pitch": 0.008986574274569392,
          "rotation": 1.5707963267948966,
          "target": "2-kitchen-area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "4-upstairs",
      "name": "Upstairs",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 1.4149765109229655,
        "pitch": -0.07222711327822395,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.1070742978861094,
          "pitch": 0.4067216967639524,
          "rotation": 4.71238898038469,
          "target": "1-living-room-and-dining-area"
        },
        {
          "yaw": 0.696818118454086,
          "pitch": -0.4029532479448008,
          "rotation": 7.853981633974483,
          "target": "5-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "5-hallway-2nd-floor",
      "name": "Hallway 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.5654866776461596,
        "pitch": 0.11352784757373513,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.2177949749461199,
          "pitch": 0.19524439411699923,
          "rotation": 0,
          "target": "7-bedroom-1"
        },
        {
          "yaw": -0.6294238394713307,
          "pitch": 0.19951652125927843,
          "rotation": 0,
          "target": "8-bedroom-2"
        },
        {
          "yaw": -0.11495859843362233,
          "pitch": 0.10796679519259911,
          "rotation": 0,
          "target": "9-bedroom-3"
        },
        {
          "yaw": -2.9014511130211798,
          "pitch": 0.07732646941505728,
          "rotation": 0,
          "target": "11-bathroom-2nd-floor"
        },
        {
          "yaw": -0.2616343880067511,
          "pitch": 0.6094100290849838,
          "rotation": 0.7853981633974483,
          "target": "6-hallway-2nd-floor---2nd-view"
        },
        {
          "yaw": 0.2564828047514691,
          "pitch": 0.8624211267496982,
          "rotation": 10.210176124166829,
          "target": "4-upstairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "6-hallway-2nd-floor---2nd-view",
      "name": "Hallway 2nd Floor - 2nd View",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0.008597964040429673,
        "pitch": 0.1942935698084316,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.7036094995235018,
          "pitch": 0.19895855857761546,
          "rotation": 0,
          "target": "8-bedroom-2"
        },
        {
          "yaw": 0.723849443246074,
          "pitch": 0.1354113268451158,
          "rotation": 0,
          "target": "9-bedroom-3"
        },
        {
          "yaw": -1.7622776456280107,
          "pitch": 0.13781023633481482,
          "rotation": 0,
          "target": "7-bedroom-1"
        },
        {
          "yaw": -2.3994604380395828,
          "pitch": 0.08216876816454644,
          "rotation": 0,
          "target": "3-bathroom"
        },
        {
          "yaw": -3.0682504632650396,
          "pitch": 0.15225938132074823,
          "rotation": 8.63937979737193,
          "target": "5-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "7-bedroom-1",
      "name": "Bedroom #1",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -2.664445512727738,
        "pitch": 0.2557394141390361,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.099061601420491,
          "pitch": 0.10949463877999399,
          "rotation": 0,
          "target": "5-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "8-bedroom-2",
      "name": "Bedroom #2",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0.2155174056156124,
        "pitch": 0.0718904334500916,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.5606785418878086,
          "pitch": 0.07993303385006811,
          "rotation": 0,
          "target": "5-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "9-bedroom-3",
      "name": "Bedroom #3",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0.02079310955069147,
        "pitch": 0.10746913555278148,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 3.1349786051847364,
          "pitch": 0.5311758467801599,
          "rotation": 0,
          "target": "6-hallway-2nd-floor---2nd-view"
        },
        {
          "yaw": 3.1194479996920306,
          "pitch": -0.04613460591227536,
          "rotation": 0,
          "target": "11-bathroom-2nd-floor"
        },
        {
          "yaw": -3.01444704634169,
          "pitch": 0.07317442834710917,
          "rotation": 1.5707963267948966,
          "target": "7-bedroom-1"
        },
        {
          "yaw": -2.951341261406851,
          "pitch": 0.20197309960183404,
          "rotation": 1.5707963267948966,
          "target": "8-bedroom-2"
        },
        {
          "yaw": 2.88175585006778,
          "pitch": 0.14576632355448105,
          "rotation": 7.853981633974483,
          "target": "5-hallway-2nd-floor"
        },
        {
          "yaw": 0.6944568171125152,
          "pitch": 0.18567963679131871,
          "rotation": 3.9269908169872414,
          "target": "10-bedroom-3---2nd-view"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "10-bedroom-3---2nd-view",
      "name": "Bedroom #3 - 2nd View",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -1.930559356480197,
        "pitch": 0.062099373193715834,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.12182678899312,
          "pitch": 0.15502412293459145,
          "rotation": 10.995574287564278,
          "target": "6-hallway-2nd-floor---2nd-view"
        },
        {
          "yaw": -1.3695094614116385,
          "pitch": 0.2001221018839754,
          "rotation": 3.141592653589793,
          "target": "9-bedroom-3"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "11-bathroom-2nd-floor",
      "name": "Bathroom 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0.18764076231979843,
        "pitch": 0.16085372442141477,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.05624210539921748,
          "pitch": 0.21566324153517513,
          "rotation": 0,
          "target": "5-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    }
  ],
  "name": "360-calista-mid",
  "settings": {
    "mouseViewMode": "drag",
    "autorotateEnabled": false,
    "fullscreenButton": true,
    "viewControlButtons": true
  }
};

export default APP_DATA