import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import ReCAPTCHA from "react-google-recaptcha";
import '../css/book-tripping.css'

const recaptchaRef = React.createRef();


const { REACT_APP_MAIL_API_URL, REACT_APP_MAIL_API_KEY, REACT_APP_MAIL_RECEIVER, REACT_APP_RECAPTCHA_SITE_KEY, REACT_APP_RECAPTCHA_SECRET_KEY} = process.env


const BookTripping = (props) => {

    const [ nameInput, setName ] = useState("")
    const [ emailInput, setEmail ] = useState("")
    const [ contactNumber, setContactNumber ] = useState("")
    const [ locationInput, setLocation ] = useState("")
    const [ dateVisit, setDateVisit ] = useState("")
    const [ timeVisit, setTimeVisit ] = useState("")
    const [ modeTransport, setModeTransport ] = useState("")
    const [ pickUpPoint, setPickUpPoint ] = useState("")
    const [ visitorCount, setVisitorCount ] = useState("")
    const [ addtlNotes, setAddtlNotes ] = useState("")

    const [ isCaptchaVerified, setCaptchaStatus ] = useState(false)

    useEffect(() => {
        setMinimumDatetime()
    }, [])

    useEffect(() => {
        let pickUpInput = document.getElementById('pickUpPoint')

        if (modeTransport === "Private") {
            pickUpInput.setAttribute("disabled", "true")
            setPickUpPoint("N/A")
        } else if (modeTransport === "Commute") {
            pickUpInput.removeAttribute("disabled")
            setPickUpPoint(pickUpInput.value)
        }
    }, [modeTransport])

    useEffect(() => {
        checkFormValidity()
    }, [isCaptchaVerified])

    const onChange = (value) => {
        setCaptchaStatus(true)
    }

    const resetCaptcha = () => {
        setCaptchaStatus(false)
    }

    const checkFormValidity = () => {
        var btnSubmit = document.getElementById('btnContactSubmit')

        if (isCaptchaVerified) {
            btnSubmit.removeAttribute('disabled')
        } else {
            btnSubmit.setAttribute('disabled', 'true')
        }
    }

    const setMinimumDatetime = () => {
        const d = new Date()
        let today = d.getDate()
        let month = d.getMonth() + 1
        let year = d.getFullYear()

        if (today < 10) {
            today = '0' + today
        }

        if (month < 10) {
            month = '0' + month
        }

        let minDate = year + '-' + month + '-' + today

        let dateInput = document.getElementById('visitDate')
        dateInput.setAttribute("min", minDate)
    }

    const mailContent = () => {
        return (
                '<!DOCTYPE html lang="en"> \
                    <head> \
                        <meta http-equiv="X-UA-Compatible" content="IE=edge"> \
                        <meta name="viewport" content="width=device-width, initial-scale=1"> \
                        <meta name="color-scheme" content="light dark"> \
                        <meta name="supported-color-schemes" content="light dark"> \
                        <title>Our new office awaits! | Bayer Office Inauguration</title> \
                        <style type="text/css"> \
                            * { \
                                color: black; \
                            } \
                            body { \
                                padding: 0; \
                                margin: 0; \
                                background-color: #fff; \
                            } \
                            div[class="content"] { \
                                display: flex; \
                                flex-direction: column; \
                                width: 100%; \
                                max-width: 720px; \
                                margin: 0 auto; \
                            } \
                            div[class="content"] div[class="text-content"]{ \
                                box-sizing: border-box; \
                                padding: 24px 40px;    \
                            } \
                            img[class="brand-img"] { \
                                width: 100%; \
                            } \
                            \
                            div[class="content"] p { \
                                margin-bottom: 48px; \
                            } \
                            div[class="content"] h6 { \
                                margin: unset; \
                                font-size: 16px; \
                                font-weight: normal; \
                            } \
                            \
                        </style>  \
                    </head> \
                    <body> \
                        <div class="content" style="width: 100%; max-width: 720px; margin: 0 auto;"> \
                            <a href="#" style="width: 100%;"><img class="brand-img" style="width: 100%;" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/others/email-bg.png" alt=""></a>\
                            <div class="text-content" style="box-sizing: border-box; padding: 24px 40px;"> \
                                <h4 style="font-size: 20px;font-weight: normal;"><span style="font-weight: bold;">Book a Tripping</span></h4> \
                                <h6 style="margin: unset; font-size: 16px; font-weight: normal;">Name: ' + nameInput + '</h6> \
                                <h6 style="margin: unset; font-size: 16px; font-weight: normal;">Email: ' + emailInput + '</h6> \
                                <h6 style="margin: unset; font-size: 16px; font-weight: normal;">Contact Number: ' + contactNumber + '</h6> \
                                <h6 style="margin: unset; font-size: 16px; font-weight: normal;">Location: ' + locationInput + '</h6> \
                                <h6 style="margin: unset; font-size: 16px; font-weight: normal;">Date of Visit: ' + dateVisit + '</h6> \
                                <h6 style="margin: unset; font-size: 16px; font-weight: normal;">Time of Visit: ' + timeVisit + '</h6> \
                                <h6 style="margin: unset; font-size: 16px; font-weight: normal;">Mode of Transport: ' + modeTransport + '</h6> \
                                <h6 style="margin: unset; font-size: 16px; font-weight: normal;">Preferred Pick-up Point: ' + pickUpPoint + '</h6> \
                                <h6 style="margin: unset; font-size: 16px; font-weight: normal;">How many people would be visiting?: ' + visitorCount + '</h6> \
                                <h6 style="margin: unset; font-size: 16px; font-weight: normal;">Additional Notes: </h6> \
                                <textarea style="margin-top: 0;margin-bottom: 48px;font-size: 16px;resize: none !important;min-width: 320px;" readonly rows="4">' + addtlNotes + '</textarea> \
                                \
                            </div> \
                        </div> \
                    </body> \
                </html>'
            )
    }

    const sendTripping = (e) => {
        e.preventDefault()

        // console.log(nameInput + ", " + emailInput + ", " + contactNumber + ", " + locationInput + ", " + dateVisit + ", " + timeVisit + ", " + modeTransport + ", " + pickUpPoint + ", " + visitorCount + ", " +  addtlNotes)

        const mailData = {
            sender: {email: emailInput, name: nameInput},
            to: [{email: 'phirstsights@gmail.com'}],
            htmlContent: mailContent(),
            subject: "Book A Tripping",
            params: {
                name: nameInput,
                mobile: contactNumber
            }
          }

        const data = JSON.stringify(mailData)


        const options = {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'api-key': REACT_APP_MAIL_API_KEY
            },
            body: data,
        };
          
        // Send email using API endpoint (sendinblue)
        fetch(REACT_APP_MAIL_API_URL+'smtp/email', options)
            .then((response) => {
                if(response.ok) {
                    window.alert("Your email has been sent!")
                } else {
                    window.alert("Something went wrong. Please try again.")
                }
            })
            .catch(err => console.error(err));
        
        setTimeout(() => {
            recaptchaRef.current.reset()
            setCaptchaStatus(false)
        }, 1000)

    }

    return (
        // Modal
        <div className="book-tripping-modal" id="bookTrippingModal">
            <div className="modal-header">
                <h4>Book a tripping</h4>
                <i className="bi bi-x-lg" onClick={ props.toggleBook }></i>
            </div>
            
            <div className="modal-content" id="bookContent">
                <form onSubmit={ sendTripping }>
                    <div className="input-item">
                        <label htmlFor="name">Name:</label>
                        <input type="text" name="name" id="name" onChange={ (e) => { setName(e.target.value) }} required/>
                    </div>

                    <div className="input-group">
                        <div className="input-item">
                            <label htmlFor="email">Email:</label>
                            <input type="email" name="email" id="email" onChange={ (e) => { setEmail(e.target.value) }} required/>
                        </div>
                        <div className="input-item">
                            <label htmlFor="contactNumber">Contact Number:</label>
                            <input type="text" name="contactNumber" id="contactNumber" onChange={ (e) => { setContactNumber(e.target.value) }} required/>
                        </div>
                    </div>

                    <div className="input-item">
                        <label htmlFor="location">Location:</label>
                        <select name="location" id="location" onChange={ (e) => { setLocation(e.target.value) }} required>
                            <option value="" style={{ display: "none" }}>Choose location</option>
                            <option value="Batulao, Batangas">Batulao, Batangas</option>
                        </select>
                    </div>

                    <div className="input-group">
                        <div className="input-item">
                            <label htmlFor="visitDate">Date of visit:</label>
                            <input type="date" name="visitDate" id="visitDate" onChange={ (e) => { setDateVisit(e.target.value) }} required/>
                        </div>
                        <div className="input-item">
                            <label htmlFor="visitTime">Time of Visit:</label>
                            <input type="time" name="visitTime" id="visitTime" onChange={ (e) => { setTimeVisit(e.target.value) }} required/>
                        </div>
                    </div>

                    <div className="input-item">
                        <label htmlFor="modeTransport">Mode of Transport:</label>
                        <select name="modeTransport" id="modeTransport" onChange={ (e) => { setModeTransport(e.target.value) }} required>
                            <option value="" style={{ display: "none" }}>Choose mode of transport</option>
                            <option value="Private">Private</option>
                            <option value="Commute">Commute</option>
                        </select>
                    </div>

                    <div className="input-item">
                        <label htmlFor="pickUpPoint">Preferred Pick-up Point(for those who would commute):</label>
                        <input type="text" name="pickUpPoint" id="pickUpPoint" onChange={ (e) => { setPickUpPoint(e.target.value) }} required disabled/>
                    </div>

                    <div className="input-item">
                        <label htmlFor="pickUpPoint">How many people would be visiting?</label>
                        <input type="text" name="visitorCount" id="visitorCount" onChange={ (e) => { setVisitorCount(e.target.value) }} required/>
                    </div>

                    <div className="input-item">
                        <label htmlFor="addtlNotes">Additional Notes(Optional)</label>
                        <textarea name="addtlNotes" id="addtlNotes" cols="30" rows="5"  onChange={ (e) => { setAddtlNotes(e.target.value) }} required></textarea>
                    </div>

                        <div className="recaptcha">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={ REACT_APP_RECAPTCHA_SITE_KEY }
                                onChange={onChange}
                                onExpired={resetCaptcha}
                                size="normal"
                            />
                        </div>
                        
                    <div className="input-item">
                        <button type="submit" id="btnContactSubmit" disabled>Submit</button>
                    </div>

                </form>
            </div>
        </div>
    )
}

export default BookTripping