import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

const impressionsImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-1-thumbnail.JPG'
const editionsImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/editions-hermosa-tablet.jpg'
const PropertiesImg = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/pavilion/pavilion-img-1.jpg"
const AmenitiesImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/amenity-nodes/nodes/tree-house.jpg'


// Not used
const AdditionalDetails = props => {
    const [ isBalcony, setBalcony] = useState(false)

    return (
        <div className="additional-details">
            <div className="detail">
                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-floor-area.png" alt="" loading="lazy"/>
                <h4>{ props.floorArea }</h4>
                <small>Floor area</small>
            </div>
            <div className="detail">
                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-lot-area.png" alt="" loading="lazy"/>
                <h4>{ props.lotArea}</h4>
                <small style={{textAlign: "center"}}>Typical<br />Lot area</small>
            </div>
            <div className="detail">
                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-shower2.png" alt="" loading="lazy"/>
                <h4>{ props.bathroom }</h4>
                <small>Bathrooms</small>
            </div>
            <div className="detail">
                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-bed.png" alt="" loading="lazy"/>
                <h4>{ props.bedroom }</h4>
                <small>Bedrooms</small>
            </div>
            <div className="detail">
                <img src={ isBalcony 
                    ? "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-balcony.png" 
                    : "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-carport.png"} alt="" loading="lazy"/>
                { isBalcony ?
                    <>
                        <h4>{ props.balcony }</h4>
                        <small>Balcony</small>
                    </>
                    :
                    <>
                        <h4>{ props.carport }</h4>
                        <small>Carport</small>
                    </>
                }
            </div>
        </div>
    )
}

const Amenities = (props) => {
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 200), top: (props.posY + 24) }}>
            <div className="modal-body">
                <div className="modal-img" style={{ backgroundImage: "url(" + AmenitiesImg + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                    {/* <h4>Amenities</h4> */}
                </div>
                <h4>El Patio</h4>
                <small>Amenity Area</small>
                <p className="amenities-desc">Discover a stunning lifestyle hub at PHirst Centrale township. Indulge in style with exciting amenities for social connections.</p>
                <div className="modal-btn">
                    {/* <p>Coming Soon!</p> */}
                    <Link to="/amenity-nodes" ><button>Read More</button></Link>
                </div>
            </div>
        </div>
    )
}

const SalesPavilion = (props) => {
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 200), top: (props.posY + 100) }}>
        <div className="modal-body">
            <div className="modal-img" style={{ backgroundImage: "url(" + PropertiesImg + ")"}}>
                <a href="#" onClick={ props.hideModal }>
                    <i className="bi-x"></i>
                </a>
            </div>
            <h4>Le Pavilion</h4>
            <small>Sales Office</small>
            <p>Do you find yourself with a query about your home and community at PHirst Centrale? We’d be happy to guide you to make home decisions and address your questions.</p>
            <div className="modal-btn">
                {/* <p>Coming Soon!</p> */}
                <Link to="/sales-pavilion" ><button>See More</button></Link>
            </div>
        </div>
    </div>
    )
}

const ImpressionsUnit = (props) => {
    const details = { id:"amaniSeries", lotArea: "88 sqm", floorArea: "54 sqm", bathroom: "2", bedroom: "3", carport: "1" }
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 200), top: (props.posY + 100) }}>
            <div className="modal-body impressions">
                <div className="modal-img" style={{ backgroundImage: "url(" + impressionsImg + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                </div>
                <h4 className="impressions-title">PHirst <span>Impressions</span></h4>
                <p>Experience the magic of urban living at PHirst Impressions, where comfortable suburban living meets a community that fosters meaningful connections. Make PHirst Impressions your new home in the heart of PHirst Centrale, where convenience and comfort awaits.</p>
                {/* { <AdditionalDetails {...details} /> } */}
                <div className="modal-btn">
                    <Link to="/phirst-impressions" ><button>See More</button></Link>
                </div>
            </div>
        </div>
    )
}

const EditionsUnit = (props) => {
    const details = { id:"amaniSeries", lotArea: "88 sqm", floorArea: "54 sqm", bathroom: "2", bedroom: "3", carport: "1" } // Not used
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 120), top: (props.posY + 100) }}>
            <div className="modal-body editions">
                <div className="modal-img" style={{ backgroundImage: "url(" + editionsImg + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                </div>
                <h4 className="editions-title">PHirst <span>Editions</span></h4>
                <p>Indulge in the art of living at PHirst Editions, where the comforts of suburban serenity merge with the vibrancy of urban lifestyle at PHirst Centrale. Discover the perfect balance of privacy and socialization, and experience the elevation of your life's best moments.</p>
                {/* { <AdditionalDetails {...details} /> } */}
                <div className="modal-btn">
                    <Link to="/phirst-editions" ><button>See More</button></Link>
                </div>
            </div>
        </div>
    )
}
const EditionsUnit2 = (props) => {
    const details = { id:"amaniSeries", lotArea: "88 sqm", floorArea: "54 sqm", bathroom: "2", bedroom: "3", carport: "1" } // Not used
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 120), top: (props.posY) }}>
            <div className="modal-body editions">
                <div className="modal-img" style={{ backgroundImage: "url(" + editionsImg + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                </div>
                <h4 className="editions-title">PHirst <span>Editions</span></h4>
                <p>Indulge in the art of living at PHirst Editions, where the comforts of suburban serenity merge with the vibrancy of urban lifestyle at PHirst Centrale. Discover the perfect balance of privacy and socialization, and experience the elevation of your life's best moments.</p>
                {/* { <AdditionalDetails {...details} /> } */}
                <div className="modal-btn">
                    <Link to="/phirst-editions" ><button>See More</button></Link>
                </div>
            </div>
        </div>
    )
}

export { Amenities, SalesPavilion, ImpressionsUnit, EditionsUnit, EditionsUnit2 }