import React from "react";

import UnitTemplate from "./unit-template";

const CalistaEnd = () => {

    // Unit Specification
    const specsObj = {
        specs: [
            {
                icon: "area",
                desc: "Typical Lot Area",
                quantity: "44 sqm"
            },
            {
                icon: "area",
                desc: "Floor Area",
                quantity: "40 sqm"
            },
            {
                icon: "bath",
                desc: "Bathroom",
                quantity: "1"
            },
            {
                icon: "bed",
                desc: "Bedrooms",
                quantity: "2"
            },
            {
                icon: "car",
                desc: "Carport",
                quantity: "1"
            },
            {
                icon: "expand",
                desc: "Expandable",
                quantity: ""
            },
        ]
    }

    // Carousel Images
    const carouselImgs = {
        cImgs: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/carousel/calista-end-2-carousel.png",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/carousel/calista-end-1-carousel.png",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/calista-end-4.JPG"
        ]
    }

    // Gallery Images
    const imgGallery = {
        imgs: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-end/calista-end-exterior-1.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-end/calista-end-exterior-2.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-end/calista-end-exterior-3.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-end/calista-end-interior-1.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-end/calista-end-interior-2.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-end/calista-end-interior-3.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-end/calista-end-interior-4.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-end/calista-end-interior-5.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-end/calista-end-interior-6.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-end/calista-end-interior-7.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-end/calista-end-interior-8.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-end/calista-end-interior-9.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-end/calista-end-interior-10.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-end/calista-end-interior-11.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-end/calista-end-interior-12.JPG",
        ]
    }
     
    return (
        <>
            <UnitTemplate 
                logo="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/logos/phirst-impressions-centrale-logo-white.png"
                bg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/calista-end-4.JPG"
                className="calista-end"
                mainTitle="Calista End"
                mainDesc=""
                { ...specsObj }
                { ...imgGallery }
                { ...carouselImgs }


                introImg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/calista-end-4.JPG"
                introDesc="If you hold an affinity towards cultivating a flourishing garden in a spacious land, the Calista End Unit holds the potential to cater to your requirements. An exceptional feature of this particular property is its location at the corner, thereby offering an expanded lot area in contrast to the interior units."
                floorPlanGf='https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Floor-plans/calista-end-GF.jpg'
                floorPlan2f='https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Floor-plans/calista-end-2F.jpg'
                floorPlanClassName='calista-end'
            />
        </>
    )
}

export default CalistaEnd