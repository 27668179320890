import React, { useEffect, useState } from "react"

import '../css/loadScreen.css'

const LoadScreen = (props) => {

    const brandLogo = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/logos/centrale-no-hermosa-logo-v2.png'
    
    useEffect(() => {
        var loader = document.getElementById('loader')

        if (!props.isLoading) {
            setTimeout(() => {
                loader.style.opacity = "0"
            }, 400)
            setTimeout(() => {
                loader.style.display = "none"
            }, 600)
        }
    }, [props.isLoading])

    return (
        <div className="loading-screen" id="loader">
            <div>
                <img className="brand-logo" src={brandLogo} alt="" />
                <div className="loader"></div>
            </div>
        </div>
    )
}

export default LoadScreen