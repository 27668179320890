export const coords = [
    {
        id: "amenities",
        title: "Amenities",
        shape: "poly",
        name: "amenities",
        // coords: [1249,553,1290,533,1255,499,1280,474,1290,479,1315,455,1316,448,1311,444,1333,423,1409,455,1460,400,1289,334,1234,383,1297,409,1251,453,1264,412,1255,412,1258,405,1217,387,1205,384,1192,353,1174,352,1172,355,1176,364,1171,376,1146,374,1127,414,1126,401,1090,396,1095,394,1041,349,1009,362,1001,356,890,400,900,411,862,427,910,477,984,446,1035,501,1092,508,1094,520,1142,525,1144,518,1220,527],
        coords: [1162,314,1186,307,1220,303,1263,304,1297,309,1325,321,1342,295,1485,307,1491,437,1632,551,1646,583,1635,679,1675,814,1676,871,1655,940,1677,1137,817,934,943,771,1000,684,1016,650,1015,532,1018,440,1076,323,1135,327,1149,321],
        fillColor: "rgba(75, 128, 169, .7)",
    },
    {
        id: "marketingTent",
        title: "Marketing Tent",
        shape: "poly",
        name: "marketing tent",
        // coords: [907,720,1041,935,1044,937,1057,930,1069,952,1389,760,1477,854,1602,772,1610,752,1645,728,1561,655,1622,619,1619,618,1627,594,1657,576,1481,441,1229,563,1254,588,1254,594,1249,596,1222,567],
        coords: [1062,209,1070,275,1215,286,1221,279,1343,288,1338,296,1550,311,1571,190,1542,187,1544,167,1540,165,1541,160,1431,155,1445,135,1424,129,1428,110,1337,83,1317,93,1315,102,1300,96,1236,132,1192,130,1152,154,1139,154,1065,192,1067,204],
        fillColor: "rgba(75, 128, 169, .7)",
    },
    {
        id: "amaniSeries",
        title: "Amani Series",
        shape: "poly",
        name: "amani series",
        // coords: [598,305,565,315,645,433,1117,247,1117,244,1102,239,1101,226,1040,181,1032,140,1034,138,1034,134,1041,127,1041,124,995,101,934,120,925,116,921,117,922,122,865,140,859,137,854,139,855,143,794,161,788,157,785,159,787,163,722,184,716,180,713,181,714,188,645,209,638,203,635,205,638,211,550,238,550,243,582,283,586,282],
        coords: [575,330,579,342,610,341,617,361,608,366,621,401,576,425,581,446,781,490,1075,295,1076,289,1073,270,1067,265,1056,262,999,259,999,256,1004,254,994,202,1009,192,1008,187,916,196,882,211,871,212,866,214,866,216,834,230,823,231,823,234,813,238,791,236,791,231,751,238,715,259,720,274,674,293,660,293,656,295,658,299],
        fillColor: "rgba(75, 128, 169, .7)",
    },
    {
        id: "aloraSeries",
        title: "Alora Series",
        shape: "poly",
        name: "alora series",
        // coords: [1356,161,1351,238,1320,260,1588,354,1609,324,1644,288,1660,230,1674,220,1676,215,1601,193,1602,185,1598,184,1590,188,1533,173,1534,164,1530,164,1521,168,1469,154,1469,146,1467,146,1457,150,1395,130,1343,152,1344,156],
        coords: [1504,254,1494,323,1502,381,1600,510,1866,681,2083,617,2174,455,2167,452,2141,454,1979,389,1874,394,1892,342,1888,341,1866,341,1777,294,1730,290,1739,261,1730,260,1696,223,1698,215,1535,193,1533,212,1541,213,1546,220,1541,253],
        // coords: [513,749,737,797,949,586,947,560,840,545,818,472,842,461,841,450,599,420,532,441,519,415,515,414,494,420,375,402,224,438,228,445,148,464,152,472,214,483,217,488,226,490,267,543,262,548,264,553,272,555,253,565,257,570,124,632,109,628,104,633,124,663,476,739,312,863,616,932,714,955,824,986,905,1015,992,1044,1098,1075,1163,1091,1242,1107,1258,1105,1290,1107,1322,1112,1352,1118,1389,1127,1419,1137,1473,1149,1537,1160,1596,1171,1677,1189,1668,1133,1660,1089,1651,1029,1651,985,1654,937,1662,886,1666,853,1665,817,1655,765,1637,719,1625,685,1621,653,1620,623,1624,597,1649,605,1673,611,1696,614,1731,614,1747,614,1844,684,2082,620,2173,456,2169,452,2141,454,1975,387,1876,392,1893,343,1889,341,1868,341,1776,291,1734,289,1742,260,1729,259,1696,221,1699,216,1536,192,1533,211,1541,213,1545,221,1540,251,1502,251,1492,325,1502,379,1606,506,1720,593,1696,593,1668,591,1628,576,1594,573,1589,597,1586,632,1585,661,1587,690,1590,709,1604,738,1614,773,1617,809,1615,844,1610,874,1600,917,1594,950,1592,978,1592,1011,1593,1044,1597,1073,1604,1117,1425,1085,1384,1070,1341,1059,1303,1053,1260,1053,1157,1033,1044,1002,909,957,757,912,597,875,448,849,396,837],
        fillColor: "rgba(75, 128, 169, .7)",
    },
    {
        id: "editions2",
        title: "Editions 2",
        shape: "poly",
        name: "editions 2",
        coords: [108,634,123,663,738,798,947,583,945,561,843,546,820,474,842,462,840,450,599,417,535,437,520,409,499,417,377,402,224,439,226,444,149,464,158,476,219,484,221,491,226,491,265,547,260,548,263,553,269,555,252,564,255,569,127,634,115,632],
        fillColor: "rgba(75, 128, 169, .7)",
    }
]
