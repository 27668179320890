import React, { useEffect, useRef, useState } from "react";

import '../css/amenities.css'

import LoadScreen from "../../others/components/loadScreen";
import BackButton from "../../others/components/backButton";

const AmenityNodes = () => {

    const amenityNodeImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/amenity-nodes/amenity-img.png'

    const primeHubImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/amenity-nodes/nodes/prime-hub.jpg'
    const primeHub2Img = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/amenity-nodes/nodes/prime-hub-2.jpg'
    const panoramaImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/amenity-nodes/nodes/panorama-patch.jpg'
    const passCentralImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/amenity-nodes/nodes/pass-central.jpg'
    const puddlePlaceImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/amenity-nodes/nodes/puddle-place.jpg'
    const treeHouseImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/amenity-nodes/nodes/tree-house.jpg'
    const tunnelClimbImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/amenity-nodes/nodes/tunnel-climb.jpg'

    const leftArrow = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/icon-left-arrow.png'
    const rightArrow = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/icon-right-arrow.png'

    const friendsImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/family-photos/friends-img-2.jpg'
    const familyImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/family-photos/family-img-2.jpg'

    const [ nodeContState, setNodeContState ] = useState(null)
    const previousNodeState = useRef(1)

    const [ loadState, setLoadState ] = useState(false)

    useEffect(() => {
        if (nodeContState != null ) {
            let node, prevStateVal = previousNodeState.current - 1
            let nodeCollection = document.querySelectorAll('.node-item')
            let nodeButtons = document.querySelectorAll('button.node-btn')

            // Switches carousel slide
            // Triggers whenever nodeContState updates
            switch (nodeContState) {
                case 1:
                    node = nodeCollection[0]
                    nodeButtons[0].classList.add('active')
                    nodeButtons[(prevStateVal)].classList.remove('active')
                    break;
            
                case 2:
                    node = nodeCollection[1]
                    nodeButtons[1].classList.add('active')
                    nodeButtons[(prevStateVal)].classList.remove('active')
                    break;
            
                case 3:
                    node = nodeCollection[2]
                    nodeButtons[2].classList.add('active')
                    nodeButtons[(prevStateVal)].classList.remove('active')
                    break;
            
                case 4:
                    node = nodeCollection[3]
                    nodeButtons[3].classList.add('active')
                    nodeButtons[(prevStateVal)].classList.remove('active')
                    break;
            
                case 5:
                    node = nodeCollection[4]
                    nodeButtons[4].classList.add('active')
                    nodeButtons[(prevStateVal)].classList.remove('active')
                    break;
            
                case 6:
                    node = nodeCollection[5]
                    nodeButtons[5].classList.add('active')
                    nodeButtons[(prevStateVal)].classList.remove('active')
                    break;
            
                case 7:
                    node = nodeCollection[6]
                    nodeButtons[6].classList.add('active')
                    nodeButtons[(prevStateVal)].classList.remove('active')
                    break;
    
                default:
                    break;
            }
    
            // scrolls into the section of `node` variable
            node.scrollIntoView({behavior: 'smooth', block: 'nearest', inline:'center'})
            previousNodeState.current = nodeContState
        }

    }, [nodeContState])
    
    useEffect(() => {
        let nodeButtons = document.querySelectorAll('button.node-btn')
        nodeButtons[0].classList.add('active')

        setLoadState(true)

        window.addEventListener('resize', reset)
        return () => {
            window.removeEventListener('resize', reset)
        }
    }, [])

    // Resets the carousel. Default is the 1st slide (Prime Hub)
    const reset = () => {
        let nodeButtons = document.querySelectorAll('button.node-btn')
        nodeButtons[previousNodeState.current - 1].classList.remove('active')
        nodeButtons[0].classList.add('active')

        let nodeCont = document.getElementById('nodeCont')
        nodeCont.scrollLeft = 0

        setNodeContState(1)
        previousNodeState.current = 1
    }

    const scrollPrev = () => {
        let currVal = nodeContState

        if (currVal != 1) {
            setNodeContState(currVal - 1)
        } else if (currVal == 1) {
            setNodeContState(1)
        }
    }

    const scrollNext = () => {
        let currVal = nodeContState

        if (currVal != 6) {
            setNodeContState(currVal + 1)
        } else if (currVal == 6) {
            setNodeContState(1)
        }

    }

    return (
        <>
            { loadState ? <LoadScreen /> : '' }
            <div className="content amenities">
                <BackButton/>

                <main className="main" style={{ backgroundImage: 'url(' + primeHub2Img + ')'}}>
                    <img src={ amenityNodeImg } alt="" />
                    <div className="text-content">
                        <h2>El Patio</h2>
                        <h6>Amenity Area</h6>
                        <p>Discover a stunning lifestyle hub at PHirst Centrale township. Indulge in style with exciting amenities for social connections.</p>
                        <small>Scroll down to see more</small>
                    </div>
                    <div className="color-overlay"></div>
                </main>

                <div className="divider-section" style={{ backgroundImage: 'url(' + familyImg + ')'}}>
                    <p>Experience the ultimate urban lifestyle at PHirst Centrale township's amenity area! Get ready to unwind and have some fun with friends and family while enjoying a range of exciting amenities designed for social connections. <br /><br /> Whether you're looking to relax or have an adventure, PHirst Centrale's amenity area has got you covered. Come and discover this stunning lifestyle hub today!</p>
                    <div className="color-overlay"></div>
                </div>

                <div className="nodes mobile">
                    <h2>Prime Hub</h2>
                    <div className="img-cont">
                        <img src={ primeHubImg } alt="" />
                        <div className="border-div"></div>
                    </div>
                    <p>Step inside and experience the excitement of a clubhouse like no other. The Prime Hub is the perfect place to relax, unwind, and connect with your fellow neighbors.</p>
                    <p>Located in the heart of PHirst Centrale township, the Prime Hub is just a short walk away from your home. It's the perfect place to escape the hustle and bustle of everyday life and enjoy all the comforts of home.</p>
                </div>

                {/* <div className="nodes mobile">
                    <h2>Prime Hub 2</h2>
                    <div className="img-cont">
                        <img src={ primeHub2Img } alt="" />
                        <div className="border-div"></div>
                    </div>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo earum facilis, placeat, vitae libero magnam eligendi debitis laboriosam eveniet cum deleniti quisquam excepturi? Repudiandae totam possimus quaerat rem, ipsa quae?</p>
                </div> */}

                {/* Mobile View */}
                <div className="nodes mobile">
                    <h2>Panorama Patch</h2>
                    <div className="img-cont">
                        <img src={ panoramaImg } alt="" />
                        <div className="border-div"></div>
                    </div>
                    <p>As the sun sets and the stars come out, sit back and relax while enjoying your favorite movies under the night sky. From classic blockbusters to indie favorites, we've got you covered as our Panorama Patch amenity provides a fun and exciting atmosphere for everyone!</p>
                </div>

                <div className="nodes mobile">
                    <h2>Pass Central</h2>
                    <div className="img-cont">
                        <img src={ passCentralImg } alt="" />
                        <div className="border-div"></div>
                    </div>
                    <p>Looking for a slam dunk of a good time? Look no further than the Pass Central! Get your game on with PHirst Centrale’s basketball court amenity, designed to keep you at the top of your game. Whether you're an experienced baller or just starting out, we've got everything you need to take your skills to the next level.</p>
                </div>

                <div className="nodes mobile">
                    <h2>Puddle Place</h2>
                    <div className="img-cont">
                        <img src={ puddlePlaceImg } alt="" />
                        <div className="border-div"></div>
                    </div>
                    <p>Dip your toes into the Puddle Place and feel the stress of the day melt away. Take a refreshing swim, or just lounge poolside and soak up the sun. With plenty of space to stretch out, you can enjoy a day of leisure or bring your friends for a fun-filled afternoon.</p>
                    <p>Located within the vibrant community of PHirst Centrale, our swimming pool amenity is the ultimate destination for relaxation and fun.</p>
                </div>

                <div className="nodes mobile">
                    <h2>Tree House</h2>
                    <div className="img-cont">
                        <img src={ treeHouseImg } alt="" />
                        <div className="border-div"></div>
                    </div>
                    <p>Let your kids explore the possibilities of life! As they grow older, they’ll have bigger dreams that take root in the adventures they’ve experienced.</p>
                    <p>Our Tree House is more than just a place to play – it's a magical world where kids can make new friends, create lasting memories, and let their imaginations soar. Come and experience the excitement for yourself!</p>
                </div>

                <div className="nodes mobile">
                    <h2>Tunnel Climb</h2>
                    <div className="img-cont">
                        <img src={ tunnelClimbImg } alt="" />
                        <div className="border-div"></div>
                    </div>
                    <p>Welcome to the most thrilling adventure for kids in PHirst Centrale - the tunnel climb amenity!</p>
                    <p>Get ready to explore an exciting world of obstacles that will challenge your kids’ courage and test their agility. With multiple levels of climbing and crawling, this amenity will keep kids entertained for hours on end.</p>
                </div>

                {/* Desktop View */}
                <div className="nodes-collection desktop">
                    <div className="node-top-control">
                        <button className="node-btn active" onClick={ () => { setNodeContState(1) }}>Prime Hub</button>
                        {/* <button className="node-btn" onClick={ () => { setNodeContState(2) }}>Prime Hub 2</button> */}
                        <button className="node-btn" onClick={ () => { setNodeContState(2) }}>Panorama Patch</button>
                        <button className="node-btn" onClick={ () => { setNodeContState(3) }}>Pass Central</button>
                        <button className="node-btn" onClick={ () => { setNodeContState(4) }}>Puddle Place</button>
                        <button className="node-btn" onClick={ () => { setNodeContState(5) }}>Tree House</button>
                        <button className="node-btn" onClick={ () => { setNodeContState(6) }}>Tunnel Climb</button>
                    </div>
                    <div className="node-cont" id="nodeCont">
                        <div className="node-item">
                            <div className="text-cont">
                                <h2>Prime Hub</h2>
                                <p>Step inside and experience the excitement of a clubhouse like no other. The Prime Hub is the perfect place to relax, unwind, and connect with your fellow neighbors.</p>
                                <p>Located in the heart of PHirst Centrale township, the Prime Hub is just a short walk away from your home. It's the perfect place to escape the hustle and bustle of everyday life and enjoy all the comforts of home.</p>
                            </div>
                            <div className="img-cont">
                                <img src={ primeHubImg } alt="" />
                                <div className="border-div"></div>
                            </div>
                        </div>
                        {/* <div className="node-item">
                            <div className="text-cont">
                                <h2>Prime Hub 2</h2>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Architecto distinctio itaque iste voluptatum possimus, incidunt quae provident, illum consequatur similique repellat! Qui modi quod hic delectus quaerat minus repellendus error?</p>
                            </div>
                            <div className="img-cont">
                                <img src={ primeHub2Img } alt="" />
                                <div className="border-div"></div>
                            </div>
                        </div> */}
                        <div className="node-item">
                            <div className="text-cont">
                                <h2>Panorama Patch</h2>
                                <p>As the sun sets and the stars come out, sit back and relax while enjoying your favorite movies under the night sky. From classic blockbusters to indie favorites, we've got you covered as our Panorama Patch amenity provides a fun and exciting atmosphere for everyone!</p>
                            </div>
                            <div className="img-cont">
                                <img src={ panoramaImg } alt="" />
                                <div className="border-div"></div>
                            </div>
                        </div>
                        <div className="node-item">
                            <div className="text-cont">
                                <h2>Pass Central</h2>
                                <p>Looking for a slam dunk of a good time? Look no further than the Pass Central! Get your game on with PHirst Centrale’s basketball court amenity, designed to keep you at the top of your game.</p>
                                <p>Whether you're an experienced baller or just starting out, we've got everything you need to take your skills to the next level.</p>
                            </div>
                            <div className="img-cont">
                                <img src={ passCentralImg } alt="" />
                                <div className="border-div"></div>
                            </div>
                        </div>
                        <div className="node-item">
                            <div className="text-cont">
                                <h2>Puddle Place</h2>
                                <p>Dip your toes into the Puddle Place and feel the stress of the day melt away. Take a refreshing swim, or just lounge poolside and soak up the sun. With plenty of space to stretch out, you can enjoy a day of leisure or bring your friends for a fun-filled afternoon.</p>
                                <p>Located within the vibrant community of PHirst Centrale, our swimming pool amenity is the ultimate destination for relaxation and fun.</p>
                            </div>
                            <div className="img-cont">
                                <img src={ puddlePlaceImg } alt="" />
                                <div className="border-div"></div>
                            </div>
                        </div>
                        <div className="node-item">
                            <div className="text-cont">
                                <h2>Tree House</h2>
                                <p>Let your kids explore the possibilities of life! As they grow older, they’ll have bigger dreams that take root in the adventures they’ve experienced.</p>
                                <p>Our Tree House is more than just a place to play – it's a magical world where kids can make new friends, create lasting memories, and let their imaginations soar. Come and experience the excitement for yourself!</p>
                            </div>
                            <div className="img-cont">
                                <img src={ treeHouseImg } alt="" />
                                <div className="border-div"></div>
                            </div>
                        </div>
                        <div className="node-item">
                            <div className="text-cont">
                                <h2>Tunnel Climb</h2>
                                <p>Welcome to the most thrilling adventure for kids in PHirst Centrale - the tunnel climb amenity!</p>
                                <p>Get ready to explore an exciting world of obstacles that will challenge your kids’ courage and test their agility. With multiple levels of climbing and crawling, this amenity will keep kids entertained for hours on end.</p>
                            </div>
                            <div className="img-cont">
                                <img src={ tunnelClimbImg } alt="" />
                                <div className="border-div"></div>
                            </div>
                        </div>
                    </div>
                    {/* Navigation */}
                    <div className="node-nav">
                        <img className="left-arrow-nav" src={leftArrow} alt="" onClick={ scrollPrev } draggable={false} />
                        <img className="right-arrow-nav" src={rightArrow} alt="" onClick={ scrollNext } draggable={false} />
                    </div>
                </div>

                <div className="color-overlay"></div>
            </div>
        </>
    )
}

export default AmenityNodes