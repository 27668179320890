import React, { useEffect, useState } from "react";

import { Amenities, SalesPavilion, ImpressionsUnit, EditionsUnit, EditionsUnit2 } from "./content";

// import { Data } from '../assets/files/modalContents'

const Modal = (props) => {

    // Toggles which content should be returned, depending on the value of `title`.
    const Render = (title) => {
        switch (title) {
            case "Amenities":
                return <Amenities {...props} />
                break;
            case "Marketing Tent":
                return <SalesPavilion {...props} />
                break;
            case "Amani Series":
                return <ImpressionsUnit {...props} />
                break;
            case "Alora Series":
                return <EditionsUnit {...props} />
                break;
            case "Editions 2":
                return <EditionsUnit2 {...props} />
                break;
            // default:
            //     break;
        }
    }
    
    return (
        <>
            { Render(props.title) }
            <div className="dark-screen" onClick={ props.hideModal }></div>
        </>
    )
    
}


export default Modal
