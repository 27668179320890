import React, { useState, useEffect, useRef } from "react";

import '../css/slides.css'

const Slides = () => {

    const [ slideState, setSlideState ] = useState(null) 
    const prevSlideState = useRef(1)

    useEffect(() => {
        let slide = document.getElementById("slide1")
        // slide.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'})
        slide.classList.add('show')
    }, [])

    useEffect(() => {
        if (slideState != null ) {
            let node, prevStateVal = prevSlideState.current - 1
            let nodeCollection = document.querySelectorAll('.slide-item')

            // Switches/Toggles the slide viewed on the viewport.
            switch (slideState) {
                case 1:
                    node = nodeCollection[0]
                    node.classList.add("show")
                    nodeCollection[(prevStateVal)].classList.remove("show")
                    break;
            
                case 2:
                    node = nodeCollection[1]
                    node.classList.add("show")
                    nodeCollection[(prevStateVal)].classList.remove("show")
                    break;
            
                case 3:
                    node = nodeCollection[2]
                    node.classList.add("show")
                    nodeCollection[(prevStateVal)].classList.remove("show")
                    break;
            
                case 4:
                    node = nodeCollection[3]
                    node.classList.add("show")
                    nodeCollection[(prevStateVal)].classList.remove("show")
                    break;
            
                case 5:
                    node = nodeCollection[4]
                    node.classList.add("show")
                    nodeCollection[(prevStateVal)].classList.remove("show")
                    break;
    
                default:
                    break;
            }
    
            // Scrolls the slide into the viewport.
            node.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'})
            prevSlideState.current = slideState
        }
    }, [slideState])


    return (
        <>
            <div className="slide-section">
                <div className="slide-container">
                    <div className="placeholder-item"></div>
                    <SlideComponent
                        text={<><span>Welcome to the Sales Pavilion of PHirst Centrale,</span> the township that offers a perfect blend of modern living, business opportunities, and retail experiences. <br/><br/> With its three components - Dwell, Create, and Thrive, PHirst Centrale provides its residents with an all-inclusive lifestyle.</>}
                        slideBg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/pavilion/pavilion-img-1.jpg"
                        slideNumber={1}
                        withPrevSlide={false}
                        withNextSlide={true}
                        nextSlide={ () => {setSlideState(2)}}
                        link=""
                        linkDesc=""
                    />
                    <SlideComponent
                        text="Our residential enclaves, PHirst Impressions and PHirst Editions, offer contemporary design and a lush nature experience for a unique living experience."
                        slideBg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/pavilion/pavilion-img-2.jpg"
                        slideNumber={2}
                        withPrevSlide={true}
                        withNextSlide={true}
                        prevSlide={ () => {setSlideState(1)}}
                        nextSlide={ () => {setSlideState(3)}}
                        link="phirst-impressions"
                        linkDesc="Go to DWELL to know more"
                    />
                    <SlideComponent
                        text="The PHirst Fairgrounds provide an ideal environment for businesses with high-value lots and endless possibilities."
                        slideBg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/pavilion/pavilion-img-3.jpg"
                        slideNumber={3}
                        withPrevSlide={true}
                        withNextSlide={true}
                        prevSlide={ () => {setSlideState(2)}}
                        nextSlide={ () => {setSlideState(4)}}
                        link="phirst-fairgrounds"
                        linkDesc="Go to CREATE to know more"
                    />
                    <SlideComponent
                        text="Meanwhile, the PHirst Boroughs redefine retail experience by integrating urban and environmental design with a range of shops to satisfy your wants and needs."
                        slideBg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/pavilion/pavilion-img-4.jpg"
                        slideNumber={4}
                        withPrevSlide={true}
                        withNextSlide={true}
                        prevSlide={ () => {setSlideState(3)}}
                        nextSlide={ () => {setSlideState(5)}}
                        link="phirst-boroughs"
                        linkDesc="Go to THRIVE to know more"
                    />
                    <SlideComponent
                        text="Discover a vibrant community that promotes a balanced and revitalized lifestyle at PHirst Centrale Hermosa. Contact us now for inquiries on our house and lot options and commercial spaces for businesses!"
                        slideBg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/pavilion/pavilion-img-5.jpg"
                        slideNumber={5}
                        withPrevSlide={true}
                        withNextSlide={false}
                        prevSlide={() => {setSlideState(4)}}
                        link=""
                        linkDesc=""
                    />
                    <div className="placeholder-item"></div>
                </div>
            </div>
        </>
    )
}

// Component for the Slides item/s
const SlideComponent = (props) => {
    const [ linkState, setLinkState ] = useState()

    useEffect(() => {
        if(props.link === "") {
            setLinkState(false)
        } else {
            setLinkState(true)
        }
    }, [])
    return (
        <>
            <div className="slide-item" id={"slide" + props.slideNumber} style={{ backgroundImage: 'url(' + props.slideBg + ')'}}>
                <div className="text-cont">
                    <p>{ props.text }</p>
                    { linkState ? <a href={props.link}>{props.linkDesc}</a> : ""}
                </div>
                { props.withPrevSlide ? 
                    <span className="prev-btn" onClick={ props.prevSlide }>
                        <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/prev-icon.png" alt="" />
                    </span> : ""}
                { props.withNextSlide ?
                    <span className="next-btn" onClick={ props.nextSlide }>
                        <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/next-icon.png" alt="" />
                    </span> : ""}
            </div>
        </>
    )
}

export default Slides