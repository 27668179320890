import React from "react";

import UnitTemplate from "./unit-template";

const CalistaMid = () => {
    
    // Unit Specifications
    const specsObj = {
        specs: [
            {
                icon: "area",
                desc: "Typical Lot Area",
                quantity: "44 sqm"
            },
            {
                icon: "area",
                desc: "Floor Area",
                quantity: "40 sqm"
            },
            {
                icon: "bath",
                desc: "Bathroom",
                quantity: "1"
            },
            {
                icon: "bed",
                desc: "Bedrooms",
                quantity: "3"
            },
            {
                icon: "car",
                desc: "Carport",
                quantity: "1"
            },
            {
                icon: "expand",
                desc: "Expandable",
                quantity: ""
            },
        ]
    }

    // Carousel Images
    const carouselImgs = {
        cImgs: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/carousel/calista-mid-2-carousel.png",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/carousel/calista-mid-1-carousel.png",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/carousel/calista-mid-3-carousel.png",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/calista-mid-5.JPG"
        ]
    }

    // Gallery Images
    const imgGallery = {
        imgs: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-mid/calista-mid-exterior-1.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-mid/calista-mid-exterior-2.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-mid/calista-mid-interior-1.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-mid/calista-mid-interior-2.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-mid/calista-mid-interior-3.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-mid/calista-mid-interior-4.JPG"
        ]
    }

    return (
        <>
            <UnitTemplate 
                logo="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/logos/phirst-impressions-centrale-logo-white.png"
                bg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/calista-mid-5.JPG"
                className="calista-mid"
                mainTitle="Calista Mid"
                mainDesc=""
                { ...specsObj }
                { ...imgGallery }
                { ...carouselImgs}
                introImg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/calista-mid-5.JPG"
                introDesc="Situated within the townhouse cluster, the Calista Mid presents an alluring option for those who desire a property with a garden. While its lot area is marginally smaller than the Calista End, this unit's charm lies in its modest yet appealing garden space that allows residents to revel in the beauty of nature within their abode."
                floorPlanGf='https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Floor-plans/calista-mid-GF.jpg'
                floorPlan2f='https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Floor-plans/calista-mid-2F.jpg'
                floorPlanClassName='calista-mid'
            />
        </>
    )
}

export default CalistaMid