import React from "react";

const CalistaExterior360 = () => {
    return (
        <>
            <div className="content">
                Calista Exterior
            </div>
        </>
    )
}

export default CalistaExterior360