import React from "react";

import UnitTemplateEditions from "./unit-template";

const UnitCartland = () => {

    // Unit Specification
    const specsObj = {
        specs: [
            {
                icon: "area",
                desc: "Typical Lot Area",
                quantity: "44 sqm"
            },
            {
                icon: "area",
                desc: "Floor Area",
                quantity: "40 sqm"
            },
            {
                icon: "bath",
                desc: "Bathroom",
                quantity: "1"
            },
            {
                icon: "bed",
                desc: "Bedrooms",
                quantity: "2"
            },
            {
                icon: "car",
                desc: "Carport",
                quantity: "1"
            },
            {
                icon: "expand",
                desc: "Expandable",
                quantity: ""
            },
        ]
    }

    // Gallery Images
    const galleryObjs = {
        exterior: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/cartland/Cartland-exterior-1.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/cartland/Cartland-exterior-2.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/cartland/Cartland-exterior-3.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/cartland/Cartland-exterior-4.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/cartland/Cartland-exterior-5.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/cartland/Cartland-exterior-6.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/cartland/Cartland-exterior-7.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/cartland/Cartland-exterior-8.jpg",
        ],
        interior: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/cartland/Cartland-interior-1.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/cartland/Cartland-interior-2.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/cartland/Cartland-interior-3.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/cartland/Cartland-interior-4.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/cartland/Cartland-interior-5.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/cartland/Cartland-interior-6.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/cartland/Cartland-interior-7.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/cartland/Cartland-interior-8.jpg",
        ]
    }

    // Unit Floor Plan
    const floorPlanObjs = {
        floor1: 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/floor-plan/Cartland_GF_v3.jpg',
        floor2: 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/floor-plan/Cartland_2F.jpg'
    }

    return (
        <>
            <UnitTemplateEditions
                title="Cartland 54"
                mainImg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/cartland/Cartland-exterior-6.jpg"
                logo="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/logos/phirst-editions-centrale-logo-white.png"
                mainDesc="Experience the tranquil existence you've longed for alongside your loved ones. Begin by indulging in the allure of a modest yet tastefully designed abode such as the Cartland 54. "
                {...specsObj}
                section2Desc="Embark on a journey of discovery and enrichment by seizing the opportunity to acquire this exceptional PHirst Editions dwelling. With Cartland 54 as your cornerstone, you can manifest a life of boundless possibility and fulfillment!"
                section2Img="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/cartland/Cartland-img-1.jpg"
                {...galleryObjs}
                {...floorPlanObjs}
            />
        </>
    )
}

export default UnitCartland