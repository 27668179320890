import React, { useEffect, useRef, useState } from "react";

import '../css/pavilion.css'

import LoadScreen from "../../others/components/loadScreen";

import { Link } from "react-router-dom";

import BookTripping from "../../others/components/bookATripping";

import Slides from "./slides.js"
import BackButton from "../../others/components/backButton";

const Pavilion = () => {
    
    const sampleBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/sample-bg.jpg'
    const logo = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/logos/centrale-no-hermosa-logo-v2.png'
    const aerialBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/aerial-centrale.jpg'
    const pavilionBg = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/le-pavilion.JPG"

    const [ loadState, setLoadState ] = useState(false)
    const [ isBooking, setBooking ] = useState(false)

    
    useEffect(() => {
        setLoadState(true)
        let delayValue = 1000
        let textItems = document.querySelectorAll('.text-item')
        textItems.forEach((item) => {
            setTimeout(() => {
                item.classList.add('show')
            }, delayValue);
            delayValue += 2000
        })
    }, [])
    
    // Toggles the visibility of Book a tripping modal
    useEffect(() => {
        let bookmodal = document.getElementById('bookTrippingModal')
        if (isBooking) {
            bookmodal.classList.add("show")    
        } else {
            bookmodal.classList.remove("show")
        }

        let bookContent = document.getElementById('bookContent')
        if (bookContent.scrollTop != 0) bookContent.scrollTop = 0
    }, [isBooking])

    return (
        <>
            { loadState ? <LoadScreen /> : ''}
            <div className="content pavilion">
                <BackButton/>

                {/* Main Section */}
                <main className="main" style={{ backgroundImage: 'url(' + sampleBg + ')'}}>
                    <img className="logo" src={ logo } alt="" />
                    <div className="text-content">
                        <div className="text-item">
                            <p className="desc-1">Live in</p>
                            <h2 className="main-desc">Style</h2>
                        </div>
                        <div className="text-item">
                            <p className="desc-1">Indulge in an array of</p>
                            <h2 className="main-desc">Stunning</h2>
                            <p className="desc-2">attractions</p>
                        </div>
                        <div className="text-item">
                            <p className="desc-1">Broaden your</p>
                            <h2 className="main-desc">Social</h2>
                            <p className="desc-2">connections</p>
                        </div>
                        <div className="text-item">
                            <p className="desc-1">Seize your dreamsthrough a</p>
                            <h2 className="main-desc">Simplified</h2>
                            <p className="desc-2">journey</p>
                        </div>
                        <div className="desc-cont">
                            <p>Do you find yourself with a query about your home and community at PHirst Centrale? <br /> We’d be happy to guide you to make home decisions and address your questions.</p>
                        </div>
                    </div>
                    <div className="color-overlay"></div>
                </main>

                {/* Slides Section */}
                <Slides />

                {/* Front Desk Section */}
                <div className="front-desk" style={{ backgroundImage: 'url(' + pavilionBg + ')'}}>
                    <div className="btn-cont">
                        <h4>Front Desk</h4>
                        <a href="#" onClick={ () => {setBooking((prevState) => !prevState)} }>Book a Tripping</a>
                        <a href="/about-us">About Us</a>
                        <a href="/sales-pavilion/360">Le Pavilion 360&deg;</a>
                        <a href="/vicinity-map">Vicinity Map</a>
                        <a href="/explore">Buying Guide</a>
                        <a href="/contact-us">Contact Us</a>
                        <a href="/privacy-policy">Privacy Policy</a>
                    </div>
                </div>

                {/* Book a tripping component */}
                <BookTripping toggleBook={() => {setBooking((prevState) => !prevState)}} />
            </div>
        </>
    )
}

export default Pavilion