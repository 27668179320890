import React, { useEffect, useState } from "react";

import '../css/fairgrounds.css'

import LoadScreen from "../../others/components/loadScreen";
import { ContactFormTemplate } from "../../contact-us/components/contact-index";
import BackButton from "../../others/components/backButton";

const Fairgrounds = () => {

    const fairgroundsLogo = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/logos/fairgounds-logo.png'
    const fairgroundImgMobile = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/fairgrounds/fairgrounds-img-mobile.png'
    const fairgroundImgTablet = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/fairgrounds/fairgrounds-img-tablet.png'
    const fairgroundImgDesktop = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/fairgrounds/fairgrounds-desktop.jpg'

    
    const createBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/fairgrounds/create-bg-mobile.jpg'

    const [ loadState, setLoadState ] = useState(false)

    const [ item1, setItem1State ] = useState(null)
    const [ item2, setItem2State ] = useState(null)
    const [ item3, setItem3State ] = useState(null)
    const [ item4, setItem4State ] = useState(null)

    useEffect(() => {
        if (item1 != null) {
            if (item1 == false) {
                redirectView('item1', 600)
            } else {
                redirectView('item1', 0)
            }
        }
    }, [item1])
    
    useEffect(() => {
        if (item2 != null) {
            if (item2 == false) {
                redirectView('item2', 600)
            } else {
                redirectView('item2', 0)
            }
        }
    }, [item2])
    
    useEffect(() => {
        if (item3 != null) {
            if (item3 == false) {
                redirectView('item3', 600)
            } else {
                redirectView('item3', 0)
            }
        }
    }, [item3])
    
    useEffect(() => {
        if (item4 != null) {
            if (item4 == false) {
                redirectView('item4', 600)
            } else {
                redirectView('item4', 0)
            }
        }
    }, [item4])

    const redirectView = (id, delay) => {
        if(window.innerWidth <= 1024) {
            let item = document.getElementById(id)
            setTimeout(() => {
                item.scrollIntoView({behavior: "smooth", inline: 'center', block: 'start'})
            }, delay);
        }
    } 
    useEffect(() => {
        let mainImg = document.getElementById('mainImgMobile')
        if (window.innerWidth <= 576) {
            mainImg.setAttribute('src', fairgroundImgMobile)
        } else if (window.innerWidth > 576 && window.innerWidth <= 1280) {
            mainImg.setAttribute('src', fairgroundImgTablet)
        }

        setLoadState(true)

    }, [])

    return (
        <>
            { loadState ? <LoadScreen /> : ''}
            <div className="content fairgrounds" style={{ backgroundImage: 'url(' + createBg + ')'}}>
                <BackButton/>

                {/* Main Section */}
                <main className="main">
                    <div className="text-content">
                        <img className="fairground-logo" src={ fairgroundsLogo} alt="" />
                        <h2>
                            <span>PHirst</span>
                            Fairgrounds
                        </h2>
                        <img className="main-img-mobile" id="mainImgMobile" alt="" />
                        <p>Indulge in an array of stunning attractions & harness your imagination in curating ideas for your personal, creative and livelihood ventures through PHirst Centrale Hermosa’s commercial & institutional districts.</p>
                    </div>
                    <div className="img-content">
                        <div>
                            <img src={ fairgroundImgDesktop } alt="" />
                            <div className="border-div"></div>
                        </div>
                    </div>
                    <svg className="svg-rect">
                        <rect width={'100%'} height='100%' style={{fill: 'rgba(135, 62, 151, 1)'}} />
                    </svg>
                    <svg className="svg-rect-2">
                        <rect width={'100%'} height='100%' style={{fill: 'rgba(135, 62, 151, 1)'}} />
                    </svg>
                </main>

                {/* Divider Section */}
                <div className="section-divider">
                    <h4>The Commerce Hub: Your One-Stop Destination for Business</h4>
                    <h6>Permitted Uses</h6>

                    {/* Icons container */}
                    <div className="icons">
                        <div>
                            <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/foodnbeverage.png" alt="" />
                            <p>Food & <br /> Beverage</p>
                        </div>
                        <div>
                            <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/retail.png" alt="" />
                            <p>Retail</p>
                        </div>
                        <div>
                            <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/services.png" alt="" />
                            <p>Services</p>
                        </div>
                        <div>
                            <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/recreational.png" alt="" />
                            <p>Recreational</p>
                        </div>
                        <div>
                            <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/office.png" alt="" />
                            <p>Office</p>
                        </div>
                    </div>

                </div>

                
                <div className="section-text">
                    <div className="text-content">
                        <div className="item" id="item1">
                            <div className="item-header" onClick={ () => { setItem1State(prevState => !prevState)}}>
                                <h6>Food & Beverage</h6>
                                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/fairgrounds-food-v3.png" alt="" />
                            </div>
                            <div className={"item-body " + (item1 ? "show" : "")}>
                                <p>Imagine setting up your restaurant in a bustling community filled with potential customers, all eager to try something new. With our diverse range of businesses, you'll be able to attract a wide range of patrons to your establishment. Whether you're looking to serve up classic comfort food or innovative cuisine, PHirst Fairgrounds is the perfect place to make your mark in the culinary world.</p>
                                <p>Invest in PHirst Fairgrounds and watch your business soar to new heights. Contact us today to learn more about how you can be part of this exciting development.</p>
                                <small onClick={ () => { setItem1State(prevState => !prevState)}}>Close</small>
                            </div>
                        </div>
                        <div className="item" id="item2">
                            <div className="item-header" onClick={ () => { setItem2State(prevState => !prevState)}}>
                                <h6>Merchandise</h6>
                                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/fairgrounds-merchandise-v3.png" alt="" />
                            </div>
                            <div className={"item-body " + (item2 ? "show" : "")}>
                                <p>If you're a merchandise business looking for a new home, PHirst Fairgrounds offers the perfect opportunity for you. Our prime location in Centrale Hermosa provides easy access to a diverse range of customers, allowing you to expand your brand and increase your customer base.</p>
                                <p>Contact us today to learn more about our available lots and how we can help you take your business to the next level.</p>
                                <small onClick={ () => { setItem2State(prevState => !prevState)}}>Close</small>
                            </div>
                        </div>
                        <div className="item" id="item3">
                            <div className="item-header" onClick={ () => { setItem3State(prevState => !prevState)}}>
                                <h6>Services</h6>
                                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/fairgrounds-services-v3.png" alt="" />
                            </div>
                            <div className={"item-body " + (item3 ? "show" : "")}>
                                <p>For service-providers, PHirst Fairgrounds is the ideal location to operate your business. We have a range of spaces that are perfect for service-based businesses such as spas, salons, and other wellness centers. Our spaces are designed to provide a comfortable and relaxing environment for your clients, ensuring that they have an excellent experience. With ample parking and excellent accessibility, your service business is sure to thrive at PHirst Fairgrounds.</p>
                                <p>Inquire now to secure your space and provide exceptional services to our customers.</p>
                                <small onClick={ () => { setItem3State(prevState => !prevState)}}>Close</small>
                            </div>
                        </div>
                        <div className="item" id="item4">
                            <div className="item-header" onClick={ () => { setItem4State(prevState => !prevState)}}>
                                <h6>Office Space</h6>
                                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/fairgrounds-office-v3.png" alt="" />
                            </div>
                            <div className={"item-body " + (item4 ? "show" : "")}>
                                <p>At PHirst Fairgrounds, we understand the importance of having a professional work environment. That's why we offer a range of office spaces to meet your business needs. Our offices are modern, spacious, and equipped with all the necessary amenities to help you run your business effectively. With ample parking, excellent accessibility, and a prime location, PHirst Fairgrounds is the perfect place to set up your office and grow your business.</p>
                                <p>Inquire now to secure your space and set up your office in a prime location with excellent accessibility and ample parking.</p>
                                <small onClick={ () => { setItem4State(prevState => !prevState)}}>Close</small>
                            </div>
                        </div>
                    </div>
                </div>

                <ContactFormTemplate />
            </div>
        </>
    )
}

export default Fairgrounds