import React, { useState, useEffect } from "react";

import '../css/about.css'

import LoadScreen from "../../others/components/loadScreen";
import { ContactFormTemplate } from "../../contact-us/components/contact-index";

import BackButton from "../../others/components/backButton";

const AboutUs = () => {
    const [ loadState, setLoadState ] = useState(false)
    useEffect(() => {
        setLoadState(true)
    }, [])
    const aboutCentraleImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/impressions-desktop.jpg'
    return (
        <>
            { loadState ? <LoadScreen /> : '' }
            <div className="content about">
                <BackButton />
                
                <main className="main">

                    <div className="text-content">
                        <h2>About PHirst Centrale</h2>
                        <p className="desc">Welcome to PHirst Centrale, a vibrant township where urban living meets suburban comfort. We're all about helping you experience life's perfect combination. Whether you're a hardworking grinder, an ambitious aspirant, or a dedicated achiever, our township is designed to meet your needs and exceed your expectations.</p>
                        <p>Our <em>DWELL</em>, <em>CREATE</em>, and <em>THRIVE</em> - reflect our commitment to providing you with a comprehensive and holistic living experience. DWELL represents our residential offerings, where you can find your dream home and build a happy life. CREATE embodies our commercial and institutional components, where you can pursue your passions and make your mark on the world. And THRIVE symbolizes our retail component, where you can enjoy the best of shopping, dining, and entertainment.</p>
                        <p style={{ display: "none" }}>At PHirst Centrale, you can</p>
                    </div>

                    {/* Not used */}
                    <div className="card-container" style={{ display: "none" }}> 
                        <Card
                            desc1="Live in"
                            desc2=""
                            desc3="Style"
                            desc4=""
                        />
                        <Card
                            desc1="Indulge in an"
                            desc2="array of"
                            desc3="Stunning"
                            desc4="attractions"
                        />
                        <Card
                            desc1="Broaden your"
                            desc2=""
                            desc3="Social"
                            desc4="connections"
                        />
                        <Card
                            desc1="Seize your dreams"
                            desc2="through a"
                            desc3="Simplified"
                            desc4="journey"
                        />
                    </div>

                </main>


                <div className="section-2">
                    <div className="text-content">
                        <h2>About PHirst Centrale Hermosa</h2>
                        <div className="img-cont" style={{backgroundImage: 'url(' + aboutCentraleImg + ')'}}></div>
                        <h4>At PHirst Centrale Hermosa, you will have easy access to everything that you need.</h4>
                        <h4>The first of many upcoming PHirst Centrale developments can be found at the municipality of Hermosa, Bataan.</h4>
                        <p className="desc">Hermosa is a town located in the province of Bataan, Philippines. It is a place rich in history and culture, with a booming economy and a bright future. The town is strategically located, making it an ideal destination for business and leisure travelers. It is accessible via the Subic-Clark-Tarlac Expressway (SCTEX) and is just a short drive away from Clark International Airport.</p>
                        <p>The town boasts a stunning natural landscape, with breathtaking views of mountains and the sea. It is also home to various industrial parks, making it a hub for investment and job opportunities. In PHirst Centrale Hermosa, we offer a perfect combination of residential, commercial, institutional, and retail components, making it an ideal place to live, work, and play.</p>
                        <p>Be the first to experience life's perfect combination in PHirst Centrale Hermosa. Join us and be at the center of everything. Life becomes easier and more exciting when you're where the action is. It's just perfect for grinders, aspirants, and achievers like you!</p>
                    </div>
                </div>

                <ContactFormTemplate/>

            </div>
        </>
    )
}

const Card = (props) => {

    return (
        <>
            <div className="about-card" style={{ backgroundImage: 'url("https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/calista-end-1.jpg")'}}>
                <img className="card-logo" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/logos/brand-logo-white.png" alt="" />
                <p className="card-desc">
                    {props.desc1} <br />
                    {props.desc2}
                    <span>{props.desc3}</span>
                    {props.desc4}
                </p>
                <div className="color-overlay"></div>
            </div>
        </>
    )
}

export default AboutUs