var APP_DATA = {
  "scenes": [
    {
      "id": "0-entrance-exterior",
      "name": "Entrance Exterior",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.0599758597503488,
        "pitch": -0.0038702675309227885,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.35666978966017204,
          "pitch": -0.05245807401007596,
          "rotation": 0,
          "target": "1-living-room-and-dining-area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "1-living-room-and-dining-area",
      "name": "Living Room and Dining Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 3.067109015254397,
        "pitch": 0.07496214351022523,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.749890471219226,
          "pitch": -0.019969580281811616,
          "rotation": 0,
          "target": "0-entrance-exterior"
        },
        {
          "yaw": 2.5822236555554126,
          "pitch": -0.05140433826979063,
          "rotation": 5.497787143782138,
          "target": "3-upstairs"
        },
        {
          "yaw": 0.24389713049827932,
          "pitch": 0.05157002456234494,
          "rotation": 4.71238898038469,
          "target": "2-bathroom-1st-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "2-bathroom-1st-floor",
      "name": "Bathroom 1st Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "pitch": 0,
        "yaw": 0,
        "fov": 1.5707963267948966
      },
      "linkHotspots": [
        {
          "yaw": -0.5426739424292997,
          "pitch": -0.053882460856547354,
          "rotation": 4.71238898038469,
          "target": "1-living-room-and-dining-area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "3-upstairs",
      "name": "Upstairs",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 1.5888842844973796,
        "pitch": -0.07998552897240074,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.385702833988084,
          "pitch": -0.3326762294498913,
          "rotation": 0,
          "target": "4-hallway-2nd-floor"
        },
        {
          "yaw": 1.2370567358725317,
          "pitch": 0.18820335659228427,
          "rotation": 3.141592653589793,
          "target": "1-living-room-and-dining-area"
        },
        {
          "yaw": 0.7531375968347476,
          "pitch": 0.29856192077345156,
          "rotation": 4.71238898038469,
          "target": "0-entrance-exterior"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "4-hallway-2nd-floor",
      "name": "Hallway 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -2.7852126778244806,
        "pitch": 0.02568282068640748,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.313527185591088,
          "pitch": 0.006048518505702205,
          "rotation": 1.5707963267948966,
          "target": "7-bedroom-1"
        },
        {
          "yaw": -2.714465778338619,
          "pitch": 0.019335989585599123,
          "rotation": 7.853981633974483,
          "target": "8-bedroom-2"
        },
        {
          "yaw": -3.021631905775598,
          "pitch": 0.014253308261411135,
          "rotation": 0,
          "target": "9-bedroom-3"
        },
        {
          "yaw": -2.951347681064995,
          "pitch": 0.5088471649174267,
          "rotation": 0,
          "target": "5-hallway-2nd-floor---2nd-view"
        },
        {
          "yaw": 2.9130986668716394,
          "pitch": 0.780179646020656,
          "rotation": 2.356194490192345,
          "target": "3-upstairs"
        },
        {
          "yaw": -0.15276994225271778,
          "pitch": 0.06749719359265072,
          "rotation": 0,
          "target": "6-bathroom-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "5-hallway-2nd-floor---2nd-view",
      "name": "Hallway 2nd Floor - 2nd View",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0.049503884238394136,
        "pitch": 0.07482491621141207,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.1156238414175057,
          "pitch": 0.05423954942206066,
          "rotation": 0,
          "target": "6-bathroom-2nd-floor"
        },
        {
          "yaw": 0.22992119539850542,
          "pitch": 0.4542178891252888,
          "rotation": 2.356194490192345,
          "target": "4-hallway-2nd-floor"
        },
        {
          "yaw": -0.2151814389984672,
          "pitch": 0.04639194493448606,
          "rotation": 4.71238898038469,
          "target": "7-bedroom-1"
        },
        {
          "yaw": -0.5869065168421397,
          "pitch": 0.03739829372051062,
          "rotation": 4.71238898038469,
          "target": "8-bedroom-2"
        },
        {
          "yaw": -3.110052952290715,
          "pitch": -0.016857916839420284,
          "rotation": 0,
          "target": "9-bedroom-3"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "6-bathroom-2nd-floor",
      "name": "Bathroom 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.04759988869075826,
        "pitch": 0.17932239559942253,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.19244290462452618,
          "pitch": 0.1375435896581756,
          "rotation": 1.5707963267948966,
          "target": "7-bedroom-1"
        },
        {
          "yaw": 0.12157861130957492,
          "pitch": 0.012231965556010138,
          "rotation": 1.5707963267948966,
          "target": "8-bedroom-2"
        },
        {
          "yaw": 0.016654771669614377,
          "pitch": 0.07095394254263354,
          "rotation": 0,
          "target": "9-bedroom-3"
        },
        {
          "yaw": -0.08839397541189342,
          "pitch": 0.49405564440363037,
          "rotation": 6.283185307179586,
          "target": "4-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "7-bedroom-1",
      "name": "Bedroom #1",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0.031386432839180856,
        "pitch": 0.074794594373639,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.19726238261872808,
          "pitch": 0.07687824888055594,
          "rotation": 0,
          "target": "4-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "8-bedroom-2",
      "name": "Bedroom #2",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0.03522391763116417,
        "pitch": 0.056763923786867565,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.23359502778205332,
          "pitch": 0.11733939885761835,
          "rotation": 0,
          "target": "4-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "9-bedroom-3",
      "name": "Bedroom #3",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.6418810247364597,
        "pitch": 0.129849109719828,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.6005282166578993,
          "pitch": 0.04861438513486682,
          "rotation": 0,
          "target": "6-bathroom-2nd-floor"
        },
        {
          "yaw": -0.6461261637363016,
          "pitch": 0.368463714177075,
          "rotation": 6.283185307179586,
          "target": "5-hallway-2nd-floor---2nd-view"
        }
      ],
      "infoHotspots": []
    }
  ],
  "name": "360-calista-mid-extended-bare-unit",
  "settings": {
    "mouseViewMode": "drag",
    "autorotateEnabled": false,
    "fullscreenButton": true,
    "viewControlButtons": true
  }
};

export default APP_DATA
