import React from "react";
import { Link, useNavigate } from "react-router-dom";

import '../css/back-button.css'


const BackButton = () => {
    const navigate = useNavigate()
    const backIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/back-button.png'

    return (
        <>
            <div className="back-btn-cont">
                {/* <Link to='/'>
                    <img src={ houseIcon } alt="" />    
                </Link> */}
                <span className="back-btn" onClick={ () => { navigate(-1) }}>
                    <img src={ backIcon } alt="" />    
                </span>
            </div>
        </>
    )
}

export default BackButton