import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import '../css/impressions.css'

import LoadScreen from "../../others/components/loadScreen";
import BackButton from "../../others/components/backButton";

const Impressions = ()  => {

    const centraleLogoWhite = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/logos/phirst-impressions-centrale-logo-white.png'
    const centraleAerialImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale%2Fimages%2Fothers%2Fcentrale-landing-page.jpg'
    const mainSectionToggler = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/main-section-toggler.png'

    const calistaMobile = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/calista-mobile.jpg'
    
    const impressionsTablet = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/impressions-tablet.jpg'
    const impressionsDesktop = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/impressions-desktop.jpg'
    const impressionsDesktop2 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-1.JPG'

    const bedIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/bed.png'
    const carIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/car.png'
    const expandIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/expand.png'
    const lotAreaIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/lot-area.png'
    const bathIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/bath.png'

    const calistaImgBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/calista-img-bg.png'
    const calistaImgBg2 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/calista-img-bg-v2.jpg'
    
    const calistaEndMainImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/calista-end-1.jpg'

    const calistaMidMainImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/calista-mid-2.JPG'

    const calistaPairMainImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/calista-pair-2.jpg'

    const famImg2 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/family-photos/family-img-2.jpg'

    // States
    const [ isTextContShown, setTextContState ] = useState(true)
    const [ loadState, setLoadState ] = useState(false) 

    useEffect(() => {
        let mainImgContent = document.getElementById('mainImgContent')
        let dividerSection = document.getElementById('dividerSection')

        dividerSection.style.backgroundImage = 'url(' + famImg2 + ')'

        // Changes which image is loaded in the background depending on viewport width
        if (window.innerWidth < 1600) {
            mainImgContent.style.backgroundImage = 'url('+impressionsTablet+')'
        } else if (window.innerWidth >= 1600) {
            mainImgContent.style.backgroundImage = 'url('+impressionsDesktop2+')'
        }

        setLoadState(true)
    }, [])

    // Use to toggle visibility of text container in the main section.
    useEffect(() => {
        let mainTextContent = document.querySelector('#mainTextContent > div')

        if(!isTextContShown) {
            mainTextContent.classList.add('hide')
        } else {
            mainTextContent.classList.remove('hide')
        }

    }, [isTextContShown])

    return (
        <>
            { loadState ? <LoadScreen /> : ''}
            <div className="content impressions">
                <BackButton/>

                {/* Main Section */}
                <main className="main-section">
                    <div className="text-content desktop" id="mainTextContent">
                        {/* <div className="left-pattern" style={{ backgroundImage: 'url("https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/left-side-pattern.png")'}}></div> */}
                        <div>
                            <div className="logo-cont">
                                <img className="logo" src={ centraleLogoWhite} alt="" />
                            </div>
                            <h1 className="header-title">
                                PHirst <span>Impressions</span>
                            </h1>
                            <p>Experience the magic of urban living at PHirst Impressions, where comfortable suburban living meets a community that fosters meaningful connections. Make PHirst Impressions your new home in the heart of PHirst Centrale, where convenience and comfort awaits.</p>
                            <NavLink to='/calista/bare-units/360' >
                                Check out Calista's Bare-unit 360&deg; View
                            </NavLink>
                            <small className="mobile-note">
                                Scroll down to see more
                            </small>
                        </div>
                        <img className="main-section-toggler" src={ mainSectionToggler } alt="" onClick={ () => { setTextContState((prevState) => !prevState)}}/>
                    </div>

                    <div className="text-content mobile">
                        <div className="logo-cont">
                            <img className="logo" src={ centraleLogoWhite} alt="" />
                        </div>
                        <h1 className="header-title">
                            PHirst <span>Impressions</span>
                        </h1>
                        <p>Experience the magic of urban living at PHirst Impressions, where comfortable suburban living meets a community that fosters meaningful connections. Make PHirst Impressions your new home in the heart of PHirst Centrale, where convenience and comfort awaits.</p>
                        <NavLink to='/calista/bare-units/360' >
                            Check out Calista's Bare-unit 360&deg; View
                        </NavLink>
                        <small className="mobile-note">
                            Scroll down to see more
                        </small>
                    </div>
                    <div className="img-content" id="mainImgContent">
                    </div>
                </main>

                {/* Divider Section */}
                <div className="divider-section" id="dividerSection">
                    <p>From corner lots to double plots, the PHirst Impressions’ Calista townhouse cluster has all the space you need to grow.</p>
                    <div className="color-overlay"></div>
                </div>

                {/* Calista End Section - mobile */}
                <div className="calista-section end mobile">
                    <h1>Calista End</h1>
                    <img src={ calistaMobile } alt="" />
                    <div className="desc-cont">
                        <p>If you hold an affinity towards cultivating a flourishing garden in a spacious land, the Calista End Unit holds the potential to cater to your requirements. An exceptional feature of this particular property is its location at the corner, thereby offering an expanded lot area in contrast to the interior units.</p>
                        <div className="specs">
                            <h5>Specifications</h5>
                            {/* <div className="specs-list">
                                <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Typical Lot Area <br />60.5 sqm</p>
                                <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Floor Area <br />40 sqm</p>
                                <p><img className="specs-icon" src={ bathIcon } alt="" /> 1 Bathroom</p>
                                <p><img className="specs-icon" src={ bedIcon } alt="" /> 2 Bedrooms</p>
                                <p><img className="specs-icon" src={ carIcon } alt="" /> 1 Carport</p>
                                <p><img className="specs-icon" src={ expandIcon } alt="" /> Expandable</p>
                            </div> */}
                            <ul>
                                <li>Typical Lot Area <br />60.5 sqm</li>
                                <li>Floor Area <br />40sqm</li>
                                <li>1 Bathroom</li>
                                <li>2 Bedrooms</li>
                                <li>1 Carport</li>
                                <li>Expandable</li>
                            </ul>
                        </div>
                    </div>
                    <NavLink to='/calista-end' >
                        View Floor Plan
                    </NavLink>
                    <NavLink to='/calista/end/360' >
                        Unit 360&deg; View
                    </NavLink>
                </div>
                
                {/* Calista End Section - desktop */}
                <div className="calista-section end desktop">
                    <div className="text-cont">
                        <h1>Calista End</h1>
                        <div className="desc-cont">
                            <p>If you hold an affinity towards cultivating a flourishing garden in a spacious land, the Calista End Unit holds the potential to cater to your requirements. An exceptional feature of this particular property is its location at the corner, thereby offering an expanded lot area in contrast to the interior units.</p>
                            <div className="specs">
                                <h5>Specifications</h5>
                                <div className="specs-list">
                                    <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Typical Lot Area <br />60.5 sqm</p>
                                    <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Floor Area <br />40 sqm</p>
                                    <p><img className="specs-icon" src={ bathIcon } alt="" /> 1 Bathroom</p>
                                    <p><img className="specs-icon" src={ bedIcon } alt="" /> 2 Bedrooms</p>
                                    <p><img className="specs-icon" src={ carIcon } alt="" /> 1 Carport</p>
                                    <p><img className="specs-icon" src={ expandIcon } alt="" /> Expandable</p>
                                </div>
                                {/* <ul>
                                    <li>Typical Lot Area <br />60.5 sqm</li>
                                    <li>Floor Area <br />40sqm</li>
                                    <li>1 Bathroom</li>
                                    <li>2 Bedrooms</li>
                                    <li>1 Carport</li>
                                    <li>Expandable</li>
                                </ul> */}
                            </div>
                        </div>
                        <NavLink to='/calista-end' >
                            View Floor Plan
                        </NavLink>
                        <NavLink to='/calista/end/360' >
                            Unit 360&deg; View
                        </NavLink>
                    </div>
                    <div className="img-cont" style={{ backgroundImage: 'url(' + calistaImgBg2 + ')'}}>
                        <img src={ calistaEndMainImg } alt="" />
                    </div>
                </div>
                
                {/* Calista Mid Section - mobile */}
                <div className="calista-section mid mobile">
                    <h1>Calista Mid</h1>
                    <img src={ calistaMobile } alt="" />
                    <div className="desc-cont">
                        <p>Situated within the townhouse cluster, the Calista Mid presents an alluring option for those who desire a property with a garden. While its lot area is marginally smaller than the Calista End, this unit's charm lies in its modest yet appealing garden space that allows residents to revel in the beauty of nature within their abode.</p>
                        <div className="specs">
                            <h5>Specifications</h5>
                            {/* <div className="specs-list">
                                <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Typical Lot Area <br />44 sqm</p>
                                <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Floor Area <br />40 sqm</p>
                                <p><img className="specs-icon" src={ bathIcon } alt="" /> 1 Bathroom</p>
                                <p><img className="specs-icon" src={ bedIcon } alt="" /> 2 Bedrooms</p>
                                <p><img className="specs-icon" src={ carIcon } alt="" /> 1 Carport</p>
                                <p><img className="specs-icon" src={ expandIcon } alt="" /> Expandable</p>
                            </div> */}
                            <ul>
                                <li>Typical Lot Area <br />44 sqm</li>
                                <li>Floor Area <br />40sqm</li>
                                <li>1 Bathroom</li>
                                <li>2 Bedrooms</li>
                                <li>1 Carport</li>
                                <li>Expandable</li>
                            </ul>
                        </div>
                    </div>
                    <NavLink to='/calista-mid' >
                        View Floor Plan
                    </NavLink>
                    <NavLink to='/calista/mid/360' >
                        Unit 360&deg; View
                    </NavLink>
                </div>
                
                {/* Calista End Section - desktop */}
                <div className="calista-section mid desktop">
                    <div className="text-cont">
                        <h1>Calista Mid</h1>
                        <div className="desc-cont">
                            <p>Situated within the townhouse cluster, the Calista Mid presents an alluring option for those who desire a property with a garden. While its lot area is marginally smaller than the Calista End, this unit's charm lies in its modest yet appealing garden space that allows residents to revel in the beauty of nature within their abode.</p>
                            <div className="specs">
                                <h5>Specifications</h5>
                                <div className="specs-list">
                                    <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Typical Lot Area <br />44 sqm</p>
                                    <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Floor Area <br />40 sqm</p>
                                    <p><img className="specs-icon" src={ bathIcon } alt="" /> 1 Bathroom</p>
                                    <p><img className="specs-icon" src={ bedIcon } alt="" /> 2 Bedrooms</p>
                                    <p><img className="specs-icon" src={ carIcon } alt="" /> 1 Carport</p>
                                    <p><img className="specs-icon" src={ expandIcon } alt="" /> Expandable</p>
                                </div>
                                {/* <ul>
                                    <li>Typical Lot Area <br />44 sqm</li>
                                    <li>Floor Area <br />40sqm</li>
                                    <li>1 Bathroom</li>
                                    <li>2 Bedrooms</li>
                                    <li>1 Carport</li>
                                    <li>Expandable</li>
                                </ul> */}
                            </div>
                        </div>
                        <NavLink to='/calista-mid' >
                            View Floor Plan
                        </NavLink>
                    <NavLink to='/calista/mid/360' >
                        Unit 360&deg; View
                    </NavLink>
                    </div>
                    <div className="img-cont" style={{ backgroundImage: 'url(' + calistaImgBg2 + ')'}}>
                        <img src={ calistaMidMainImg } alt="" />
                    </div>
                </div>
                
                {/* Calista Pair Section - mobile */}
                <div className="calista-section pair mobile">
                    <h1>Calista Pair</h1>
                    <img src={ calistaMobile } alt="" />
                    <div className="desc-cont">
                        <p>It is quite remarkable to discover that with our Calista Pair Unit, you have the opportunity to acquire not just one but TWO houses, fused together into a single, expansive dwelling. You can opt to merge two Calista Mid Units or combine one Calista Mid Unit with a Calista End Unit, giving you the flexibility to create the perfect living arrangement for your growing family.</p>
                        <div className="specs">
                            <h5>Specifications</h5>
                            {/* <div className="specs-list">
                                <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Typical Lot Area <br />104.5 sqm</p>
                                <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Floor Area <br />80 sqm</p>
                                <p><img className="specs-icon" src={ bathIcon } alt="" /> 2 Bathroom</p>
                                <p><img className="specs-icon" src={ bedIcon } alt="" /> 3 Bedrooms</p>
                                <p><img className="specs-icon" src={ carIcon } alt="" /> 1 Carport</p>
                                <p><img className="specs-icon" src={ expandIcon } alt="" /> Expandable</p>
                            </div> */}
                            <ul>
                                <li>Typical Lot Area <br />60.5 sqm</li>
                                <li>Floor Area <br />40sqm</li>
                                <li>1 Bathroom</li>
                                <li>2 Bedrooms</li>
                                <li>1 Carport</li>
                                <li>Expandable</li>
                            </ul>
                        </div>
                    </div>
                    <NavLink to='/calista-pair' >
                        View Floor Plan
                    </NavLink>
                    <NavLink to='/calista/pair/360' >
                        Unit 360&deg; View
                    </NavLink>
                </div>

                {/* Calista Pair Section - desktop */}
                <div className="calista-section pair desktop">
                    <div className="text-cont">
                        <h1>Calista Pair</h1>
                        <div className="desc-cont">
                            <p>It is quite remarkable to discover that with our Calista Pair Unit, you have the opportunity to acquire not just one but TWO houses, fused together into a single, expansive dwelling. You can opt to merge two Calista Mid Units or combine one Calista Mid Unit with a Calista End Unit, giving you the flexibility to create the perfect living arrangement for your growing family.</p>
                            <div className="specs">
                                <h5>Specifications</h5>
                                <div className="specs-list">
                                    <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Typical Lot Area <br />104.5 sqm</p>
                                    <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Floor Area <br />80 sqm</p>
                                    <p><img className="specs-icon" src={ bathIcon } alt="" /> 2 Bathroom</p>
                                    <p><img className="specs-icon" src={ bedIcon } alt="" /> 3 Bedrooms</p>
                                    <p><img className="specs-icon" src={ carIcon } alt="" /> 1 Carport</p>
                                    <p><img className="specs-icon" src={ expandIcon } alt="" /> Expandable</p>
                                </div>
                                {/* <ul>
                                    <li>Typical Lot Area <br />60.5 sqm</li>
                                    <li>Floor Area <br />40sqm</li>
                                    <li>1 Bathroom</li>
                                    <li>2 Bedrooms</li>
                                    <li>1 Carport</li>
                                    <li>Expandable</li>
                                </ul> */}
                            </div>
                        </div>
                        <NavLink to='/calista-pair' >
                            View Floor Plan
                        </NavLink>
                        <NavLink to='/calista/pair/360' >
                            Unit 360&deg; View
                        </NavLink>
                    </div>
                    <div className="img-cont" style={{ backgroundImage: 'url(' + calistaImgBg2 + ')'}}>
                        <img src={ calistaPairMainImg } alt="" />
                    </div>
                </div>

            </div>
        </>
    )
}

export default Impressions