import React from "react";

import UnitTemplateEditions from "./unit-template";

const UnitChristie = () => {

    // Unit Specification
    const specsObj = {
        specs: [
            {
                icon: "area",
                desc: "Typical Lot Area",
                quantity: "110 sqm"
            },
            {
                icon: "area",
                desc: "Floor Area",
                quantity: "90 sqm"
            },
            {
                icon: "bath",
                desc: "Bathrooms",
                quantity: "3"
            },
            {
                icon: "bed",
                desc: "Bedrooms",
                quantity: "3"
            },
            {
                icon: "car",
                desc: "Carport",
                quantity: "2"
            },
            // {
            //     icon: "expand",
            //     desc: "Expandable",
            //     quantity: ""
            // },
        ]
    }

    // Gallery Images
    const galleryObjs = {
        exterior: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/christie/Christie-90-exterior-2.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/christie/Christie-90-exterior-1.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/christie/Christie-90-exterior-6.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/christie/Christie-90-exterior-8.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/christie/Christie-90-exterior-5.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/christie/Christie-90-exterior-3.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/christie/Christie-90-exterior-7.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/christie/Christie-90-exterior-4.jpg",
        ],
        interior: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/christie/Christie-90-interior-1.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/christie/Christie-90-interior-6.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/christie/Christie-90-interior-4.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/christie/Christie-90-interior-10.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/christie/Christie-90-interior-7.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/christie/Christie-90-interior-9.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/christie/Christie-90-interior-8.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/christie/Christie-90-interior-2.jpg",
        ]
    }

    // Unit Floor Plan
    const floorPlanObjs = {
        floor1: 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/floor-plan/Christie_GF_v3.jpg',
        floor2: 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/floor-plan/Christie_2F.jpg'
    }

    return (
        <>
            <UnitTemplateEditions
                title="Christie 90"
                mainImg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/christie/Christie-90-exterior-6.jpg"
                logo="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/logos/phirst-editions-centrale-logo-white.png"
                mainDesc=""
                {...specsObj}
                section2Desc="Creating a nurturing environment where you and your loved ones can relish life's finer pleasures is a pursuit that warrants attention. Allow your imagination to wander and delve into the vast possibilities that await with Christie 90. This exceptional unit amplifies your capacity to manifest your domestic dreams into reality."
                section2Img="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/christie/Christie-90-exterior-2.jpg"
                {...galleryObjs}
                {...floorPlanObjs}
            />
        </>
    )
}

export default UnitChristie