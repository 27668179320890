var APP_DATA = {
  "scenes": [
    {
      "id": "0-entrance-exterior",
      "name": "Entrance Exterior",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.11427725681382128,
        "pitch": -0.0038702675309227885,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.8230934847778109,
          "pitch": 0.012934787358293676,
          "rotation": 0,
          "target": "1-living-room"
        },
        {
          "yaw": -1.094814243824894,
          "pitch": -0.0037936127933697605,
          "rotation": 0,
          "target": "6-dining-area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "1-living-room",
      "name": "Living Room",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.5740543240826828,
        "pitch": 0.04257339481665667,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.9564958240405232,
          "pitch": -0.02613122396998868,
          "rotation": 1.5707963267948966,
          "target": "2-hallway-to-bathroom---living-room"
        },
        {
          "yaw": -1.3525064192386758,
          "pitch": 0.050529031844183336,
          "rotation": 4.71238898038469,
          "target": "4-dining-and-kitchen-area"
        },
        {
          "yaw": 1.790195658213495,
          "pitch": 0.05097152056756116,
          "rotation": 0,
          "target": "0-entrance-exterior"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "2-hallway-to-bathroom---living-room",
      "name": "Hallway to Bathroom - Living Room",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -3.066738645645895,
        "pitch": 0.03999046677794382,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.7188195829411335,
          "pitch": -0.046269669694130044,
          "rotation": 4.71238898038469,
          "target": "3-bathroom---living-room"
        },
        {
          "yaw": -1.08530934847778109,
          "pitch": -0.012934787358293676,
          "rotation": 0,
          "target": "1-living-room"
        },
      ],
      "infoHotspots": []
    },
    {
      "id": "3-bathroom---living-room",
      "name": "Bathroom - Living Room",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.15803163045329072,
        "pitch": 0.052893656255944776,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.5701735058871655,
          "pitch": -0.04712568713264176,
          "rotation": 1.5707963267948966,
          "target": "2-hallway-to-bathroom---living-room"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "4-dining-and-kitchen-area",
      "name": "Dining and Kitchen Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 2.9272098465789353,
        "pitch": 0.11755937625177637,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.6805694541924403,
          "pitch": 0.05148692705276403,
          "rotation": 0,
          "target": "0-entrance-exterior"
        },
        {
          "yaw": 2.9128730289197176,
          "pitch": 0.15688881744770455,
          "rotation": 2.356194490192345,
          "target": "6-dining-area"
        },
        {
          "yaw": -1.1756779627500453,
          "pitch": 0.12218298898433844,
          "rotation": 0,
          "target": "5-bathroom---dining-area"
        },
        {
          "yaw": 2.210280955573081,
          "pitch": 0.22502970401030709,
          "rotation": 5.497787143782138,
          "target": "7-upstairs"
        },
        {
          "yaw": 0.4868015647858215,
          "pitch": 0.1933657278274996,
          "rotation": 0,
          "target": "1-living-room"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "5-bathroom---dining-area",
      "name": "Bathroom - Dining Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0.6551263294853413,
        "pitch": -0.036765262442989055,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -3.1308805704320726,
          "pitch": 0.1754069172212276,
          "rotation": 0,
          "target": "4-dining-and-kitchen-area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "6-dining-area",
      "name": "Dining Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -2.6707861192196383,
        "pitch": 0.12586238953372586,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -3.091592994293144,
          "pitch": 0.06321425609715803,
          "rotation": 0,
          "target": "5-bathroom---dining-area"
        },
        {
          "yaw": -2.3875764346948376,
          "pitch": 0.012457202432196723,
          "rotation": 1.5707963267948966,
          "target": "1-living-room"
        },
        {
          "yaw": -1.6390918162110797,
          "pitch": -0.08479153809739692,
          "rotation": 5.497787143782138,
          "target": "7-upstairs"
        },
        {
          "yaw": 0.5214370877956469,
          "pitch": 0.13675702933478462,
          "rotation": 0,
          "target": "0-entrance-exterior"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "7-upstairs",
      "name": "Upstairs",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -1.043389560101339,
        "pitch": -0.18964310901521664,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.46348291051411294,
          "pitch": -0.3378299787055461,
          "rotation": 5.497787143782138,
          "target": "8-hallway-2nd-floor"
        },
        {
          "yaw": -1.8911049558721622,
          "pitch": 0.3680938054343965,
          "rotation": 7.853981633974483,
          "target": "6-dining-area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "8-hallway-2nd-floor",
      "name": "Hallway 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 1.5221859545079255,
        "pitch": 0.1254608498946741,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.1532990380732588,
          "pitch": 0.043740928760056974,
          "rotation": 4.71238898038469,
          "target": "12-bedroom-1"
        },
        {
          "yaw": 1.9001334500426807,
          "pitch": 0.03341320965489736,
          "rotation": 1.5707963267948966,
          "target": "13-bedroom-2"
        },
        {
          "yaw": -2.0073333487137663,
          "pitch": 0.7015030954327663,
          "rotation": 1.5707963267948966,
          "target": "9-hallway-2nd-floor---2nd-view"
        },
        {
          "yaw": -1.5514097131934061,
          "pitch": 0.13865393989381047,
          "rotation": 0,
          "target": "10-master-bedroom"
        },
        {
          "yaw": -0.06787708052851293,
          "pitch": 0.9018044811803243,
          "rotation": 2.356194490192345,
          "target": "7-upstairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "9-hallway-2nd-floor---2nd-view",
      "name": "Hallway 2nd Floor - 2nd View",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.926293833922081,
        "pitch": 0.08256570732635282,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.5267232812113765,
          "pitch": 0.16970916369482936,
          "rotation": 0,
          "target": "10-master-bedroom"
        },
        {
          "yaw": 1.2075619973593277,
          "pitch": 0.029185380927598814,
          "rotation": 4.71238898038469,
          "target": "12-bedroom-1"
        },
        {
          "yaw": 1.6158710565641066,
          "pitch": 0.01941368899536755,
          "rotation": 1.5707963267948966,
          "target": "13-bedroom-2"
        },
        {
          "yaw": 0.92656404337799,
          "pitch": 0.1621097162596996,
          "rotation": 3.9269908169872414,
          "target": "7-upstairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "10-master-bedroom",
      "name": "Master Bedroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -2.4152871815625865,
        "pitch": 0.12446758196773366,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.172243390206395,
          "pitch": 0.033928156427014855,
          "rotation": 10.995574287564278,
          "target": "9-hallway-2nd-floor---2nd-view"
        },
        {
          "yaw": -0.7780677377668734,
          "pitch": 0.16035218091510828,
          "rotation": 0,
          "target": "8-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "11-master-bedroom---2nd-view",
      "name": "Master Bedroom - 2nd View",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -1.5203242762059066,
        "pitch": 0.08008451563975605,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.912950758724108,
          "pitch": 0.08129367966437329,
          "rotation": 17.27875959474387,
          "target": "10-master-bedroom"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "12-bedroom-1",
      "name": "Bedroom #1",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -3.1256177381997414,
        "pitch": 0.0453453567747264,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.37269910194654443,
          "pitch": 0.1787434446563978,
          "rotation": 0,
          "target": "8-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "13-bedroom-2",
      "name": "Bedroom #2",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 2.3267137766523067,
        "pitch": 0.14394338847412058,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.4742281672383779,
          "pitch": 0.07665775373142303,
          "rotation": 0,
          "target": "8-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    }
  ],
  "name": "360-calista-pair",
  "settings": {
    "mouseViewMode": "drag",
    "autorotateEnabled": false,
    "fullscreenButton": true,
    "viewControlButtons": true
  }
};

export default APP_DATA
