import React, { useState, useEffect} from "react";
import { NavLink } from "react-router-dom";

import '../css/editions.css'

import LoadScreen from "../../others/components/loadScreen";
import BackButton from "../../others/components/backButton";

const Editions = () => {

    const centraleAerialImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale%2Fimages%2Fothers%2Fcentrale-landing-page.jpg'
    const mainSectionToggler = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/main-section-toggler.png'

    const calistaMobile = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/calista-mobile.jpg'

    const cartlandImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/cartland-54.jpg'
    const charles70Img = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-70.jpg'
    const charles150Img = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/charles-150.jpg'
    const christieImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/christie-90.jpg'
    const corinImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/corin-120.jpg'
    
    const editionsTablet = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/editions-hermosa-tablet.jpg'
    const editionsDesktop = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/editions-hermosa-desktop.jpg'

    const bedIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/bed.png'
    const carIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/car.png'
    const expandIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/expand.png'
    const lotAreaIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/lot-area.png'
    const bathIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/bath.png'

    const editionsLogoWhite = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/logos/phirst-editions-centrale-logo-white.png'

    const friendsImg1 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/family-photos/friends-img-1.jpg'

    const unitsBg = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/editions-unit-bg.jpg"

    const [ isTextContShown, setTextContState ] = useState(true)
    const [ loadState, setLoadState ] = useState(false)

    useEffect(() => {
        let mainImgContent = document.getElementById('mainImgContent')
        let dividerSection = document.getElementById('dividerSection')

        dividerSection.style.backgroundImage = 'url(' + friendsImg1 + ')'

        if (window.innerWidth < 1600) {
            mainImgContent.style.backgroundImage = 'url('+editionsTablet+')'
        } else if (window.innerWidth >= 1600) {
            mainImgContent.style.backgroundImage = 'url('+editionsDesktop+')'
        }

        setLoadState(true)
    }, [])

    useEffect(() => {
        let mainTextContent = document.querySelector('#mainTextContent > div')

        if(!isTextContShown) {
            mainTextContent.classList.add('hide')
        } else {
            mainTextContent.classList.remove('hide')
        }

    }, [isTextContShown])

    return (
        <>
            { loadState ? <LoadScreen /> : ''}
            <div className="content editions">
                <BackButton/>

                {/* Main Section */}
                <main className="main-section">
                    <div className="text-content desktop" id="mainTextContent">
                        <div>
                            <div className="logo-cont">
                                <img className="logo" src={ editionsLogoWhite} alt="" />
                            </div>
                            <h1 className="header-title">
                                PHirst <span>Editions</span>
                            </h1>
                            <p>Indulge in the art of living at PHirst Editions, where the comforts of suburban serenity merge with the vibrancy of urban lifestyle at PHirst Centrale. Discover the perfect balance of privacy and socialization, and experience the elevation of your life's best moments.</p>
                            <small className="mobile-note">
                                Scroll down to see more
                            </small>
                        </div>
                        <img className="main-section-toggler" src={ mainSectionToggler } alt="" onClick={ () => { setTextContState((prevState) => !prevState)}}/>
                    </div>

                    <div className="text-content mobile">
                        <div>
                            <div className="logo-cont">
                                <img className="logo" src={ editionsLogoWhite} alt="" />
                            </div>
                            <h1 className="header-title">
                                PHirst <span>Editions</span>
                            </h1>
                            <p>Indulge in the art of living at PHirst Editions, where the comforts of suburban serenity merge with the vibrancy of urban lifestyle at PHirst Centrale. Discover the perfect balance of privacy and socialization, and experience the elevation of your life's best moments.</p>
                            <small className="mobile-note">
                                Scroll down to see more
                            </small>
                        </div>
                    </div>
                    <div className="img-content" id="mainImgContent">
                    </div>
                </main>
                
                {/* Divider Section */}
                <div className="divider-section" id="dividerSection">
                    <p>Welcome to PHirst Editions, where the art of living is elevated to new heights.</p>
                    <div className="color-overlay"></div>
                </div>

                {/* Cartland Section - Mobile */}
                <div className="edition-section mobile">
                    <h1>Meet Cartland 54</h1>
                    <div className="img-content-mobile" style={{ backgroundImage: 'url("'+ cartlandImg +'")'}}></div>
                    {/* <img src={ cartlandImg } alt="" /> */}
                    <div className="desc-cont">
                        <p>Experience the tranquil existence you've longed for alongside your loved ones. Begin by indulging in the allure of a modest yet tastefully designed abode such as the Cartland 54.</p>
                        <p>Embark on a journey of discovery and enrichment by seizing the opportunity to acquire this exceptional PHirst Editions dwelling. With Cartland 54 as your cornerstone, you can manifest a life of boundless possibility and fulfillment!</p>
                        <div className="specs">
                            <h5>Specifications</h5>
                            <div className="specs-list">
                                <p><img className="specs-icon" src={ lotAreaIcon } alt="" />Typical Lot Area <br />88 sqm</p>
                                <p><img className="specs-icon" src={ lotAreaIcon } alt="" />Floor Area <br />54 sqm</p>
                                <p><img className="specs-icon" src={ bathIcon } alt="" />2 Bathroom</p>
                                <p><img className="specs-icon" src={ bedIcon } alt="" />3 Bedrooms</p>
                                <p><img className="specs-icon" src={ carIcon } alt="" />1 Carport</p>
                            </div>
                            <ul>
                                <li>Typical Lot Area <br />88 sqm</li>
                                <li>Floor Area <br />54 sqm</li>
                                <li>2 Bathrooms</li>
                                <li>3 Bedrooms</li>
                                <li>1 Carport</li>
                            </ul>
                        </div>
                    </div>
                    <NavLink to='/cartland-54' >
                        View Floor Plan
                    </NavLink>
                </div>
                
                {/* Cartland Section - Desktop */}
                <div className="edition-section cartland-54 desktop">
                    <div className="text-cont">
                        <h1>Meet Cartland 54</h1>
                        <div className="desc-cont">
                            <p>Experience the tranquil existence you've longed for alongside your loved ones. Begin by indulging in the allure of a modest yet tastefully designed abode such as the Cartland 54.</p>
                            <p>Embark on a journey of discovery and enrichment by seizing the opportunity to acquire this exceptional PHirst Editions dwelling. With Cartland 54 as your cornerstone, you can manifest a life of boundless possibility and fulfillment!</p>
                            <div className="specs">
                                <h5>Specifications</h5>
                                <div className="specs-list">
                                    <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Typical Lot Area <br />88 sqm</p>
                                    <p><img className="specs-icon" src={ lotAreaIcon } alt="" />Floor Area <br />54 sqm</p>
                                    <p><img className="specs-icon" src={ bathIcon } alt="" />2 Bathroom</p>
                                    <p><img className="specs-icon" src={ bedIcon } alt="" />3 Bedrooms</p>
                                    <p><img className="specs-icon" src={ carIcon } alt="" />1 Carport</p>
                                </div>
                                <ul>
                                    <li>Typical Lot Area <br />88 sqm</li>
                                    <li>Floor Area <br />54 sqm</li>
                                    <li>2 Bathrooms</li>
                                    <li>3 Bedrooms</li>
                                    <li>1 Carport</li>
                                </ul>
                            </div>
                        </div>
                        <NavLink to='/cartland-54' >
                            View Floor Plan
                        </NavLink>
                    </div>
                    <div className="img-cont" style={{ backgroundImage: 'url("' + unitsBg + '")'}}>
                        <img src={ cartlandImg } alt="" />
                    </div>
                </div>
                
                {/* Charles 70 Section - Mobile */}
                <div className="edition-section mobile">
                    <h1>Meet Charles 70</h1>
                    <div className="img-content-mobile" style={{ backgroundImage: 'url("'+ charles70Img +'")'}}></div>
                    {/* <img src={ charles70Img } alt="" /> */}
                    <div className="desc-cont">
                        <p>Embracing contemporary elegance is not just a distant aspiration, but an absolute certainty when your determination fuels your dreams. Experience the ultimate modern living as you reside in a residence that seamlessly transports you to a world of absolute bliss.</p>
                        <p>Let the Charles 70 unit be your gateway to a life that is beautifully lived.</p>
                        <div className="specs">
                            <h5>Specifications</h5>
                            <div className="specs-list">
                                <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Typical Lot Area <br />99 sqm</p>
                                <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Floor Area <br />70 sqm</p>
                                <p><img className="specs-icon" src={ bathIcon } alt="" /> 2 Bathroom</p>
                                <p><img className="specs-icon" src={ bedIcon } alt="" /> 3 Bedrooms</p>
                                <p><img className="specs-icon" src={ carIcon } alt="" />Provision for <br /> 2 Carport</p>
                            </div>
                            <ul>
                                <li>Typical Lot Area <br />99 sqm</li>
                                <li>Floor Area <br />70 sqm</li>
                                <li>2 Bathroom</li>
                                <li>3 Bedrooms</li>
                                <li>Provision for <br /> 2 Carport</li>
                            </ul>
                        </div>
                    </div>
                    <NavLink to='/charles-70' >
                        View Floor Plan
                    </NavLink>
                </div>
                
                {/* Charles 70 Section - Desktop */}
                <div className="edition-section charles-70 desktop">
                    <div className="text-cont">
                        <h1>Meet Charles 70</h1>
                        <div className="desc-cont">
                            <p>Embracing contemporary elegance is not just a distant aspiration, but an absolute certainty when your determination fuels your dreams. Experience the ultimate modern living as you reside in a residence that seamlessly transports you to a world of absolute bliss.</p>
                            <p>Let the Charles 70 unit be your gateway to a life that is beautifully lived.</p>
                            <div className="specs">
                                <h5>Specifications</h5>
                                <div className="specs-list">
                                    <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Typical Lot Area <br />99 sqm</p>
                                    <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Floor Area <br />70 sqm</p>
                                    <p><img className="specs-icon" src={ bathIcon } alt="" /> 2 Bathroom</p>
                                    <p><img className="specs-icon" src={ bedIcon } alt="" /> 3 Bedrooms</p>
                                    <p><img className="specs-icon" src={ carIcon } alt="" /> Provision for <br /> 2 Carport</p>
                                </div>
                                <ul>
                                    <li>Typical Lot Area <br />99 sqm</li>
                                    <li>Floor Area <br />70 sqm</li>
                                    <li>2 Bathroom</li>
                                    <li>3 Bedrooms</li>
                                    <li>Provision for <br /> 2 Carport</li>
                                </ul>
                            </div>
                        </div>
                        <NavLink to='/charles-70' >
                            View Floor Plan
                        </NavLink>
                    </div>
                    <div className="img-cont" style={{ backgroundImage: 'url("' + unitsBg + '")'}}>
                        <img src={ charles70Img } alt="" />
                    </div>
                </div>
                
                {/* Charles 150 Section - Mobile */}
                <div className="edition-section mobile">
                    <h1>Meet Charles 150 Expanded Version</h1>                    
                    <div className="img-content-mobile" style={{ backgroundImage: 'url("'+ charles150Img +'")'}}></div>
                    {/* <img src={ charles150Img } alt="" /> */}
                    <div className="desc-cont">
                        <p>Unlock the full potential of your new abode and seize the possibilities that truly enrich life's experience.</p>
                        <p>The Charles 70, a dwelling of distinct charm, can now be transformed into a more expansive version to breathe life into your imaginative aspirations. Say hello to the grander and more refined Charles 150 Expanded Version, where your dreams come to fruition in a haven that you can truly call your own.</p>
                        <small>*Suggested Expansion Plan</small>
                        <div className="specs">
                            <h5>Specifications</h5>
                            <div className="specs-list">
                                <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Typical Lot Area <br />99 sqm</p>
                                <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Floor Area <br />150 sqm</p>
                                <p><img className="specs-icon" src={ bathIcon } alt="" /> 3 Bathrooms</p>
                                <p><img className="specs-icon" src={ bedIcon } alt="" />3 Bedrooms</p>
                                <p><img className="specs-icon" src={ carIcon } alt="" />Provision for <br /> 2 Carports</p>
                            </div>
                            <ul>
                                <li>Typical Lot Area <br />99 sqm</li>
                                <li>Floor Area <br />150 sqm</li>
                                <li>3 Bathrooms</li>
                                <li>3 Bedrooms</li>
                                <li>2 Carports</li>
                            </ul>
                        </div>
                    </div>
                    <NavLink to='/charles-150' >
                        View Floor Plan
                    </NavLink>
                </div>

                {/* Charles 150 Section - Desktop */}
                <div className="edition-section charles-150 desktop">
                    <div className="text-cont">
                        <h1>Meet Charles 150 Expanded Version</h1>
                        <div className="desc-cont">
                            <p>Unlock the full potential of your new abode and seize the possibilities that truly enrich life's experience.</p>
                            <p>The Charles 70, a dwelling of distinct charm, can now be transformed into a more expansive version to breathe life into your imaginative aspirations. Say hello to the grander and more refined Charles 150 Expanded Version, where your dreams come to fruition in a haven that you can truly call your own.</p>
                            <small>*Suggested Expansion Plan</small>
                            <div className="specs">
                                <h5>Specifications</h5>
                                <div className="specs-list">
                                    <p><img className="specs-icon" src={ lotAreaIcon } alt="" />Typical Lot Area <br />99 sqm</p>
                                    <p><img className="specs-icon" src={ lotAreaIcon } alt="" />Floor Area <br />150 sqm</p>
                                    <p><img className="specs-icon" src={ bathIcon } alt="" />3 Bathrooms</p>
                                    <p><img className="specs-icon" src={ bedIcon } alt="" />3 Bedrooms</p>
                                    <p><img className="specs-icon" src={ carIcon } alt="" />Provision for <br />2 Carports</p>
                                </div>
                                <ul>
                                    <li>Typical Lot Area <br />60.5 sqm</li>
                                    <li>Floor Area <br />40sqm</li>
                                    <li>3 Bathroom</li>
                                    <li>3 Bedrooms</li>
                                    <li>1 Carport</li>
                                    <li>Expandable</li>
                                </ul>
                            </div>
                        </div>
                        <NavLink to='/charles-150' >
                            View Floor Plan
                        </NavLink>
                    </div>
                    <div className="img-cont" style={{ backgroundImage: 'url("' + unitsBg + '")'}}>
                        <img src={ charles150Img } alt="" />
                    </div>
                </div>

                {/* Christie Section - Mobile */}
                <div className="edition-section mobile">
                    <h1>Meet Christie 90</h1>                    
                    <div className="img-content-mobile" style={{ backgroundImage: 'url("'+ christieImg +'")'}}></div>
                    {/* <img src={ christieImg } alt="" /> */}
                    <div className="desc-cont">
                        <p>Creating a nurturing environment where you and your loved ones can relish life's finer pleasures is a pursuit that warrants attention. Allow your imagination to wander and delve into the vast possibilities that await with Christie 90. This exceptional unit amplifies your capacity to manifest your domestic dreams into reality.</p>
                        <div className="specs">
                            <h5>Specifications</h5>
                            <div className="specs-list">
                                <p><img className="specs-icon" src={ lotAreaIcon } alt="" />Typical Lot Area <br />110 sqm</p>
                                <p><img className="specs-icon" src={ lotAreaIcon } alt="" />Floor Area <br />90 sqm</p>
                                <p><img className="specs-icon" src={ bathIcon } alt="" />3 Bathrooms</p>
                                <p><img className="specs-icon" src={ bedIcon } alt="" />3 Bedrooms</p>
                                <p><img className="specs-icon" src={ carIcon } alt="" />Provision for <br /> 2 Carport</p>
                            </div>
                            <ul>
                                <li>Typical Lot Area <br />110 sqm</li>
                                <li>Floor Area <br />90 sqm</li>
                                <li>3 Bathrooms</li>
                                <li>3 Bedrooms</li>
                                <li>Provision for <br /> 2 Carport</li>
                            </ul>
                        </div>
                    </div>
                    <NavLink to='/christie-90' >
                        View Floor Plan
                    </NavLink>
                </div>

                {/* Christie Section - Desktop */}
                <div className="edition-section christie desktop">
                    <div className="text-cont">
                        <h1>Meet Christie 90</h1>
                        <div className="desc-cont">
                            <p>Creating a nurturing environment where you and your loved ones can relish life's finer pleasures is a pursuit that warrants attention. Allow your imagination to wander and delve into the vast possibilities that await with Christie 90. This exceptional unit amplifies your capacity to manifest your domestic dreams into reality.</p>
                            <div className="specs">
                                <h5>Specifications</h5>
                                <div className="specs-list">
                                    <p><img className="specs-icon" src={ lotAreaIcon } alt="" />Typical Lot Area <br />110 sqm</p>
                                    <p><img className="specs-icon" src={ lotAreaIcon } alt="" />Floor Area <br />90 sqm</p>
                                    <p><img className="specs-icon" src={ bathIcon } alt="" />3 Bathrooms</p>
                                    <p><img className="specs-icon" src={ bedIcon } alt="" />3 Bedrooms</p>
                                    <p><img className="specs-icon" src={ carIcon } alt="" />Provision for <br /> 2 Carport</p>
                                </div>
                                <ul>
                                    <li>Typical Lot Area <br />110 sqm</li>
                                    <li>Floor Area <br />90 sqm</li>
                                    <li>3 Bathrooms</li>
                                    <li>3 Bedrooms</li>
                                    <li>Provision for <br /> 2 Carport</li>
                                </ul>
                            </div>
                        </div>
                        <NavLink to='/christie-90' >
                            View Floor Plan
                        </NavLink>
                    </div>
                    <div className="img-cont" style={{ backgroundImage: 'url("' + unitsBg + '")'}}>
                        <img src={ christieImg } alt="" />
                    </div>
                </div>

                {/* Corin Section - Mobile */}
                <div className="edition-section mobile">
                    <h1>Meet Corin 120</h1>                    
                    <div className="img-content-mobile" style={{ backgroundImage: 'url("'+ corinImg +'")'}}></div>
                    {/* <img src={ corinImg } alt="" /> */}
                    <div className="desc-cont">
                        <p>Embrace the limitless possibilities that come with living in a home that encourages you to dream big. With the Corin 120 unit, you can strive to achieve your loftiest goals and make significant leaps towards your aspirations.</p>
                        <p>Discover a home like no other and unlock a world of endless potential with the Corin 120 unit.</p>
                        <div className="specs">
                            <h5>Specifications</h5>
                            <div className="specs-list">
                                <p><img className="specs-icon" src={ lotAreaIcon } alt="" />Typical Lot Area <br />132 sqm</p>
                                <p><img className="specs-icon" src={ lotAreaIcon } alt="" />Floor Area <br />120 sqm</p>
                                <p><img className="specs-icon" src={ bathIcon } alt="" />3 Bathrooms</p>
                                <p><img className="specs-icon" src={ bedIcon } alt="" />2 Bedrooms</p>
                                <p><img className="specs-icon" src={ carIcon } alt="" />Provision for <br /> 2 Carport</p>
                            </div>
                            <ul>
                                <li>Typical Lot Area <br />132 sqm</li>
                                <li>Floor Area <br />120 sqm</li>
                                <li>3 Bathrooms</li>
                                <li>3 Bedrooms</li>
                                <li>Provision for <br /> 2 Carport</li>
                            </ul>
                        </div>
                    </div>
                    <NavLink to='/corin-120' >
                        View Floor Plan
                    </NavLink>
                </div>

                {/* Corin Section - Desktop */}
                <div className="edition-section corin desktop">
                    <div className="text-cont">
                        <h1>Meet Corin 120</h1>
                        <div className="desc-cont">
                            <p>Embrace the limitless possibilities that come with living in a home that encourages you to dream big. With the Corin 120 unit, you can strive to achieve your loftiest goals and make significant leaps towards your aspirations.</p>
                            <p>Discover a home like no other and unlock a world of endless potential with the Corin 120 unit.</p>
                            <div className="specs">
                                <h5>Specifications</h5>
                                <div className="specs-list">
                                    <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Typical Lot Area <br />132 sqm</p>
                                    <p><img className="specs-icon" src={ lotAreaIcon } alt="" /> Floor Area <br />120 sqm</p>
                                    <p><img className="specs-icon" src={ bathIcon } alt="" /> 3 Bathroom</p>
                                    <p><img className="specs-icon" src={ bedIcon } alt="" /> 3 Bedrooms</p>
                                    <p><img className="specs-icon" src={ carIcon } alt="" /> Provision for <br /> 2 Carport</p>
                                    {/* <p><img className="specs-icon" src={ expandIcon } alt="" /> Expandable</p> */}
                                </div>
                                <ul>
                                    <li>Typical Lot Area <br />60.5 sqm</li>
                                    <li>Floor Area <br />40sqm</li>
                                    <li>1 Bathroom</li>
                                    <li>2 Bedrooms</li>
                                    <li>Provision for <br /> 2 Carport</li>
                                    {/* <li>Expandable</li> */}
                                </ul>
                            </div>
                        </div>
                        <NavLink to='/corin-120' >
                            View Floor Plan
                        </NavLink>
                    </div>
                    <div className="img-cont" style={{ backgroundImage: 'url("' + unitsBg + '")'}}>
                        <img src={ corinImg } alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Editions