import React, { useState, useEffect } from "react";

import '../css/unit-editions-template.css'

import LoadScreen from "../../others/components/loadScreen";
import BackButton from "../../others/components/backButton";

const UnitTemplateEditions = (props) => {
    
    const iconArea = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/icon-area-4x-white.png'
    const iconBath = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/icon-bath-4x-white.png'
    const iconBedroom = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/icon-bedroom-4x-white.png'
    const iconCar = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/icon-car-4x-white.png'
    const iconExpand = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/icon-expand-4x-white.png'
    
    const texturedBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/textured-bg.jpg'
    const aerialBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/aerial-bg.png'

    const [ loadState, setLoadState ] = useState(false)
    const [ modalImg, setModalImg ] = useState("")
    
    useEffect(() => {
        setLoadState(true)
    }, [])

    const identifyIcon = (iconName) => {
        switch (iconName) {
            case 'area':
                return iconArea
                break;
            case 'bath':
                return iconBath
                break;
            case 'bed':
                return iconBedroom
                break;
            case 'car':
                return iconCar
                break;
            case 'expand':
                return iconExpand
                break;
            default:
                break;
        }
    }

    const showModal = (link) => {
        let galleryModal = document.getElementById('galleryModal')

        galleryModal.classList.add('show')

        setModalImg(link)
    }

    const hideModal = () => {
        let galleryModal = document.getElementById('galleryModal')

        galleryModal.classList.remove('show')
    }
    
    return(
        <>
            { loadState ? <LoadScreen /> : ''}
            <div className="content unit-editions">
                <BackButton/>

                <main className="main-section" style={{ backgroundImage: 'url(' + props.mainImg + ')'}}>
                    <div className="text-content">
                        <img className="logo" src={ props.logo } alt="" />
                        <h2>{props.title}</h2>
                        <p>{props.mainDesc}</p>
                    </div>
                    <div className="color-overlay"></div>
                </main>

                <div className="section-2">
                    <div className="">
                        <div className="text-content">
                            <h2>Meet {props.title}</h2>
                            <img className="mobile-img" src={props.section2Img} alt="" />
                            <p>{props.section2Desc}</p>
                            <div className="specs">
                                <h4>Specifications</h4>
                                {
                                    props.specs.map((spec, i) => {
                                        let specIcon = identifyIcon(spec.icon)
                                        return (
                                            <>
                                                <div className="spec">
                                                    <img className="icon" src={specIcon} alt="" />
                                                    <p className={spec.icon}>{spec.desc}<span>{spec.quantity}</span></p>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="img-content" style={{ backgroundImage: 'url(' + props.section2Img + ')'}}></div>
                    </div>
                </div>

                {/* Unit Gallery */}
                <div className="gallery" style={{ backgroundImage: 'url(' + texturedBg + ')'}}>
                    <h2>Unit Gallery</h2>
                    <div className="gallery-cont">
                        {
                            props.exterior.map((item, i) => {
                                return (
                                    <>
                                        <div className="gallery-item" onClick={ () => { showModal(item) }}>
                                            <div className="img-holder" style={{ backgroundImage: 'url(' + item + ')'}}></div>
                                        </div>
                                    </>
                                )
                            })
                        }
                        {
                            props.interior.map((item, i) => {
                                return (
                                    <>
                                        <div className="gallery-item" onClick={ () => { showModal(item) }}>
                                            <div className="img-holder" style={{ backgroundImage: 'url(' + item + ')'}}></div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>

                {/* Unit Floor Plan */}
                <div className="floor-plan" style={{ backgroundImage: 'url(' + aerialBg + ')'}}>
                    <div className="plan-cont">
                        <h4>Floor Plan</h4>
                        <div className="plan-item">
                                <img src={ props.floor1 } alt="" />
                                <p>Ground Floor</p>
                        </div>
                        <div className="plan-item">
                            <img src={ props.floor2 } alt="" />
                            <p>2nd Floor</p>
                        </div>
                            
                    </div>
                </div>

                {/* Modal */}
                <div className="gallery-modal" id="galleryModal" onClick={ hideModal }>
                    <div className="modal-body">
                        <i className="bi bi-x-lg" onClick={ props.toggleBook }></i>
                        <img src={ modalImg } alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default UnitTemplateEditions