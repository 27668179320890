import React, { useEffect, useRef, useState } from "react";
import '../css/unit-template.css'

import LoadScreen from "../../others/components/loadScreen";
import BackButton from "../../others/components/backButton";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const UnitTemplate = (props) => {

    const iconArea = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/icon-area-4x.png'
    const iconBath = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/icon-bath-4x.png'
    const iconBedroom = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/icon-bedroom-4x.png'
    const iconCar = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/icon-car-4x.png'
    const iconExpand = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/icon-expand-4x.png'
    
    const floorPlanBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/calista-img-bg-v2.jpg'
    const floorPlanBg2 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-1.JPG'

    const [ loadState, setLoadState ] = useState(false)

    const [isGalleryModalShown, setGalleryModalState ] = useState(false)
    const [galleryModalImg, setModalImg] = useState()
    
    useEffect(() => {
        setLoadState(true)
    }, [])

    // Used in unit specifications. Checks which icons to be used per specs item.
    const identifyIcon = (iconName) => {
        switch (iconName) {
            case 'area':
                return iconArea
                break;
            case 'bath':
                return iconBath
                break;
            case 'bed':
                return iconBedroom
                break;
            case 'car':
                return iconCar
                break;
            case 'expand':
                return iconExpand
                break;
            default:
                break;
        }
    }

    const showModal = (img) => {
        setModalImg(img)
        setGalleryModalState(true)
    }

    const hideModal = () => {
        setGalleryModalState(false)
    }
    return (
        <>
            { loadState ? <LoadScreen /> : ''}
            <div className={("content units " + props.className )}>
                <BackButton/>

                {/* Main Section */}
                <div className="main-section" style={{ backgroundImage: 'url(' + props.bg + ')'}}>
                    <div className="text-content">
                        <img className="logo" src={props.logo} alt="" />
                        <h1 className="main-title">{ props.mainTitle }</h1>
                        <p className="main-desc">{ props.mainDesc }</p>
                    </div>
                    <div className="color-overlay"></div>
                </div>

                {/* Unit Description */}
                <div className="intro-section">
                    <div className="intro-cont">
                        <div className="text-content">
                            <h2 className="intro-title">Meet {props.mainTitle}</h2>
                            {/* <div className="img-mobile" style={{ backgroundImage: 'url(' + props.introImg + ')'}}></div> */}
                            <p className="intro-desc">
                                { props.introDesc}
                            </p>
                        </div>
                        {/* <div className="img-content" style={{ backgroundImage: 'url(' + props.introImg + ')'}}></div> */}

                        {/* Carousel */}
                        <Carousel 
                        autoPlay={true}
                        infiniteLoop={true}
                        swipeable={true}
                        showThumbs={false}
                        >
                            { 
                                props.cImgs.map((img, index) => {
                                    return (
                                        <div key={ index }>
                                            <img src={ img }/>
                                        </div>
                                    )
                                }) 
                            }
                        </Carousel>
                    </div>
                </div>

                {/* Unit Specifications */}
                <div className="specs-divider">
                    <h4>Specifications</h4>
                        {
                            props.specs.map((spec, i) => {
                                let specIcon = identifyIcon(spec.icon)
                                return (
                                    <div className="spec" key={i}>
                                        <img className="icon" src={specIcon} alt="" />
                                        <p className={spec.icon}>{spec.desc}<span>{spec.quantity}</span></p>
                                    </div>
                                )
                            })
                        }
                </div>

                {/* Unit Gallery */}
                <div className="unit-gallery" style={{ backgroundImage: 'url(' + props.bg + ')'}}>
                    <h2>Unit Gallery</h2>
                    <div className="gallery-cont">
                        {
                            props.imgs.map((img, i) => {
                                return (
                                    <div className="gallery-item" style={{backgroundImage: 'url("' + img + '")'}} onClick={ () => { showModal(img)} } key={i}></div>
                                )
                            })
                        }
                    </div>
                    <div className="bg-overlay"></div>
                </div>

                {/* Unit Floor Plan */}
                <div className={"floor-plan " + props.floorPlanClassName} style={{ backgroundImage: 'url(' + floorPlanBg2 + ')'}}>
                    <div className="cont">
                        <h2>Floor Plan</h2>
                        <div className="floor-item">
                            <img src={ props.floorPlanGf } alt="" />
                            <p>Ground Floor</p>
                        </div>
                        <div className="floor-item">
                            <img src={ props.floorPlan2f } alt="" />
                            <p>Second Floor</p>
                        </div>
                    </div>
                    <div className="bg-overlay"></div>
                </div>
                
            </div>

            {/* Gallery Modal */}
            {
                isGalleryModalShown ? <GalleryModal img={galleryModalImg} hideModal={hideModal} /> : <></>
            }
        </>
    )
}

// Gallery Modal Component
const GalleryModal = (props) => {
    return (
        <div className="gallery-modal" onClick={props.hideModal}>
            <div className="gallery-content">
                {/* <i className="bi bi-x-square-fill" onClick={props.hideModal}></i> */}
                <img src={ props.img } alt="" />
                <button onClick={props.hideModal}>Close</button>
            </div>
        </div>
    )
}

export default UnitTemplate