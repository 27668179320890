import React from "react";

import UnitTemplateEditions from "./unit-template";

const UnitCorin = () => {

    // Unit Specification
    const specsObj = {
        specs: [
            {
                icon: "area",
                desc: "Typical Lot Area",
                quantity: "132 sqm"
            },
            {
                icon: "area",
                desc: "Floor Area",
                quantity: "120 sqm"
            },
            {
                icon: "bath",
                desc: "Bathrooms",
                quantity: "3"
            },
            {
                icon: "bed",
                desc: "Bedrooms",
                quantity: "3"
            },
            {
                icon: "car",
                desc: "Carport",
                quantity: "2"
            },
            // {
            //     icon: "expand",
            //     desc: "Expandable",
            //     quantity: ""
            // },
        ]
    }

    // Gallery Images
    const galleryObjs = {
        exterior: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/corin/Corin-120-exterior-4.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/corin/Corin-120-exterior-5.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/corin/Corin-120-exterior-6.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/corin/Corin-120-exterior-10.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/corin/Corin-120-exterior-7.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/corin/Corin-120-exterior-2.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/corin/Corin-120-exterior-9.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/corin/Corin-120-exterior-8.jpg",
        ],
        interior: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/corin/Corin-120-interior-12.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/corin/Corin-120-interior-15.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/corin/Corin-120-interior-9.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/corin/Corin-120-interior-5.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/corin/Corin-120-interior-4.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/corin/Corin-120-interior-14.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/corin/Corin-120-interior-6.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/corin/Corin-120-interior-1.jpg",
        ]
    }

    // Unit Floor Plan
    const floorPlanObjs = {
        floor1: 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/floor-plan/Corin_GF_v3.jpg',
        floor2: 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/floor-plan/Corin_2F.jpg'
    }

    return (
        <>
            <UnitTemplateEditions
                title="Corin 120"
                mainImg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/corin/Corin-120-exterior-5.jpg"
                logo="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/logos/phirst-editions-centrale-logo-white.png"
                mainDesc="Discover a home like no other and unlock a world of endless potential with the Corin 120 unit."
                {...specsObj}
                section2Desc="Embrace the limitless possibilities that come with living in a home that encourages you to dream big. With the Corin 120 unit, you can strive to achieve your loftiest goals and make significant leaps towards your aspirations."
                section2Img="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/editions/corin/Corin-120-exterior-4.jpg"
                {...galleryObjs}
                {...floorPlanObjs}
            />
        </>
    )
}

export default UnitCorin