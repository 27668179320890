var APP_DATA = {
  "scenes": [
    {
      "id": "0-entrance-exterior",
      "name": "Entrance Exterior",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.5026548245743747,
        "pitch": -0.025801783539485257,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.8275846400110414,
          "pitch": -0.0020728836204959578,
          "rotation": 0,
          "target": "1-living-room-and-dining-area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "1-living-room-and-dining-area",
      "name": "Living Room and Dining Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -1.5806800931304075,
        "pitch": 0.11353718877556318,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.6029318755564805,
          "pitch": 0.02052690064015117,
          "rotation": 4.71238898038469,
          "target": "3-bathroom"
        },
        {
          "yaw": -1.3240057333872297,
          "pitch": 0.06214053692301391,
          "rotation": 0,
          "target": "2-kitchen-area"
        },
        {
          "yaw": -0.07572211032698561,
          "pitch": -0.19765158424591078,
          "rotation": 5.497787143782138,
          "target": "4-upstairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "2-kitchen-area",
      "name": "Kitchen Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0.011423973285786815,
        "pitch": 0.17803220676842102,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.4651419714237175,
          "pitch": 0.3861827850534816,
          "rotation": 0,
          "target": "1-living-room-and-dining-area"
        },
        {
          "yaw": 0.5073857096696912,
          "pitch": 0.05868095107404159,
          "rotation": 0,
          "target": "0-entrance-exterior"
        },
        {
          "yaw": 0.0301821147975474,
          "pitch": -0.11793280658254623,
          "rotation": 5.497787143782138,
          "target": "4-upstairs"
        },
        {
          "yaw": 2.4450159309123016,
          "pitch": 0.014401353263364669,
          "rotation": 1.5707963267948966,
          "target": "3-bathroom"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "3-bathroom",
      "name": "Bathroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -3.114835153864922,
        "pitch": -0.002722122105248914,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.0169566563909918,
          "pitch": 0.07234058845915925,
          "rotation": 4.71238898038469,
          "target": "2-kitchen-area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "4-upstairs",
      "name": "Upstairs",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.40986771093834307,
        "pitch": -0.33415647938998205,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.11969914480657096,
          "pitch": -0.21529099547948505,
          "rotation": 4.71238898038469,
          "target": "5-hallway-2nd-floor"
        },
        {
          "yaw": -1.9659793898997613,
          "pitch": 0.791382658889134,
          "rotation": 1.5707963267948966,
          "target": "1-living-room-and-dining-area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "5-hallway-2nd-floor",
      "name": "Hallway 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.7969928441805081,
        "pitch": 0.5537978115406403,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.7114295446983938,
          "pitch": 0.9076228256360963,
          "rotation": 7.853981633974483,
          "target": "4-upstairs"
        },
        {
          "yaw": 0.09566552531027028,
          "pitch": 0.9329125288441436,
          "rotation": 0,
          "target": "6-hallway-2nd-floor---2nd-view"
        },
        {
          "yaw": 0.038984502768590445,
          "pitch": 0.36415029839695734,
          "rotation": 6.283185307179586,
          "target": "7-babys-room"
        },
        {
          "yaw": -0.32726987487080095,
          "pitch": 0.1823291365606572,
          "rotation": 4.71238898038469,
          "target": "8-master-bedroom"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "6-hallway-2nd-floor---2nd-view",
      "name": "Hallway 2nd Floor - 2nd View",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -2.455264182214764,
        "pitch": 0.09433629180160352,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.902126884815913,
          "pitch": 0.05752637906245539,
          "rotation": 0,
          "target": "5-hallway-2nd-floor"
        },
        {
          "yaw": 3.1120784096883796,
          "pitch": 0.15083683685426408,
          "rotation": 0,
          "target": "8-master-bedroom"
        },
        {
          "yaw": -1.8662823012742091,
          "pitch": 0.3266841953658517,
          "rotation": 0,
          "target": "7-babys-room"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "7-babys-room",
      "name": "Baby's Room",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 1.2962843642099067,
        "pitch": 0.21699635813248008,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.6925864068548329,
          "pitch": 0.0960911754043714,
          "rotation": 0,
          "target": "6-hallway-2nd-floor---2nd-view"
        },
        {
          "yaw": 1.8578186652852757,
          "pitch": 0.15619337987796555,
          "rotation": 1.5707963267948966,
          "target": "8-master-bedroom"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "8-master-bedroom",
      "name": "Master Bedroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.7377143133135426,
        "pitch": 0.23026387821972527,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.7986395605291525,
          "pitch": 0.08477750109723559,
          "rotation": 0,
          "target": "6-hallway-2nd-floor---2nd-view"
        },
        {
          "yaw": 1.795123031302638,
          "pitch": 0.17914793520511907,
          "rotation": 4.71238898038469,
          "target": "7-babys-room"
        }
      ],
      "infoHotspots": []
    }
  ],
  "name": "360-calista-end",
  "settings": {
    "mouseViewMode": "drag",
    "autorotateEnabled": false,
    "fullscreenButton": true,
    "viewControlButtons": true
  }
};

export default APP_DATA
