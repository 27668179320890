import React, { useEffect, useState } from "react";


const PlayImage = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/play-icon.png'

const PlayImageAlt = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/play-icon-alt.png'

const PauseImage = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/pause-icon.png'

const PauseImageAlt = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/pause-icon-alt.png'


const Controller = () => {

    const [ isPlaying, setIsPlaying ] = useState(true);
    
    useEffect(() => {
        if (localStorage.getItem('bgmusic') === null){
            localStorage.setItem('bgmusic', JSON.stringify({'status': true}))
        } else {
            const item = localStorage.getItem('bgmusic')
            const bgMusic = JSON.parse(item)

            setIsPlaying(bgMusic.status)
            if (!bgMusic.status) {
                var audio = document.getElementById('natureSound')
                if (audio !== null) {
                    pause()
                }
            }
        }
    }, [])

    const play = () => {
        var audio = document.getElementById('natureSound')
        audio.play()
        
    
        setIsPlaying(true)
        localStorage.setItem('bgmusic', JSON.stringify({'status': true}))
    }

    const pause = () => {
        var audio = document.getElementById('natureSound')
        audio.pause()
        setIsPlaying(false)
        localStorage.setItem('bgmusic', JSON.stringify({'status': false}))
    }

    return (
        <div className="music-controller">
            <a href="#" onClick={ play }>
                <img src={ PlayImage} style={ isPlaying ? { display: ''} : {display: 'none'} } alt="" />
                <img src={ PlayImageAlt} style={ isPlaying ? { display: 'none'} : {display: ''} } alt="" />
            </a>
            <a href="#" onClick={ pause }>
                <img src={ PauseImage} style={ isPlaying ? { display: 'none'} : {display: ''} } alt="" />
                <img src={ PauseImageAlt} style={ isPlaying ? { display: ''} : {display: 'none'} } alt="" />
            </a>
        </div>
    )
}

export default Controller