import React, { useEffect, useState } from "react";

import '../css/explore.css'

import LoadScreen from "../../others/components/loadScreen";
import { ContactFormTemplate } from "../../contact-us/components/contact-index";
import BackButton from "../../others/components/backButton";

const Explore = () => {
    const exploreBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/explore/explore-bg-black.jpg'
    const texturedBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/textured-bg.jpg'
    
    const titleReservation = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/explore/buying-guide/title-reservation.png'
    const titleBook = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/explore/buying-guide/title-booking.png'
    const titlePayment = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/explore/buying-guide/title-payment.png'
    const titleHouse = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/explore/buying-guide/title-house.png'
    const titleTurnover = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/explore/buying-guide/title-turnover.png'
    
    const step1Icon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/explore/buying-guide/step-1-icon.png'
    const step2Icon1 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/explore/buying-guide/step-2-icon-1.png'
    const step2Icon2 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/explore/buying-guide/step-2-icon-2.png'
    const step3Icon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/explore/buying-guide/step-3-icon-1.png'
    const step4Icon1 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/explore/buying-guide/step-4-icon-1.png'
    const step4Icon2 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/explore/buying-guide/step-4-icon-2.png'
    const step4Icon3 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/explore/buying-guide/step-4-icon-3.png'
    const step4Icon4 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/explore/buying-guide/step-4-icon-4.png'
    const step5Icon1 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/explore/buying-guide/step-5-icon-1.png'
    const step5Icon2 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/explore/buying-guide/step-5-icon-2.png'

    const guideBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/explore/buying-guide/buying-guide-bg.jpg'

    const [ loadState, setLoadState ] = useState(false)
    useEffect(() => {
        setLoadState(true)
    }, [])

    return (
        <>
            { loadState ? <LoadScreen /> : ''}
            <div className="content explore">
                <BackButton/>
                
                <main className="main" style={{ backgroundImage: 'url('+ exploreBg +')'}}>
                    <div className="text-content">
                        <h2>Explore <span>Centrale</span></h2>
                        <p>Find valuable resources to help you make informed decisions about buying your dream home and stay up-to-date with the latest news about PHirst Centrale by browsing through our News & Articles section.</p>
                        <a href="#buyingGuide">See Buyer's Guide</a>
                        <a href="#newsSection">Read News & Articles</a>
                    </div>
                </main>
                
                {/* Buying Guide */}
                <div className="buying-guide" id="buyingGuide" style={{ backgroundImage: 'url(' + guideBg + ')'}}>
                    <h2> <span>5</span> Convenient Buyer's Steps</h2>
                    <div className="steps-container">
                        <div className="step">
                            <img className="step-title" src={ titleReservation } alt="" />
                            <div className="sub-header">
                                <h5>BUYER</h5>
                                <svg>
                                    <rect className="step-rect"></rect>
                                </svg>
                            </div>
                            <div className="step-content">
                                <div className="">
                                    <h5>Day <span>1</span></h5>
                                    <ul>
                                        <li>Pays Reservation Fee</li>
                                        <li>Bring 1 valid ID</li>
                                        <li>Completes Reservation Documents</li>
                                    </ul>
                                </div>
                                <div className="">
                                    <h5>Day <span>20</span></h5>
                                    <ul>
                                        <li>Bring 2 valid IDs</li>
                                        <li>Completes BUYER's Booking Documents</li>
                                    </ul>
                                </div>
                                <div className="">
                                    <h5>Day <span>30</span></h5>
                                    <ul>
                                        <li>
                                            Starts downpayment <br />
                                            <small>*See Annex B for specific unit payment terms</small>
                                        </li>
                                    </ul>
                                </div>
                                <img className="step-icon" src={ step1Icon } alt="" />
                            </div>
                            <div className="sub-header">
                                <h5>PHirst</h5>
                                <svg>
                                    <rect className="step-rect"></rect>
                                </svg>
                            </div>
                            <div className="step-content">
                                <div className="">
                                    <h5>Month<span>2</span></h5>
                                    <ul>
                                        <li>Conducts pre-qualification</li>
                                    </ul>
                                </div>
                                <div className="">
                                    <h5>Month<span>3-5</span></h5>
                                    <ul>
                                        <li>
                                            Assists in home loan application to financial institution
                                            <br />
                                            (Submits BUYER's Booking Docs on behalf of BUYER for financial assestment)
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        <div className="step">
                            <img className="step-title" src={ titleBook } alt="" />
                            <div className="sub-header">
                                <h5>BUYER</h5>
                                <svg>
                                    <rect className="step-rect"></rect>
                                </svg>
                            </div>
                            <div className="step-content">
                                <div className="">
                                    <h5>Month<span>6</span></h5>
                                    <ul>
                                        <li>Signs Letter of Guarantee issued by financial Institution</li>
                                        <li>
                                            Reaches 5% downpayment
                                            <br />
                                            <small>*Once BUYER submitted complete documents</small>
                                        </li>
                                    </ul>
                                    <img className="step-icon" src={ step2Icon1 } alt="" />
                                </div>
                            </div>
                            <div className="sub-header">
                                <h5>PHirst</h5>
                                <svg>
                                    <rect className="step-rect"></rect>
                                </svg>
                            </div>
                            <div className="step-content">
                                <div className="">
                                    <h5>Month<span>6</span></h5>
                                    <ul>
                                        <li>Secures Letter of Approval for qualified buyers</li>
                                    </ul>
                                    <img className="step-icon" src={ step2Icon2 } alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="step">
                            <img className="step-title" src={ titlePayment } alt="" />
                            <div className="sub-header">
                                <h5>BUYER</h5>
                                <svg>
                                    <rect className="step-rect"></rect>
                                </svg>
                            </div>
                            <div className="step-content">
                                <div className="">
                                    <h5>Month<span>6</span></h5>
                                    <ul>
                                        <li>
                                            Reaches full downpayment
                                            <br />
                                            <small>*Submits latest income docs</small>
                                        </li>
                                    </ul>
                                    <img className="step-icon" src={ step3Icon } alt="" />
                                </div>
                            </div>
                        </div>
                        
                        <div className="step">
                            <img className="step-title" src={ titleHouse } alt="" />
                            <div className="sub-header">
                                <h5>BUYER</h5>
                                <svg>
                                    <rect className="step-rect"></rect>
                                </svg>
                            </div>
                            <div className="step-content">
                                <div className="">
                                    <h5>Month<span>13</span></h5>
                                    <ul>
                                        <li>
                                            Starts home loan amortization to financial institution
                                            <br />
                                            (30 days after Loan Takeout)
                                        </li>
                                    </ul>
                                    <img className="step-icon" src={ step4Icon1 } alt="" />
                                </div>
                            </div>
                            <div className="sub-header">
                                <h5>PHirst</h5>
                                <svg>
                                    <rect className="step-rect"></rect>
                                </svg>
                            </div>
                            <div className="step-content">
                                <div className="">
                                    <h5>Month<span>13</span></h5>
                                    <ul>
                                        <li>Takes out home loan</li>
                                    </ul>
                                    <img className="step-icon" src={ step4Icon2 } alt="" />
                                    <img className="step-icon" src={ step4Icon3 } alt="" />
                                </div>
                                <div className="">
                                    <h5>Month<span>13-18</span></h5>
                                    <ul>
                                        <li>House Construction**</li>
                                    </ul>
                                    <img className="step-icon second" src={ step4Icon4 } alt="" />
                                </div>
                            </div>
                        </div>
                        
                        <div className="step">
                            <img className="step-title" src={ titleTurnover } alt="" />
                            <div className="sub-header">
                                <h5>BUYER</h5>
                                <svg>
                                    <rect className="step-rect"></rect>
                                </svg>
                            </div>
                            <div className="step-content">
                                <div className="">
                                    <h5>Month<span>14-20</span></h5>
                                    <ul>
                                        <li>Inspects house</li>
                                        <li>Signs acceptance documents</li>
                                    </ul>
                                    <img className="step-icon" src={ step5Icon1 } alt="" />
                                </div>
                            </div>
                            <div className="sub-header">
                                <h5>PHirst</h5>
                                <svg>
                                    <rect className="step-rect"></rect>
                                </svg>
                            </div>
                            <div className="step-content">
                                <div className="">
                                    <h5>Month<span>18-20</span></h5>
                                    <ul>
                                        <li>Sends notice of turnover</li>
                                    </ul>
                                    <img className="step-icon second" src={ step5Icon2 } alt="" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                
                {/* <div className="divider-section">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad vel quae impedit fugit eligendi cupiditate ducimus rem repellendus!</p>
                </div> */}

                {/* News & Articles  */}
                <div className="news-section" id="newsSection" style={{ backgroundImage: 'url(' + texturedBg + ')'}}>
                    <h2 className="title">News & Articles</h2>
                    <div className="article">
                        <h4 className="article-title">CPGI PHirst expands market with launches worth P15.8 B</h4>
                        <small>Feb 26, 2023 - 06:16 am | By James A. Loyola</small>
                        <p>PHirst Park Homes, Inc. (PPHI), the affordable housing unit of Century Properties Group Inc. (CPGI), is expanding into the socialized, economic, and mid-income residential markets with initial launches worth P15.8 billion.</p>
                        <p>In a disclosure to the Philippine Stock Exchange, CPGI said PHirst is also introducing its version of a mixed-use format which will have multiple residential product offerings, as well as support commercial, retail, and institutional components.</p>
                        <p>These expansion efforts bring forth a broader range of housing packages and price points to provide first-time home buyers with a wider set of options to acquire their PHirst home. These new product lines will be launched under CPG’s new subsidiary, Century PHirst Corporation (PHirst). PHirst will underscore three flagship projects as part of this strategic initiative.</p>
                        <p>"The formation of the new subsidiary for the PHirst brand shows our deep commitment to cater to the diverse needs of first-time homebuyers in the Philippines," CPGI president and CEO Marco Antonio said.</p>
                        <p>He added that, “With this strategic initiative, we are poised to bring more quality homes to the market, that are accessible to a wider range of Filipinos.”</p>
                        <p>At the forefront is PHirst’s maiden mid-income development, PHirst Editions Batulao. Spanning 14.1 hectares in Batulao, Batangas, this development provides a total of 629 single attached and single detached units with a total sales value of P3.06 billion.</p>
                        <p>PHirst Editions Batulao offers 4 house models: Cartland 54, Charles 70, Christie 90, and Corin 120 with floor areas ranging from 54-120sqm and typical lot areas ranging from 88-132sqm. These units are available for purchase at prices ranging from P3.2 million to P6 million.</p>
                        <p>Meanwhile, PHirst broadens its reach by introducing PHirst Sights Bay, a socialized & economic housing development offering two house models.</p>
                        <p>These consist of the Alora Series (cluster homes with loft, with a floor area of 30sqm and typical lot area of 41-56sqm) and the Amani Series (townhouses with a floor area of 36sqm and typical lot area of 40-55sqm).</p>
                        <p>Soon to be launched on March 18, 2023, in Bay, Laguna, the available units are priced at P580,000 for socialized housing and P800,000 to P1.5 million for economic housing. The development occupies 15.3 hectares of land and offers 1,818 units, with a total sales value of P2.32 billion.</p>
                        <p>To round up its expansion plan, PHirst is introducing its first mixed-use township in Bataan, PHirst Centrale Hermosa to be launched on March 25, 2023.</p>
                        <p>Covering an area of 36 hectares, Centrale will launch various establishments, including PHirst Impressions and PHirst Editions for residential, PHirst Fairgrounds for commercial, and PHirst Boroughs for retail.</p>
                        <p>PHirst Centrale Hermosa has become the latest “it” town where individuals convene to reside, innovate, and flourish based on the township's three fundamental principles: Dwell, Create & Thrive.</p>
                        <p>Residents can lead a stylish lifestyle, revel in various breathtaking activities and livelihood opportunities, and cultivate their social networks, enhancing their quality of life with more ease and excitement. The township project will have 2,041 units valued at P6.74 billion.</p>
                        <p>Among PHirst Centrale Hermosa’s ‘Dwell’ components, PHirst Impressions Centrale will be opening during the township’s March 25 event.</p>
                        <p>The enclave is a townhouse development that occupies 14.5 hectares with 1,574 units, valued at around P3.12 billion. Unit prices will range from Php1,700,000 to 2,500,000 with floor areas ranging from 40sqm and typical lot areas from 44-60.5sqm.</p>
                        <p>Alongside PHirst Impressions, will be the launch of Centrale’s “Create” component, PHirst Fairgrounds, a 3.1 hectare commercial development with lot cuts ranging from 503-1,531sqm. The first phase of the development is valued at P602 million with 21 available units for sale.</p>
                        <p>Including these new product lines, the PHirst brand will now have a total of 16 master planned communities covering 293 hectares of land with 19,869 units launched inventory valued at P34.43 billion. As of end-December 2022, PHirst has built 5,908 units and turned over 3,835 homes to its buyers.</p>

                        <a href="https://mb.com.ph/2023/2/25/cpgi-phirst-expands-market-with-launches-worth-p15-8-b" target="_blank">Read the original article here.</a>
                    </div>
                    <div className="article">
                        <h4 className="article-title">Century Properties forms new subsidiary to cater socialized, mid-income residential markets</h4>
                        <small>February 23, 2023 1:46pm | By TED CORDERO, GMA Integrated News</small>
                        <p>Antonio-led Century Properties Group Inc. on Thursday announced the creation of a new subsidiary as the company ventures into the socialized, economic, and mid-income residential markets.</p>
                        <p>In a disclosure to the Philippine Stock Exchange, CPG said its new product lines will be launched under its new unit Century PHirst Corporation (PHirst).</p>
                        <p>"The formation of the new subsidiary for the PHirst brand shows our deep commitment to cater to the diverse needs of first-time homebuyers in the Philippines. With this strategic initiative, we are poised to bring more quality homes to the market that are accessible to a wider range of Filipinos,” said CPG president and CEO Marco Antonio.</p>
                        <p>CPG said PHirst is also introducing its version of a mixed-use format which will have multiple residential product offerings, as well as support commercial, retail, and institutional components.</p>
                        <p>The property developer said expansion efforts would bring a broader range of housing packages and price points to provide first-time home buyers with a wider set of options.</p>
                        <a href="https://www.gmanetwork.com/news/money/companies/861779/century-properties-forms-new-subsidiary-to-cater-socialized-mid-income-residential-markets/story/" target="_blank">Read the original article here.</a>
                    </div>
                    <div className="article">
                        <h4 className="article-title">Century Properties to establish new subsidiary</h4>
                        <small>Feb 24 | By Ed Paolo Salting</small>
                        <p>Century Properties Group Inc. is expanding offerings under its PHirst brand via the establishment of a new subsidiary, Century PHirst Corporation (PHirst).</p>
                        <p>The unit will venture into the socialized, economic, and mid-income residential markets, Century Properties said in a disclosure. PHirst will also introduce a mixed-use format with residential product offerings and also support commercial, retail, and institutional components.</p>
                        <p>"These expansion efforts bring forth a broader range of housing packages and price points to provide first time home buyers with a wider set of options to acquire their very own PHirst home," Century Properties said.</p>
                        <p>Three flagship projects have been placed under PHirst: PHirst Editions Batulao unveiled last December 10, 2022; PHirst Sights Bay, to be launched in March in Laguna; and the PHirst Centrale Hermosa township, also to be launched in March in Bataan.</p>
                        <p>PHirst Editions Batulao offers four house models with floor areas ranging from 54 to 120 square meters and lot areas ranging from 88-132 sq m. Prices range from P3.2 million to P6 million.</p>
                        <p>PHirst Sights Bay, meanwhile, will have two house models with a floor area of 30 sq. m. and lot areas of 41-56 sq. m. These will be priced at P580,000 for socialized housing and P800,000 to P1.5 million for economic housing.</p>
                        <p>The mixed-used township, lastly, will carry the PHirst Impressions and PHirst Editions brands for residential, PHirst Fairgrounds for commercial, and PHirst Boroughs for retail. The project was valued at P6.74 billion.</p>
                        <p>Century Properties' share price on Thursday inched down by half a centavo to P0.36 per share.</p>
                        <a href="https://www.msn.com/en-ph/news/other/century-properties-to-establish-new-subsidiary/ar-AA17RQke?;usg=AFQjCNHfsZBgYmXJLAKZD7wqjViaJdUvjQ&;sa=U&;ved=0ahUKEwiK6pij1uXRAhWEzLwKHeGcCakQFgj8BDBj&li=AAb280R" target="_blank">Read the original article here.</a>
                    </div>
                    <div className="article">
                        <h4 className="article-title">Century Properties enters affordable housing market</h4>
                        <small>February 24, 2023 12:06 am | By Adrian H. Halili</small>
                        <p>CENTURY Properties Group, Inc. is set to expand into the mid-income residential market through its new subsidiary Century PHirst Corp.</p>
                        <p>In a press release on Thursday, the property developer said that its subsidiary PHirst Park Homes, Inc., which is mainly focused on first-time homeowners, will venture into the socialized, economic, and mid-income residential markets.</p>
                        <p>“The formation of the new subsidiary for the PHirst brand shows our deep commitment to cater to the diverse needs of first-time homebuyers in the Philippines. With this strategic initiative, we are poised to bring more quality homes to the market, that are accessible to a wider range of Filipinos,” said Jose Marco R. Antonio, president and chief executive officer of Century Properties.</p>
                        <p>Century PHirst will oversee three flagship projects in Laguna, Batangas, and Bataan provinces.</p>
                        <p>“These expansion efforts bring forth a broader range of housing packages and price points to provide first-time home buyers with a wider set of options to acquire their very own PHirst home,” Century Properties said.</p>
                        <p>PHirst Editions Batulao — the 14.1-hectare development project in Batangas with an estimated P3.06 billion in total sales value — will provide 629 single attached and single detached units in the area.</p>
                        <p>The floor areas of the house model range from 54 to 120 square meters (sq.m.), and typical lot areas range from 88 to 132 sq.m. The homes are priced at P3.2 million to P6 million.</p>
                        <p>Additionally, the company has introduced a P2.3-billion housing project in Bay, Laguna spanning 15.3 hectares and offering 1,818 units. The project is set to be launched on March 18.</p>
                        <p>PHirst Sights Bay will offer socialized and economic housing with two house models ranging from 30 to 36 sq.m. with a typical lot area of 40 to 56 sq.m. The socialized housing will be priced at P580,000 while economic homes will range from P800,000 to P1.5 million.</p>
                        <p>Meanwhile, the company targets to launch its first mixed-use township in Bataan. Valued at P6.47 billion, the project will cover 36 hectares and will offer 2,041 units.</p>
                        <p>“PHirst Centrale Hermosa has become the latest ‘it’ town where individuals convene to reside, innovate, and flourish based on the township’s three fundamental principles: Dwell, Create & Thrive,” the company said.</p>
                        <p>PHirst Centrale will include residential, commercial, and retail establishments, it added.</p>
                        <p>“Residents can lead a stylish lifestyle, revel in various breathtaking activities and livelihood opportunities, and cultivate their social networks, enhancing their quality of life with more ease and excitement,” it said.</p>
                        <p>The P3.1-billion residential area of the project will cover 14.5 hectares with 1,574 units at prices ranging from P1.7 million to P2.5 million.</p>
                        <p>The commercial development project will cover 3.1 hectares valued at P602 million and will have 21 units available for sale ranging from 503 to 1,531 sq.m. per lot.</p>
                        <p>According to Century Properties, PHirst has so far built 5,908 units and turned over 3,835 houses to its clients.</p>
                        <p>On Thursday, Century Properties shares declined by 1.37% or P0.005, finishing at P0.36 each at the stock exchange.</p>
                        <a href="https://www.bworldonline.com/corporate/2023/02/24/506676/century-properties-enters-affordable-housing-market/" target="_blank">Read the original article here.</a>
                    </div>
                    <div className="article">
                        <h4 className="article-title">Century Properties ventures into new housing segments</h4>
                        <small>February 24, 2023 12:00 am | By Iris Gonzales</small>
                        <p>MANILA, Philippines — Century Properties Group Inc. (CPGI), the listed property company of the Antonio Group, is forming a new subsidiary Century PHirst Corp. (PHirst) as it expands its product offerings to tap other segments in the market.</p>
                        <p>PHirst will underscore three flagship projects, a mid-income development, a socialized and economic housing project and a mixed-use development in Bataan.</p>
                        <p>These expansions will provide a broader range of housing packages and price points to first-time home buyers with a wider set of options to acquire their very own PHirst home, said CPGI president and CEO Marco Antonio.</p>
                        <p>He said with the new subsidiary, CPGI is committed to address the different needs of the market.</p>
                        <p>“The formation of the new subsidiary for the PHirst brand shows our deep commitment to cater to the diverse needs of first-time homebuyers in the Philippines. With this strategic initiative, we are poised to bring more quality homes to the market that are accessible to a wider range of Filipinos,” Antonio said.</p>
                        <p>Through  PHirst, the company is venturing into the socialized, economic, and mid-income residential markets and is also introducing its version of a mixed-use format.</p>
                        <p>It will develop mixed-use projects which will have commercial, retail, and institutional components.</p>
                        <p>As of end-December 2022, PHirst had built 5,908 units and turned over 3,835 homes to its buyers.</p>
                        <p>“We will continue to innovate and deliver top-quality homes and communities to meet their evolving needs, and the industry as a whole,” Antonio said.</p>
                        <p>The maiden mid-income development, PHirst Editions Batulao in Batangas is a 14.1-hectare project with a total sales value of P3.06 billion.</p>
                        <p>PHirst Sights Bay, meanwhile, is the socialized & economic housing development.</p>
                        <p>For the mixed-use township, PHirst is introducing the 36-hectare PHirst Centrale Hermosa in Bataan to be launched next month.</p>
                        <p>Centrale will launch various establishments, including PHirst Impressions and PHirst Editions for residential; PHirst Fairgrounds for commercial; and PHirst Boroughs for retail.</p>
                        <p>PHirst Centrale Hermosa aims to become the latest “it” town where individuals will reside, innovate, and flourish based on the township’s three fundamental principles: Dwell, Create & Thrive.</p>
                        <p>The township project will have 2,041 units valued at P6.74 billion.</p>
                        <p>Including these new product lines, the PHirst brand will now have 16 master planned communities covering 293 hectares of land with 19,869 units launched inventory valued at P34.43 billion.</p>
                        
                        <a href="https://www.philstar.com/business/2023/02/24/2247138/century-properties-ventures-new-housing-segments" target="_blank">Read the original article here.</a>
                    </div>
                </div>

                <div className="contact-section explore">
                    <ContactFormTemplate/>
                </div>
            </div>
        </>
    )
}

export default Explore