import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import '../css/navbar.css'


const Navbar = () => {

    const centraleLogo = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/logos/centrale-no-hermosa-logo-v2.png'
    const menuIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/menu-icon.png'
    const menuIconAlt = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/menu-icon-alt.png'

    const [ navbarMobileShown, setNavbarState] = useState(false)
    const [ dropdownItemsShown, setDropdownState ] = useState(false) 

    useEffect(() => {
        let navLinks = document.getElementById('navLinks')
        if (navbarMobileShown) {
            navLinks.classList.add('show')
        } else {
            navLinks.classList.remove('show')
        }
    }, [navbarMobileShown])

    // Toggles the visibility of the dropdown links.
    useEffect(() => {
        let dropdownItems = document.getElementById('dropdownItems')
        if (dropdownItemsShown) {
            dropdownItems.classList.add('show')
        } else {
            dropdownItems.classList.remove('show')
        }
    }, [dropdownItemsShown])

    return (
        <>
            <nav className="nav" id="navbar">
                <NavLink to='/' onClick={() => { setDropdownState(false); setNavbarState((prevState) => !prevState)}}>
                    <img className="centrale-logo" src={ centraleLogo } alt="" />
                </NavLink>
                <img className="mobile-menu-btn" src={ ( navbarMobileShown ? menuIconAlt : menuIcon ) } onClick={ () => { setNavbarState((prevState) => !prevState)} } alt="" />
                {/* <button className="mobile-menu-btn" onClick={ () => { setNavbarState((prevState) => !prevState)} }></button> */}
                <div className="nav-links" id="navLinks">
                    
                    {/* <NavLink to={'/'} onClick={() => { setDropdownState(false)}}>
                        Home
                    </NavLink> */}

                    {/* Mobile view links */}
                    <div className="dropdown-cont" id="dropdownCont">
                        <button className="dwell-btn" onClick={() => { setDropdownState((prevState) => !prevState) }}>Dwell</button>
                        <div className="dropdown-items" id="dropdownItems">
                            <NavLink to={'/phirst-impressions'} onClick={() => { setDropdownState(false); setNavbarState((prevState) => !prevState)}}>
                                PHirst Impressions
                            </NavLink>
                            <NavLink to={'/phirst-editions'} onClick={() => { setDropdownState(false); setNavbarState((prevState) => !prevState)}}>
                                PHirst Editions
                            </NavLink>
                            <NavLink to={'/amenity-nodes'} onClick={() => { setDropdownState(false); setNavbarState((prevState) => !prevState)}}>
                                Amenity Nodes
                            </NavLink>
                        </div>
                    </div>
                    
                    <NavLink to={'/phirst-fairgrounds'} onClick={() => { setDropdownState(false); setNavbarState((prevState) => !prevState)}}>
                        Create
                    </NavLink>
                    <NavLink to={'/phirst-boroughs'} onClick={() => { setDropdownState(false); setNavbarState((prevState) => !prevState)}}>
                        Thrive
                    </NavLink>
                    <NavLink to={'/about-us'} onClick={() => { setDropdownState(false); setNavbarState((prevState) => !prevState)}}>
                        About Us
                    </NavLink>
                    <NavLink to={'/explore'} onClick={() => { setDropdownState(false); setNavbarState((prevState) => !prevState)}}>
                        Explore
                    </NavLink>
                    <NavLink to={'/contact-us'} onClick={() => { setDropdownState(false); setNavbarState((prevState) => !prevState)}}>
                        Contact Us
                    </NavLink>
                </div>
            </nav>
        </>
    )
}

export default Navbar