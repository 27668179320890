import React, { useEffect, useState } from "react";

import '../css/boroughs.css'

import { ContactFormTemplate } from "../../contact-us/components/contact-index";
import LoadScreen from "../../others/components/loadScreen";
import BackButton from "../../others/components/backButton";


const Boroughs = () => {

    const boroughsBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/boroughs-pattern-bg.jpg'

    const boroughsLogo = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/logos/boroughs-logo.png'

    const boroughsImgMobile = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/boroughs/boroughs-mobile.jpg'
    const boroughsImgDesktop = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/boroughs/boroughs-desktop.jpg'

    const toggler = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/main-section-toggler-green.png'

    const [ toggleText, setToggleText ] = useState(false)
    const [ loadState, setLoadState ] = useState(false)

    useEffect(() => {
        let textCont = document.querySelector('.text-content.desktop > div')
        if (toggleText) {
            textCont.classList.add('hide')
        } else {
            textCont.classList.remove('hide')
        }
        setLoadState(true)
    }, [toggleText])
    
    return (
        <>
            { loadState ? <LoadScreen /> : ''}
            <div className="content boroughs">
                <BackButton/>
                <main className="main" style={{ backgroundImage: 'url(' + boroughsBg + ')' }}>
                    <div className="text-content mobile">
                        <img className="logo" src={boroughsLogo} alt="" />
                        <h2 className="title">PHirst <span>Boroughs</span></h2>
                        <div className="boroughs-img" id="boroughsImg" style={{ backgroundImage: 'url(' + boroughsImgMobile+ ')'}}></div>
                        <p>Broaden your social connections and nurture your relationships in a diverse hub where different minds come together to share life accomplishments and prestige.</p>
                    </div>
                    
                    <div className="text-content desktop" style={{ backgroundImage: 'url('+ boroughsBg +')'}}>
                        <div>
                            <div className="logo-cont">
                                <img className="logo" src={boroughsLogo} alt="" />
                            </div>
                            <h2 className="title">PHirst <span>Boroughs</span></h2>
                            <p>Broaden your social connections and nurture your relationships in a diverse hub where different minds come together to share life accomplishments and prestige.  </p>
                        </div>
                        <img className="toggler" src={toggler} onClick={ () => { setToggleText((prevState) => !prevState)} } alt="" />
                    </div>
                    <div className="img-content desktop" style={{ backgroundImage: 'url('+ boroughsImgDesktop +')'}}></div>
                </main>
                <ContactFormTemplate />
            </div>
        </>
    )
}

export default Boroughs