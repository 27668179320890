import React, { useState, useEffect } from "react";

import '../css/contact.css'

import LoadScreen from "../../others/components/loadScreen";
import ReCAPTCHA from "react-google-recaptcha";
import BackButton from "../../others/components/backButton";

const ContactUs = () => {
    const aerialBg = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/others/aerial-centrale-bg.jpg"
    const [ loadState, setLoadState ] = useState(false)
    
    useEffect(() => {
        setLoadState(true)
    }, [])

    return (
        <>
            { loadState ? <LoadScreen/> : ''}
            <div className="content contact" style={{ backgroundImage: 'url('+aerialBg+')'}}>
                <BackButton/>
                <ContactFormTemplate />
            </div>
        </>
    )
}

export const ContactFormTemplate = () => {

    const cardBg = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/about/card-bg.jpg"
    const cardLogo = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/about/house-card-logo.png"


    const animTextContactLink = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/contact/Assurance-contact.gif"

    const phoneIcon = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/contact/phone-icon.png"
    const emailIcon = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/contact/email.png"

    const [ userName, setUserName ] = useState({ fname: "", lname: ""})
    const [ userEmail, setUserEmail ] = useState("")
    const [ userMobile, setUserMobile ] = useState("")
    const [ mailSubject, setMailSubject ] = useState("")
    const [ mailMessage, setMailMessage ] = useState("")

    const [ windowWidth, setWindowWidth ] = useState(window.innerWidth)

    const [ isMobileView, setisMobileView ] = useState()
    
    const [ isNameValid, setNameValidity ] = useState({ fname: false, lname: false })
    const [ isEmailValid, setEmailValidity ] = useState(false)
    const [ isMobileValid, setMobileValidity ] = useState(false)
    const [ isCaptchaVerified, setCaptchaStatus ] = useState(false)

    const recaptchaRef = React.createRef();

    const { REACT_APP_MAIL_API_URL, REACT_APP_MAIL_API_KEY, REACT_APP_MAIL_RECEIVER, REACT_APP_RECAPTCHA_SITE_KEY, REACT_APP_RECAPTCHA_SECRET_KEY} = process.env

    
    useEffect(() => {
        
        window.addEventListener('resize', size)

        // let animTextContact = document.getElementById('animTextContact')
        let accessText = document.getElementById('accessText')

        let animTimeout = setTimeout(() => {
            accessText.classList.add('show')
            // animTextContact.src = animTextContactLink
        }, 1000);
        

        return () => {
            clearTimeout(animTimeout)
            window.removeEventListener('resize', size)
        }
    }, [])

    useEffect(() => {
        checkFormValidity()
    }, [isCaptchaVerified])

    useEffect(() => {
        if (windowWidth <= 576) {
            setisMobileView(true)
        } else {
            setisMobileView(false)
        }
    }, [windowWidth])

    useEffect(() => {
        // console.log(isMobileView)
    }, [isMobileView])

    useEffect(() => {
        checkFormValidity()
    }, [isNameValid])
    
    useEffect(() => {
        checkFormValidity()
    }, [isMobileValid])

    
    // Checks if form is ready for submission
    const checkFormValidity = () => {
        var btnSubmit = document.getElementById('btnContactSubmit')

        if (isCaptchaVerified && isNameValid.fname && isNameValid.lname && isMobileValid) {
            btnSubmit.removeAttribute('disabled')
        } else {
            btnSubmit.setAttribute('disabled', '')
        }
    }

    const size = () => {
        setWindowWidth(window.innerWidth)
    }

    const onChange = (value) => {
        setCaptchaStatus(true)
    }

    const resetCaptcha = () => {
        setCaptchaStatus(false)
    }

    // Handles input field changes
    const inputHandler = (e) => {
        switch (e.target.name) {
            case "fname":
                nameHandler(e.target.value, "fname")
                break;
            case "lname":
                nameHandler(e.target.value, "lname")
                break;
            case "email":
                emailHandler(e.target.value)
                break;
            case "mobile":
                // mobileHandler(e)
                break;
            case "subject":
                setMailSubject(e.target.value)
                break;
            case "message":
                setMailMessage(e.target.value)
                break;
            default:
                break;
        }
    }

    const nameHandler = (val, desc) => {
        const checker = new RegExp('[^a-z A-Z]')

        var errorMsgFname = document.getElementById('errorMessageFname')
        var errorMsgLname = document.getElementById('errorMessageLname')

        if (desc === "fname") {
            if (checker.test(val)) {
                errorMsgFname.innerHTML = "This field only accepts letters."
                errorMsgFname.style.maxHeight = "1.75em"
                errorMsgFname.style.opacity = "1"

                setNameValidity((prevState) => ({
                    ...prevState,
                    fname: false
                }))
            } else {
                errorMsgFname.innerHTML = ""
                errorMsgFname.style.maxHeight = "0"
                errorMsgFname.style.opacity = "0"
                
                setNameValidity((prevState) => ({
                    ...prevState,
                    fname: true
                }))
            }

            setUserName((prevState) => ({
                ...prevState,
                fname: val
            }))
            
            
        } else if (desc === "lname") {

            if (checker.test(val)) {
                errorMsgLname.innerHTML = "This field only accepts letters."
                errorMsgLname.style.maxHeight = "1.75em"
                errorMsgLname.style.opacity = "1"

                setNameValidity((prevState) => ({
                    ...prevState,
                    lname: false
                }))
            } else {
                errorMsgLname.innerHTML = ""
                errorMsgLname.style.maxHeight = "0"
                errorMsgLname.style.opacity = "0"

                setNameValidity((prevState) => ({
                    ...prevState,
                    lname: true
                }))
            }

            setUserName((prevState) => ({
                ...prevState,
                lname: val
            }))

            
        }
    }

    const mobileHandler = (e) => {
        const isDigit = new RegExp('.$')
        const validMobile = new RegExp('[0-9]{4} [0-9]{3} [0-9]{4}')
        const checker = new RegExp('[a-zA-Z]')
        var errorMsg = document.getElementById('errorMessageMobile')

        if (checker.test(e.target.value)) {
            errorMsg.innerHTML = "This field only accepts digits."
            errorMsg.style.maxHeight = "1.75em"
            errorMsg.style.opacity = "1"

            setMobileValidity(false)
            
        } else {
            errorMsg.innerHTML = ""
            errorMsg.style.maxHeight = "0"
            errorMsg.style.opacity = "0"

            setMobileValidity(true)
        }

        var mobileInput = document.getElementById('mobileInput')
        
        // if (mobileInput.value.length === 4 || mobileInput.value.length === 8) {
        //     mobileInput.value += " "
        // }
        
        setUserMobile(e.target.value)
    }

    const emailHandler = (val) => {
        // const isEmail = new RegExp('/^[@]/g')
        // const noSpecialCharacter = new RegExp("/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/gm")
        // var errorMsg = document.getElementById('errorMessageEmail')

        // if (noSpecialCharacter.test(val)) {
        //     errorMsg.innerHTML = "Please follow this email format (johndoe@domain.com)"
        //     errorMsg.style.maxHeight = "1.75em"
        //     errorMsg.style.opacity = "1"
        //     console.log("Test " + noSpecialCharacter.test(val))
        // } else {
        //     errorMsg.innerHTML = ""
        //     errorMsg.style.maxHeight = "0"
        //     errorMsg.style.opacity = "0"
        //     console.log("Test " + noSpecialCharacter.test(val))
        // }

        setUserEmail(val)
    }

    // Html layout for email
    const mailContent = () => {

        return (
                '<!DOCTYPE html lang="en"> \
                    <head> \
                        <meta http-equiv="X-UA-Compatible" content="IE=edge"> \
                        <meta name="viewport" content="width=device-width, initial-scale=1"> \
                        <meta name="color-scheme" content="light dark"> \
                        <meta name="supported-color-schemes" content="light dark"> \
                        <title>Our new office awaits! | Bayer Office Inauguration</title> \
                        <style type="text/css"> \
                            * { \
                                color: black; \
                            } \
                            body { \
                                padding: 0; \
                                margin: 0; \
                                background-color: #fff; \
                            } \
                            div[class="content"] { \
                                display: flex; \
                                flex-direction: column; \
                                width: 100%; \
                                max-width: 720px; \
                                margin: 0 auto; \
                            } \
                            div[class="content"] div[class="text-content"]{ \
                                box-sizing: border-box; \
                                padding: 24px 40px;    \
                            } \
                            img[class="brand-img"] { \
                                width: 100%; \
                            } \
                            \
                            div[class="content"] p { \
                                margin-bottom: 48px; \
                            } \
                            div[class="content"] h6 { \
                                margin: unset; \
                                font-size: 16px; \
                                font-weight: normal; \
                            } \
                            \
                        </style>  \
                    </head> \
                    <body> \
                        <div class="content" style="width: 100%; max-width: 720px; margin: 0 auto;"> \
                            <a href="#" style="width: 100%;"><img class="brand-img" style="width: 100%;" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/others/email-image.jpg" alt=""></a>\
                            <div class="text-content" style="box-sizing: border-box; padding: 24px 40px;"> \
                                <h4 style="font-size: 18px;font-weight: normal;">Subject: <span style="font-weight: bold;">' + mailSubject + '</span></h4> \
                                <p style="margin-bottom: 48px;font-size: 16px;">' + mailMessage + '</p> \
                                \
                                <h6 style="margin: unset; font-size: 16px; font-weight: normal;">From: ' + userName.fname + " " + userName.lname + '</h6> \
                                <h6 style="margin: unset; font-size: 16px; font-weight: normal;">Mobile: ' + userMobile + '</h6> \
                            </div> \
                        </div> \
                    </body> \
                </html>'
            )
    }

    // Handles form submission
    const formSubmission = (event) => {
        nameHandler()
        event.preventDefault() // Prevents page refresh upon form submission

        const fullName = userName.fname + " " + userName.lname


        const mailData = {
            sender: {email: userEmail, name: fullName},
            to: [{email: REACT_APP_MAIL_RECEIVER}],
            htmlContent: mailContent(),
            // textContent: "Test Content",
            subject: mailSubject,
            params: {
                fname: userName.fname,
                lname: userName.lname,
                mobile: userMobile
            }
          }

        const data = JSON.stringify(mailData)


        const options = {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'api-key': REACT_APP_MAIL_API_KEY
            },
            body: data,
          };
          
        // Send email using API endpoint
        fetch(REACT_APP_MAIL_API_URL+'smtp/email', options)
            .then((response) => {
                if(response.ok) {
                    window.alert("Your email has been sent!")
                } else {
                    window.alert("Something went wrong. Please try again.")
                }
            })
            .catch(err => console.error(err));
        
        setTimeout(() => {
            recaptchaRef.current.reset()
            setCaptchaStatus(false)
        }, 1000)
    }

    return (
        <>
            <div className="section1">
                <h2>Contact Us</h2>
                <div className="contact-banner">
                    <div className="banner-card" style={{ backgroundImage: 'url('+cardBg+')'}}>
                        <img className="card-logo" src={cardLogo} alt="" />
                        <h4 className="card-text"><span id="accessText">Experience</span><br /> life’s perfect combination.</h4>
                        {/* <img className="assurance-text" id="animTextContact" alt="" /> */}
                        <div className="contact-info">
                            <div>
                                <img src={ phoneIcon } alt="" />
                                <p>(02) 8 424 2880</p>
                            </div>
                            <div>
                                <img src={ phoneIcon } alt="" />
                                <p>(63) 917-874-4778</p>
                            </div>
                            <div>
                                <img src={ emailIcon } alt="" />
                                <p>phirstcentrale@gmail.com</p>
                            </div>
                        </div>
                        <div className="card-overlay"></div>
                    </div>
                    <div className="form-cont">
                        <form action="" onSubmit={ formSubmission }>
                            <div className="input-group">
                                <label htmlFor="fname">First Name</label>
                                <input type="text" id="fname" name="fname" onChange={ (e) => { inputHandler(e) } } required placeholder="Your first name" />
                                <span className="error-message" id="errorMessageFname"></span>
                            </div>
                            <div className="input-group">
                                <label htmlFor="lname">Last Name</label>
                                <input type="text" id="lname" name="lname" onChange={ (e) => { inputHandler(e) } } required placeholder="Your last name"/>
                                <span className="error-message" id="errorMessageLname"></span>
                            </div>
                            <div className="input-group">
                                <label htmlFor="email">Email Address</label>
                                <input type="email" id="email" name="email" onChange={ (e) => { inputHandler(e) } } required placeholder="Your e-mail"/>
                                <span className="error-message" id="errorMessageEmail"></span>
                            </div>
                            <div className="input-group">
                                <label htmlFor="mobile">Mobile Number</label>
                                <input type="text" id="mobileInput" name="mobile" onKeyUp={ (e) => { mobileHandler(e) } } required placeholder="Your mobile number"/>
                                <span className="error-message" id="errorMessageMobile"></span>
                            </div>
                            
                            <div className="input-group subject">
                                <label>Subject</label>
                                <div className="radio-btn">
                                    <div>
                                        <input type="radio" id="subject1" value={"Sales"} name="subject" onClick={ (e) => { inputHandler(e) } } required />
                                        <label htmlFor="sales">Sales</label>
                                    </div>
                                    <div>
                                        <input type="radio" id="subject2" value={"Inquiry"} name="subject" onClick={ (e) => { inputHandler(e) } } />
                                        <label htmlFor="inquiry">Inquiry</label>
                                    </div>
                                    <div>
                                        <input type="radio" id="subject3" value={"Feedback"} name="subject" onClick={ (e) => { inputHandler(e) } } />
                                        <label htmlFor="feedback">Feedback</label>
                                    </div>
                                    <div>
                                        <input type="radio" id="subject4" value={"Applicant"} name="subject" onClick={ (e) => { inputHandler(e) } } />
                                        <label htmlFor="applicant">Applicant</label>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group message">
                                <label htmlFor="message">Message</label>
                                <textarea id="message" name="message" cols="30" rows="4" onChange={ (e) => { inputHandler(e) } } required></textarea>
                            </div>

                            <div className="recaptcha">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={ REACT_APP_RECAPTCHA_SITE_KEY }
                                    onChange={onChange}
                                    onExpired={resetCaptcha}
                                    size="normal"
                                />
                            </div>

                            <div className="submit">
                                <button className="btn-submit" type="submit" id="btnContactSubmit" disabled>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUs