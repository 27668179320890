import React from "react";

import UnitTemplate from "./unit-template";

const CalistaPair = () => {
    
    // Unit Specification
    const specsObj = {
        specs: [
            {
                icon: "area",
                desc: "Typical Lot Area",
                quantity: "104.5 sqm"
            },
            {
                icon: "area",
                desc: "Floor Area",
                quantity: "80 sqm"
            },
            {
                icon: "bath",
                desc: "Bathrooms",
                quantity: "2"
            },
            {
                icon: "bed",
                desc: "Bedrooms",
                quantity: "3"
            },
            {
                icon: "car",
                desc: "Carport",
                quantity: "1"
            },
            {
                icon: "expand",
                desc: "Expandable",
                quantity: ""
            },
        ]
    }

    // Carousel Images
    const carouselImgs = {
        cImgs: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/calista-pair-1.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/carousel/calista-pair-1-carousel.png",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/calista-pair-3.JPG",
        ]
    }

    // Gallery Images
    const imgGallery = {
        imgs: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-pair/calista-pair-exterior-1.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-pair/calista-pair-interior-1.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-pair/calista-pair-interior-2.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-pair/calista-pair-interior-3.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-pair/calista-pair-interior-4.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-pair/calista-pair-interior-5.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-pair/calista-pair-interior-6.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-pair/calista-pair-interior-7.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-pair/calista-pair-interior-8.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-pair/calista-pair-interior-9.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-pair/calista-pair-interior-10.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-pair/calista-pair-interior-11.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-pair/calista-pair-interior-12.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-pair/calista-pair-interior-13.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-pair/calista-pair-interior-14.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-pair/calista-pair-interior-15.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-pair/calista-pair-interior-16.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-pair/calista-pair-interior-17.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/units-images/calista-pair/calista-pair-interior-18.JPG",
        ]
    }
    
    return (
        <>
            <UnitTemplate 
                logo="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/logos/phirst-impressions-centrale-logo-white.png"
                bg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/calista-pair-3.JPG"
                className="calista-pair"
                mainTitle="Calista Pair"
                mainDesc=""
                { ...specsObj }
                { ...imgGallery }
                { ...carouselImgs }

                introImg="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Exteriors/calista-pair-1.jpg"
                introDesc="It is quite remarkable to discover that with our Calista Pair Unit, you have the opportunity to acquire not just one but TWO houses, fused together into a single, expansive dwelling. You can opt to merge two Calista Mid Units or combine one Calista Mid Unit with a Calista End Unit, giving you the flexibility to create the perfect living arrangement for your growing family."
                floorPlanGf='https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Floor-plans/calista-pair-GF.jpg'
                floorPlan2f='https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-centrale/images/impressions/Floor-plans/calista-pair-2F.jpg'
                floorPlanClassName='calista-pair'
            />
        </>
    )
}

export default CalistaPair